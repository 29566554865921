<template>
<pre-loader v-if="loading"></pre-loader>
<div :class="$store.state.isMobile?'content':'desktop-content'">
  <iframe
    v-show="!loading"
    ref="wufoo"
      allowtransparency="true"
      class="wufoo-form-container"
      src=" https://geoffbanks.wufoo.com/forms/p4muiq90sozq0c" >
      <a href=" https://geoffbanks.wufoo.com/forms/p4muiq90sozq0c">
          Fill out my Wufoo form!
      </a>
  </iframe >
</div>
</template>

<script>
import PreLoader from "@/components/UI/PreLoader";
export default {
  name: "ResetPasswordView",
  components: { PreLoader},
  data:()=>{
    return{
      loading:true,
    }
  },
  mounted() {
    this.$refs.wufoo.onload = ()=>{
      this.loading=false
    }
  },
}
</script>

<style scoped>
.content{
  position: fixed;
  top: 3.5rem;
  bottom: 3.5rem;
  left: 0;
  right: 0;
}
.desktop-content{
  border-radius: 0.3rem;
  overflow: hidden;
}
</style>