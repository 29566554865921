<template>
<div class="live fs-11 fw-500">Live</div>
</template>

<script>
export default {
name: "LiveIcon"
}
</script>

<style scoped>
.live{
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FD7760;
  color: #FFFFFF;
  border-radius: 3px;
  padding: 1px 3px;
  width: max-content;
  height: max-content;
}
</style>