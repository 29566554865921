<template>
<div class="sports-bar-wrap" :class="{'sports-bar-wrap-desktop': !$store.state.isMobile}" ref="tabs">
    <div class="sports-bar-scroll ps-2">
      <div
        v-if="$store.state.isMobile"
        class="sports-bar-scroll__item py-2 px-1"
        :class="{'active-sport': $route.name === 'promos'}"
      >
        <div class="d-flex justify-content-center cursor-pointer" @click="$router.push({name: 'promos'})">
          <div class="sports-bar-scroll__item_icon" :style="{backgroundImage: 'url('+ require('@/assets/sports-bar/award.svg') +')'}"></div>
        </div>
        <div class="d-flex justify-content-center">
          <div
            class="sports-bar-scroll__item_title fs-8 mt-2 text-uppercase text-center"
            :class="{'active': $route.name === 'promos'}"
          > Promos </div>
        </div>
      </div>
      <div
        v-for="sport in API.SPORTS"
        :key="sport.title"
        class="sports-bar-scroll__item py-2 px-1"
        :class="{'active-sport': sport.title===$route.params.sportName}"
      >
        <div class="d-flex justify-content-center cursor-pointer" @click="$router.push({name: 'sport', params:{sportName: sport.title}, query:{}})">
          <div class="sports-bar-scroll__item_icon" :style="{backgroundImage: 'url('+sport.img+')'}"></div>
        </div>
        <div class="d-flex justify-content-center">
          <div
            class="sports-bar-scroll__item_title fs-8 mt-2 text-uppercase text-center"
            :class="{'active': sport.title===$route.params.sportName}"
          >{{ sport.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SportsBar",
  props: ['currentSportTitle'],
  mounted() {
    if (this.$route.params.sportName){
      let selectedItem = document.getElementsByClassName('active-sport')[0]
      this.$refs.tabs.scrollLeft=selectedItem.offsetLeft - window.visualViewport.width / 2
    }
  }
}
</script>

<style scoped>
.sports-bar-wrap{
  position: absolute;
  left: 0;
  right: 0;
  top: 3.5rem;
  height: 4rem;
  background-color: #09080C;
  overflow-x: scroll;
  scroll-behavior: smooth;
  z-index: 100;
}
.sports-bar-wrap-desktop{
  position: fixed;
  top: 5rem;
}
.sports-bar-scroll{
  display: flex;
  align-items: start;
  width: max-content;
}
.sports-bar-scroll__item{
  max-width: 4rem;
  min-width: 4rem;
}
.sports-bar-scroll__item_icon{
  height: 1.75rem;
  width: 1.75rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.active-sport{
  background-color: rgba(255, 197, 73, 0.46);
  border-radius: 0.3rem;
}
.active-sport .sports-bar-scroll__item_title{
  /*color: rgb(222 189 22 / 78%)!important;*/
}
.active-sport .sports-bar-scroll__item_icon{
  transform: scale(1.15);
  transition: 0.2s;
}
.sports-bar-scroll__item_title{
  color: white;
  line-height: 0.5rem;
}
</style>