<template>
<div class="competition-item d-flex justify-content-between p-1 cursor-pointer"
@click.self="goToEvent"
>
  <div
      class="cursor-pointer p-1"
      @click="goToEvent"
  >
    <div class="fs-14">{{event.homeTeam}}</div>
    <div v-if="sport.code==='m' && event.secondPlayer" class="fs-14 mt-1"> {{ event.secondPlayer}}</div>
    <div class="fs-14 mt-1">{{event.awayTeam}}</div>
    <div class="d-flex mt-1">
      <live-icon v-if="event.isLive"></live-icon>
      <span v-else class="fs-10 text-secondary">{{formatDate}}</span>
      <span class="fs-10 text-secondary ms-1">{{event.kickoff}}</span>
    </div>
  </div>
  <div class="d-flex">
    <odd-btn class="ms-1" :race="event" :selection="0"/>
    <odd-btn class="ms-1" :race="event" :selection="1"/>
    <odd-btn class="ms-1" :race="event" :selection="2"/>
  </div>

</div>
</template>

<script>
import OddBtn from "@/components/UI/OddBtn";
import LiveIcon from "@/components/UI/LiveIcon";
export default {
name: "CompetitionsListItem",
  components: {LiveIcon, OddBtn},
  props: ['event', 'sport'],
  computed: {
    formatDate(){
      let rawDate = new Date(this.event.date.split('-').reverse().join('-'))
      let today = new Date()
      if (rawDate.getDate()===today.getDate() && rawDate.getFullYear()===today.getFullYear() && rawDate.getMonth()===today.getMonth()){
        return 'Today'
      } else {
        return this.event.date
      }
    }
  },
  methods:{
    goToEvent(){
      this.$router.push({
        name: 'matchEvent',
        params: {
            sportName:this.sport.title,
             ef:this.event.Code,
             eg:this.event.eventGroup
          },
        query: {
            title: this.event.eventName,
            homeParticipant: this.event.homeTeam,
            awayParticipant: this.event.awayTeam,
            date: `${this.formatDate} ${this.event.kickoff}`
          }
      })
    }
  }
}
</script>

<style scoped>
.competition-item{
  width: auto;
  background: #FFFFFF;
  border-radius: 0.25rem;
}
</style>