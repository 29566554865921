<template>
  <div class="bg-white h-100">
  <router-view/>
  </div>
</template>

<script>
export default {
  name: "AccountBaseView",
}
</script>

<style scoped>
</style>