<template>
<div class="live-events-section d-flex overflow-hidden">
  <div class="bg-white overflow-hidden" :class="{'rounded-3': !$store.state.isMobile}">
    <div class="px-4 py-2 fw-600 text-start">Highlights</div>
    <div class="mx-3 border-bottom"></div>
    <scroll-tabs-bar
      :items="sportsList.map(sport=>sport.title)"
      :selected-item="currentSport.title"
      @selectitem="selectSport"
    ></scroll-tabs-bar>
  </div>
  <div class="section-content">
    <div v-if="loading" class="content_wrap position-relative flex-fill"><pre-loader/></div>
    <div v-else-if="events.length>0" class="content_wrap">
        <competitions-list-item
          class="my-2"
          :class="{'mx-2': $store.state.isMobile}"
          v-for="event in currentSportMatches"
          :key="event.eventId1"
          :event="event"
          :sport="currentSport"
        ></competitions-list-item>
    </div>
    <no-events v-else/>
  </div>
</div>
</template>

<script>
import * as Utility from "@/http/utils"
import _ from 'underscore'
import ScrollTabsBar from "@/components/UI/ScrollTabsBar";
import CompetitionsListItem from "@/components/Sport/Competitions/CompetitionsListItem";
import NoEvents from "@/components/Sport/NoEvents";
import PreLoader from "@/components/UI/PreLoader";

export default {
name: "SportHighlights",
  emits: ['dataloaded'],
  components: {PreLoader, NoEvents, CompetitionsListItem, ScrollTabsBar},
  props:{

  },
  data: ()=>{
  return {
    sportsList: [],
    currentSport: {},
    loading: false,
    currentSportMatches: [],

    events: [],
    currentEvent: {},
    currentEventIndex: 0,
    showLess: true,
    isFirstLoad: "0",
    InPlayEventsToggle: null
  }
},
beforeMount() {
  this.sportsList = this.API.SPORTS.filter(sport=>sport.type==='competition')
  this.currentSport = this.API.SPORTS[4]
},
  mounted() {
  this.loadData()
},
  watch:{
     currentSport(){
       this.loadData()
     }
  },
methods:{
  loadData:function(){
    const vm = this
    vm.loading = true
    vm.API.getLiveEvents().then(responseresult => {
        vm.InPlayEventsToggle = responseresult
        vm.API.getEvents(this.currentSport.code)
      .then(events => {
          events = Utility.FilterEvents(events);
          let eventDate = events;
          eventDate.map((data) => {
            data.Competitions.map((data1) => {
              data1.details.map((data2) => {
                if (data2.firstPlayer != undefined) {
                  data2.homeTeam = data2.firstPlayer;
                  data2.awayTeam = data2.secondPlayer;
                  data2.homeodds = data2.firstodds;
                  data2.drawodds = data2.secondodds;
                  data2.awayodds = data2.thirdodds;
                  data2.kickoff = data2.time;
                  data2.Code = data.EventCode
                }
                vm.checkIsLiveMatch(data2);
              });
            });
          });
          if (vm.showLess) {
            eventDate = eventDate.slice(0, 3);
          }
          vm.events = eventDate
          vm.currentEvent = events[0]
          let allEvents = []
          eventDate.map(cat=>{
            let category = cat
            category.events = []
            cat.Competitions.map(comp=>{
              comp.details.map(ev=>{
                let event = ev
                event.date = comp.date
                event.Code = cat.Code
                event.EventName = comp.EventName
                category.events.push(event)
                allEvents.push(event)
                return event
              })
            })
            return category
          })
          allEvents.sort((ev1, ev2)=>{
            let date1 = new Date(ev1.date.split('-').reverse().join('-'))
            let date2 = new Date(ev2.date.split('-').reverse().join('-'))
            if (date1 < date2) return -1
            if (date1 === date2) return 0
            if (date1 > date2) return 1
          })
          vm.currentSportMatches = allEvents
          vm.loading = false
          this.$emit('dataloaded')
        })
      });
  },
  checkIsLiveMatch(event) {
    _.find(this.InPlayEventsToggle, function(o) {
      event["isLive"] = false;

      if (o.event_id1 === event.eventId1) {
        event["isLive"] = true;
        event["id"] = o.id;
        // var toggle_old = "toggle-" + event.eventId1;
        // var livetoggle = "#" + toggle_old;
        return true;
      }
    });
  },
  selectSport(sportName){
    this.currentSport = this.sportsList.filter(s=>s.title===sportName)[0]
  },
  selectEvent(eventName){
    this.currentEvent = this.events.filter(e=>e.EventName === eventName)[0]
  }
}
}
</script>

<style scoped>
.content_wrap{
  min-height: 150px;
}
.live-events-section{
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F0F1F5;
}
.section-content{
  position: relative;
  min-height: 10rem;
}
</style>