<template>
<div class="betslip__header px-3 rounded-top" :class="{'betslip__header-descktop': !$store.state.isMobile}" >
      <div class="fw-600" :class="{'text-white fs-13': $store.state.isMobile}">
        <div v-if="betSlipStatus==='success'" class="d-flex align-items-center"><img class="me-2" :src="require('@/assets/img/check-circle.svg')" alt="success"/><span> Bet Placed</span></div>
        <div v-else-if="betSlipStatus==='failure'" class="d-flex align-items-center"><img class="me-2" :src="require('../../assets/img/warning-triangle.svg')" alt="warning"/><span> Stake Failed</span></div>
        <div v-else><span>Bet Slip</span></div>
      </div>
      <div @click="$emit('close')" class="close-modal-btn cursor-pointer" >
        <img alt="arrow-down" :src="require('@/assets/img/arrow-down.svg')"/>
      </div>
    </div>
<div class="betslip__content rounded-bottom">
  <div v-if="betSlipProcessing" class="d-flex align-items-center justify-content-center">
    <div class="m-5 p-5 d-flex justify-content-center align-items-center flex-column">
      <div style="height: 8rem; width: 8rem" class="position-relative"><pre-loader/></div>
      <div class="fs-20 fw-500 text-center">Wager processing with Geoff Banks.</div>
    </div>
  </div>
  <bet-slip-submit-response
    v-else-if="betslipSubmitResponse"
    :betslip-response="betslipSubmitResponse"
    @close="closeSubmitResponse"
    @updatestatus="updateBetSlipStatus"
  />
  <div v-else>
    <div v-if="BETSLIP.bets.length">
      <bets-list class="flex-fill" :bets="BETSLIP.bets"/>
      <bet-slip-wager
          ref="wager"
          @close="$emit('close')"
          @betslipsubmitted="betSlipProcessing=true"
      />
    </div>
    <div v-else class="p-3">
      <div class="d-flex align-items-center justify-content-center flex-column mb-3">
        <div class="no-bets-icon mb-3" :style="{backgroundImage: 'url('+require('@/assets/img/no-bets.svg')}"></div>
        <div class="fs-14 mb-2">No open bets</div>
        <div  class="fs-11 text-secondary mb-3">Select odds to place a bet</div>
      </div>
      <vue-button v-if="$store.state.isMobile" @click="$emit('close')" class="" cls="main">Ok</vue-button>
    </div>
  </div>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {authApiService, betslipApi} from "@/services/betslip/_services";
import BetSlipWager from "@/components/BetSlip/BetSlipWager";
import BetsList from "@/components/BetSlip/BetsList";
import VueButton from "@/components/UI/VueButton";
import {signalRService} from "@/services/betslip/_services/signalrService";
import PreLoader from "@/components/UI/PreLoader";
import {BETSLIP_STATUSES} from "@/services/betslip/_constants";
import BetSlipSubmitResponse from "@/components/BetSlip/BetSlipSubmitResponse";
import {useToast} from "vue-toastification";

export default {
  name: "BetSlipMain",
  emits:['close'],
  components: {BetSlipSubmitResponse, PreLoader, BetSlipWager, BetsList, VueButton},
  data: function (){
    return {
      betslipSubmitResponse: null,
      // betslipSubmitResponse: {"id":"62a061a4113faf56809868b7","userId":"61c1f2950132a2e883d75039","status":2,"failureReason":null,"bets":[{"id":"62a994273947124ecc31ebf8","externalId":"2252255","eventFileName":"f6uBFTRP","eventGroup":1,"eventsNumber":0,"selectionNumber":0,"priceCode":"E","price":"8-11","gPriceCode":"K","gPrice":2,"coi":"000","detail":"Hell Red","name":"13:00 Uttoxeter","betPrice":"8-11","newPC":"EP","wagerName":"13:00 Uttoxeter","isAvailable":true,"isEachWay":true,"isBog":true,"oldPrice":null}],"wagers":[{"name":"Singles","typeId":13,"numberOfLines":1,"isEachWay":true,"eachWayType":"Each Way","eachWay":false,"stake":0,"toSubmit":false}],"wagersSubmitResult":[{"name":"Singles","typeId":13,"numberOfLines":1,"isEachWay":true,"eachWayType":"Each Way","eachWay":false,"stake":1,"isSubmitted":true,"potentialReturn":0.7273,"resultMessage":null,"ticketNumber":"1289928","stakePlaced":1,"isLiabilityExceeded":false,"liabilityExceededOn":null,"maxStakeAvailable":null,"customerReferenceNumber":null,"errorNumber":0,"isPriceChanged":false,"isEventEnded":false}],"message":null,"keepBetslip":false,"customerBalance":133.76,"isInsufficientFunds":false,"acceptPriceChanges":false},
      // betslipSubmitResponse: {"id":"62a061a4113faf56809868b7","userId":"61c1f2950132a2e883d75039","status":4,"failureReason":"Unexpected error occured","bets":[{"id":"62a8919d347018f90e8e2b19","externalId":"2250534","eventFileName":"e6uUnatl","eventGroup":17,"eventsNumber":0,"selectionNumber":0,"priceCode":"C","price":"17-20","gPriceCode":"C","gPrice":0,"coi":"000","detail":"Romania","name":"Romania v Montenegro","betPrice":"17-20","newPC":"CP","wagerName":"Romania v Montenegro","isAvailable":true,"isEachWay":false,"isBog":false,"oldPrice":null}],"wagers":[{"name":"Singles","typeId":13,"numberOfLines":1,"isEachWay":false,"eachWayType":"Win Only","eachWay":false,"stake":1,"toSubmit":false}],"wagersSubmitResult":[{"name":"Singles","typeId":13,"numberOfLines":1,"isEachWay":false,"eachWayType":"Win Only","eachWay":false,"stake":1,"isSubmitted":false,"potentialReturn":null,"resultMessage":null,"ticketNumber":null,"stakePlaced":null,"isLiabilityExceeded":false,"liabilityExceededOn":null,"maxStakeAvailable":null,"customerReferenceNumber":null,"errorNumber":-12,"isPriceChanged":false,"isEventEnded":true}],"message":null,"keepBetslip":false,"customerBalance":134.85,"isInsufficientFunds":false,"acceptPriceChanges":false},
      signalrConnection: null,
      betSlipProcessing: false,
      betSlipStatus: null,
    }
  },
  setup(){
    const toast = useToast()
    return { toast }
  },
  mounted(){
     if (this.$store.state.betslipStore.authToken) {
       this.setupSignalR()
     }
    this.loadBetslipInitial()
  },
  watch:{
    '$store.state.betslipStore.authToken'(authToken){
      if (authToken && !this.signalrConnection) {
        this.setupSignalR()
      }
    }
  },
  computed:{
    ...mapGetters(['BETSLIP']),
  },
  methods: {
    ...mapActions(['SET_BETSLIP_ID', 'SET_BETS_LENGTH', 'SET_BETSLIP', 'UPDATE_USER_DATA']),
    setWagerForm(betslip, chosenWagerIndex){
      if (!this.$store.state.betslipStore.backupId) return
      const currWager = (betslip.wagers || [])[chosenWagerIndex]
      this.selectedWagerStake = currWager ? currWager.stake : 0
      this.selectedWagerEachway = currWager ? currWager.eachWay : false
    },
    loadBetslipInitial(){
      const vm = this
      let requestPromise = this.$store.state.betslipStore.backupId == null
          ? betslipApi(authApiService.logout)
              .createEmptyBetslip(vm.$store.state.authToken)
          : betslipApi(authApiService.logout)
              .getActiveBetslip(vm.$store.state.betslipStore.backupId, true);

      requestPromise
        .then((betslipResp => {
          vm.bets = betslipResp.bets
          vm.SET_BETSLIP_ID(betslipResp.id)
          vm.SET_BETSLIP(betslipResp)
          vm.setWagerForm(betslipResp, 0);
        }))
        .catch(err => {
            console.error('request betslip', err);
        });
    },
    setupSignalR(){
      const connectionHubResp = signalRService.buildHub(
            this.$store.state.betslipStore.authToken,
            this.submitResponseHandler,
            authApiService.logout
        )
      if (connectionHubResp.isError) {
        console.error('signalR error: ' + connectionHubResp.error)
        this.signalrConnection = null
        this.$store.dispatch('LOGOUT')
      } else {
        console.info('add connection hub', connectionHubResp.connect)
      }
    },
    submitResponseHandler(betslipResp){
      const vm = this
      console.log('SINGNALR RESPONSE', betslipResp)

      if (!betslipResp) return null
      if (betslipResp.bets.length > 0) vm.betslipSubmitResponse = betslipResp
      if (betslipResp.status === BETSLIP_STATUSES.SUCCEED) {
        betslipApi(vm.logout).createEmptyBetslip(vm.$store.state.authToken)
          .then(resp => {
              vm.SET_BETSLIP(resp)
              vm.UPDATE_USER_DATA()
              vm.betSlipProcessing = false
          })
          .catch(err => console.error(err))
      } else {
        vm.betSlipProcessing = false
      }
      return betslipResp
    },
    updateBetSlipStatus(status){
      this.betSlipStatus = status
    },
    closeSubmitResponse(){
      this.betSlipStatus = null
      this.betslipSubmitResponse = null
      this.$emit('close')
    },
    logout(){
      this.$store.dispatch('LOGOUT')
    },
  }
}
</script>

<style scoped>
.betslip__content_wrap{
  margin: 5rem 0;
}
.betslip__content{
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  box-shadow: 0px 24px 120px -30px rgba(0, 0, 0, 0.15);
  max-height: calc(100% - 3rem);
  min-height: 100%;
  background-color: #F8F8FA;
}
.betslip__header{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  background: #09080C;
  border-radius: 0.75rem 0.75rem 0px 0px;
}
.betslip__header-descktop{
  background: #FFFFFF;
  border-radius: 0.5rem 0.5rem 0 0;
}
.no-bets-icon{
  height: 3rem;
  width: 3rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.close-modal-btn{
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-modal-btn img{
  filter: brightness(100);
  width: 1rem;
  height: auto;
}
</style>