<template>
<div class="odd-btn fw-500 fs-17">
  {{glb.formatPrice(price)}}
</div>
</template>

<script>
export default {
  name: "OddIcon",
  props:['price']
}
</script>

<style scoped>
.odd-btn{
  width: 4rem;
  padding: 0.5rem 0.25rem;
  background: #f0f1f5;
  border-radius: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>