<template>
<!--<sport-header :title="$route.params.sportName"/>-->
<component v-if="sportComponent" :is="sportComponent" v-bind="{sport: sport}"/>
</template>

<script>
import { shallowRef } from 'vue';
import RacesMain from "@/components/Sport/Races/RacesMain";
import CompetitionsMain from "@/components/Sport/Competitions/CompetitionsMain";


export default {
name: "SportView",
  components: {CompetitionsMain, RacesMain},
  data:()=>{
  return{
    sport: {},
    sportComponent: null
  }
},
watch: {
  "$route.params.sportName"(old, current){
      if (old && current){
        this.sportComponent = null
        this.$nextTick(() => {
          this.sportComponent = shallowRef(this.sport.type === 'race' ? RacesMain: CompetitionsMain)
        })
        this.sport = this.API.SPORTS.filter(sport=>sport.title.toLowerCase() === this.$route.params.sportName.toLowerCase())[0]
      }
  },
},
beforeMount() {
  this.sport = this.API.SPORTS.filter(sport=>sport.title.toLowerCase() === this.$route.params.sportName.toLowerCase())[0]
  this.sportComponent = shallowRef(this.sport.type === 'race' ? RacesMain: CompetitionsMain)
},
mounted() {
},
methods:{

}
}
</script>

<style scoped>

.sport-content{
  position: fixed;
  top: 7.5rem;
  right: 0;
  left: 0;
  bottom: 3.5rem;
  overflow-y: scroll;
}
</style>