<template>
<div class="container-fluid desktop-container">
  <div class="row bg-light py-3">
    <div class="col-2">
      <account-view/>
    </div>
    <div class="col-7 px-0 position-relative">
      <div :class="['transactions'].includes($route.name)?'':'bg-white rounded-3 overflow-hidden p-3'">
        <router-view/>
      </div>
    </div>
    <div class="col-3">
      <div class="bg-white rounded-3">
        <download-app class="p-3"/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import AccountView from "@/views/AccountView";
import DownloadApp from "@/components/DownloadApp";
export default {
  name: "BaseAccountDesktopView",
  components: {DownloadApp, AccountView}
}
</script>

<style scoped>
.desktop-container{
  margin-top: 5rem;
  background-color: #FFFFFF;
}
</style>