import axios from "axios"
import _ from 'underscore'
const siteRoot = "https://www.v1.geoffbanks.bet"
export const base_url = "https://api.geoff-banks.com/Geoff/";
const base_url_v0 = "https://v0.geoff-banks.com/";
const base_url_v1 = "https://apiv1.geoffbanks.bet/";
export const assets_base_url = "https://apiv1.geoffbanks.bet/assets/images/";
// const base_url_v2 = "https://api2.geoffbanks.bet/api/";
const items_url = "https://be.geoff-banks.com/_/items/";
const access_token = "RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M";
const paydoo_base_url = 'https://service.geoffbanks.bet/payment/paydoo/'
const paydoo_token = 'OGE4Mjk0MTg1ZTVjNjFmNTAxNWU2MWQyNWY3ZDBkY2V8ZGZxbnRYTkgzOQ=='
export const paypalProdClientId = "AeKbIqUt87EfrJvMUrx1XvaWttj3YJLIgOJft-RRc9VS5xwMkaFzYo-eKYrWr3AMVn1Cm9w2hFygG8MQ"
export const paypalTestClientId = "ATKUU4FzPFqsRTgwbMmsSFUkYxNLoeIHIPJBM_kiNbqwJHvsPn7q27vQEkjfbfhwCe8VgXrpZVtXBk8P"
import store from "../store"

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 1024

export const ApiProxy = {
    Get: `${base_url_v1}ApiProxy/Get?url=`
}
export const Reg_API_BASE_URL_V1 = "https://apiv1.geoffbanks.bet/";
export const Reg_API_KEY = "ishFiWjhUEWbXCXcgO3F9g26831";

const utility = require("./utils");

export const SPORTS = [
        {
            title: 'Horse Racing',
            code: 'H,h',
            antePostCode: "a",
            type: 'race',
            url: `${base_url_v0}events/sports-api.asp?sportcode=H,h`,
            antePostUrl:`${base_url}GetAntepostEvents/a`,
            img: require('../assets/sports-bar/hourse.svg'),
            subcategories: [
                {
                    title: 'UK',
                    code: 'H,h',
                    url: `${base_url_v0}events/sports-api.asp?sportcode=H,h`,
                },
                {
                    title: 'Irish',
                    code: 'H',
                    url: base_url_v0 + "events/sports-api.asp?sportcode=H",
                    img: require('../assets/sports-bar/hourse.svg')
                },
                {
                    title: 'International',
                    code: 'o',
                    url: `${base_url_v0}events/sports-api.asp?sportcode=o`,
                    img: require('../assets/sports-bar/hourse.svg')
                },
            ]
        },
        {
            title: 'Greyhounds',
            code: 'g,q',
            antePostCode: 'p',
            antePostUrl: `${base_url}GetAntepostEvents/p`,
            type: 'race',
            url: `${base_url_v0}events/sports-api.asp?sportcode=g,q`,
            img: require('../assets/sports-bar/greyhounds.svg')
        },
        {
            title: 'Virtual Horse',
            code: 'vh',
            antePostCode: '',
            type: 'race',
            url: `${base_url_v0}events/sports-api.asp?sportcode=vh`,
            img: require('../assets/sports-bar/hourse.svg')
        },
        {
            title: 'Virtual Greyhounds',
            code: '$',
            antePostCode: '$',
            type: 'race',
            url: `${base_url_v0}events/sports-api.asp?sportcode=$`,
            img: require('../assets/sports-bar/greyhounds.svg')
        },
        {
            title: 'Football',
            code: 'f',
            type: 'competition',
            url: `${base_url}GetEventsDetail/f?fullcard=1`,
            antePostUrl:`${base_url}GetAntepostEvents/s`,
            img: require('../assets/sports-bar/football.svg'),
            bg: require(isMobile?'../assets/sports/football-bg.jpg':'../assets/sports/football-bg-l.jpg'),
},
        {
            title: 'Tennis',
            url: base_url + "GetEventsDetail/N?fullcard=1",
            antePostUrl: `${base_url}GetAntepostEvents/t`,
            code: 'N',
            type: 'competition',
            img: require('../assets/sports-bar/tennis.svg'),
            bg: require(isMobile?'../assets/sports/tennis-bg.jpg':'../assets/sports/tennis-bg-l.jpg'),

        },
        {
            title: 'Baseball',
            code: 'e',
            antePostCode: 'n',
            type: 'competition',
            url: base_url + "GetEventsDetail/e?fullcard=1",
            antePostUrl: `${base_url}GetAntepostEvents/n`,
            img: require('../assets/sports-bar/baseball.svg'),
            bg: require(isMobile?'../assets/sports/baseball-bg.jpg':'../assets/sports/baseball-bg-l.jpg'),
        },
        {
            title: 'Basketball',
            code: 'E',
            antePostCode: 'k',
            type: 'competition',
            url: base_url + "GetEventsDetail/E?fullcard=1",
            antePostUrl: `${base_url}GetAntepostEvents/k`,
            img: require('../assets/sports-bar/basketball.svg'),
            bg: require(isMobile?'../assets/sports/bascketball-bg.jpg':'../assets/sports/bascketball-bg-l.jpg'),
        },
        {
            title: 'Golf',
            code: 'm',
            type: 'competition',
            url: `${base_url}GetEventsDetail/m?fullcard=1`,
            antePostUrl: `${base_url}GetAntepostEvents/G`,
            img: require('../assets/sports-bar/golf.svg'),
            bg: require(isMobile?'../assets/sports/golf-bg.jpg':'../assets/sports/golf-bg-l.jpg'),
        },
        {
            title: 'Cricket',
            code: 'C',
            antePostCode: 'K',
            type: 'competition',
            url: base_url + "GetEventsDetail/C?fullcard=1",
            antePostUrl: `${base_url}GetAntepostEvents/K`,
            img: require('../assets/sports-bar/cricket.svg'),
            bg: require(isMobile?'../assets/sports/cricket-bg.jpg':'../assets/sports/cricket-bg-l.jpg'),
        },

        {
            title: 'Rugby League',
            code: 'l',
            antePostCode: 'r',
            type: 'competition',
            url: base_url + "GetEventsDetail/l?fullcard=1",
            img: require('../assets/sports-bar/rugby.svg'),
            bg: require(isMobile?'../assets/sports/rugby-bg.jpg':'../assets/sports/rugby-bg-l.jpg'),
        },
        {
            title: 'Rugby Union',
            code: 'v',
            antePostCode: 'R',
            type: 'competition',
            url: base_url + "GetEventsDetail/v?fullcard=1",
            img: require('../assets/sports-bar/rugby.svg'),
            bg: require(isMobile?'../assets/sports/rugby-bg.jpg':'../assets/sports/rugby-bg-l.jpg'),
        },
        {
            title: 'American Football',
            code: 'F',
            antePostCode: 'A',
            type: 'competition',
            url:`${base_url}GetEventsDetail/F?fullcard=1`,
            antePostUrl: `${base_url}GetAntepostEvents/A`,
            img: require('../assets/sports-bar/nfl.svg'),
            bg: require(isMobile?'../assets/sports/nfl-bg.jpg':'../assets/sports/nfl-bg-l.jpg'),
        },
        {
            title: 'Ice Hockey',
            code: 'Y',
            antePostCode: 'y',
            type: 'competition',
            url: base_url + "GetEventsDetail/y?fullcard=1",
            antePostUrl: `${base_url}GetAntepostEvents/M`,
            img: require('../assets/sports-bar/hokey.svg'),
            bg: require(isMobile?'../assets/sports/hockey-bg.jpg':'../assets/sports/hockey-bg-l.jpg'),
        },
        {
            title: 'Boxing',
            code: 'box',
            antePostCode: null,
            type: 'competition',
            url:base_url + "GetEventsDetail/b?fullcard=1",
            img: require('../assets/sports-bar/boxing.svg'),
            bg: require(isMobile?'../assets/sports/box-bg.jpg':'../assets/sports/box-bg-l.jpg'),
        },
        {
            title: 'UFC | MMA',
            code: 'b',
            antePostCode: null,
            type: 'competition',
            url: base_url + "GetEventsDetail/b?fullcard=1",
            img: require('../assets/sports-bar/ufc.svg'),
            bg: require(isMobile?'../assets/sports/ufc-bg.jpg':'../assets/sports/ufc-bg-l.jpg'),
        },
        {
            title: 'Darts',
            code: 'I',
            antePostCode: 'D',
            type: 'competition',
            url:base_url + "GetEventsDetail/I?fullcard=1",
            antePostUrl: `${base_url}GetAntepostEvents/D`,
            img: require('../assets/sports-bar/darts.svg'),
            bg: require(isMobile?'../assets/sports/darts-bg.jpg':'../assets/sports/darts-bg-l.jpg'),
        },
        {
            title: 'Snooker',
            code: 'j',
            antePostCode: 'S',
            type: 'competition',
            url: base_url + "GetEventsDetail/j?fullcard=1",
            antePostUrl: `${base_url}GetAntepostEvents/S`,
            img: require('../assets/sports-bar/snooker.svg'),
            bg: require(isMobile?'../assets/sports/snooker-bg.jpg':'../assets/sports/snooker-bg-l.jpg'),
        },
        {
            title: 'Cycling',
            code: null,
            antePostCode: 'c',
            url: null,
            type: 'race',
            antePostUrl: `${base_url}GetAntepostEvents/c`,
            img: require('../assets/sports-bar/сycling.svg'),
        },
        {
            title: 'GAA',
            code: 'J',
            antePostCode: 'D',
            type: 'competition',
            url: base_url + "GetEventsDetail/J?fullcard=1",
            antePostUrl: `${base_url}GetAntepostEvents/d`,
            img: require('../assets/sports-bar/gaa.svg'),
            bg: require(isMobile?'../assets/sports/gaa-bg.jpg':'../assets/sports/gaa-bg-l.jpg'),
        },
        {
            title: 'Motor Racing',
            code: 'M',
            antePostCode: 'M',
            url: null,
            type: 'race',
            antePostUrl: `${base_url}GetAntepostEvents/M`,
            img: require('../assets/sports-bar/motor.svg'),
        },
        {
            title: 'Special',
            code: null,
            antePostCode: 'z',
            url: null,
            type: 'competition',
            antePostUrl: `${base_url}GetAntepostEvents/z`,
            img: require('../assets/sports-bar/special.svg'),
        },
    ]

const Sports = [
    {
        Name: "Horse Racing",
        URL: `${base_url_v0}events/sports-api.asp?sportcode=H,h`,
        AntePostURL:`${base_url}GetAntepostEvents/a`,
        LiveRaceURL: base_url_v0 + "Ajax/Next/HomeEvent/api.asp?urid=upRegNextHomeEv",
        SportCode: "H,h",
        AntepostSportCode: "a",
        Image: "hourse.png",
        PathName: "/sports?sportcode=H,h",
        AntepostPathName: "/antepost?sportcode=a",
        SubItems: [
            {
                Title: "UK Racing",
                Url: "/sports?sportcode=H,h",
                Name: "UK Horse Racing",
                SportCode: "H,h",
                URL: base_url_v0 + "events/sports-api.asp?sportcode=H,h",
                PathName: "/sports?sportcode=H,h",
            },
            {
                Title: "Irish Racing ",
                Url: "/sports?sportcode=H",
                Name: "Irish Horse Racing",
                SportCode: "H",
                URL: base_url_v0 + "events/sports-api.asp?sportcode=H",
                PathName: "/sports?sportcode=H",
                AntepostSportCode: "a",
                AntePostURL:`${base_url}GetAntepostEvents/a`,
                LiveRaceURL: base_url_v0 + "Ajax/Next/HomeEvent/api.asp?urid=upRegNextHomeEv",
                AntepostPathName: "/antepost?sportcode=a",
            },
            {
                Title: "International Racing",
                Url: "/sports?sportcode=o",
                Name: "International Horse Racing",
                SportCode: "o",
                URL: `${base_url_v0}events/sports-api.asp?sportcode=o`,
                PathName: "/sports?sportcode=o",
                AntepostSportCode: "a",
                AntePostURL:`${base_url}GetAntepostEvents/a`,
                LiveRaceURL: base_url_v0 + "Ajax/Next/HomeEvent/api.asp?urid=upRegNextHomeEv",
                AntepostPathName: "/antepost?sportcode=a",
            },
        ],
    },
    {
        Name: "Virtual Horse",
        URL: `${base_url_v0}events/sports-api.asp?sportcode=vh`,
        SportCode: "vh",
        AntepostSportCode: "vh",
        Image: "hourse.png",
        PathName: "/sports?sportcode=vh",
    },
    {
        Name: "Greyhound",
        URL: `${base_url_v0}events/sports-api.asp?sportcode=g,q`,
        AntePostURL:`${base_url}GetAntepostEvents/p`,
        SportCode: "g,q",
        AntepostSportCode: "p",
        LiveRaceURL: `${base_url_v0}Ajax/Next/Dogs/api.asp?urid=upRegNextDogRace&nextrace=1`,
        Image: "dog.png",
        PathName: "/sports?sportcode=g,q",
        AntepostPathName: "/antepost?sportcode=p",
    },
    {
        Name: "Virtual Greyhound",
        URL:`${base_url_v0}events/sports-api.asp?sportcode=$`,
        SportCode: "$",
        AntepostSportCode: "$",
        Image: "dog.png",
        PathName: "/sports?sportcode=$",
    },
    {
        Name: "Football",
        URL: `${base_url}GetEventsDetail/f?fullcard=1`,
        AntePostURL:`${base_url}GetAntepostEvents/s`,
        SportCode: "f",
        AntepostSportCode: "s",
        Image: "Image_27.png",
        PathName: "/sports/football?sportcode=f",
        AntepostPathName: "/antepost?sportcode=s",
        FullCardOptions: [
            "Draw No Bet",
            "Over/Under 1.5",
            "Over/Under 2.5",
            "Over/Under 3.5",
            "Over/Under 4.5",
            "Over/Under 5.5",
            "Half-Time Totals Over/Under 0.5",
            "Half-Time Totals Over/Under 1.5",
            "Both Teams To Score",
            "To Qualify",
        ],
    },
    {
        Name: "Golf",
        URL: `${base_url}GetEventsDetail/m?fullcard=1`,
        AntePostURL:`${base_url}GetAntepostEvents/G`,
        SportCode: "m",
        AntepostSportCode: "G",
        Image: "golf.png",
        PathName: "/sports/golf?sportcode=m",
        AntepostPathName: "/antepost?sportcode=G",
    },
    {
        Name: "Tennis",
        URL: base_url + "GetEventsDetail/N?fullcard=1",
        AntePostURL:`${base_url}GetAntepostEvents/t`,
        SportCode: "N",
        AntepostSportCode: "t",
        Image: "tennis.png",
        PathName: "/sports/tennis?sportcode=N",
        AntepostPathName: "/antepost?sportcode=t",
        FullCardOptions: ["Total Games 2-Way", "Handicap 2-Way - Games Won"],
    },
    {
        Name: "American Football",
        URL: `${base_url}GetEventsDetail/F?fullcard=1`,
        AntePostURL:`${base_url}GetAntepostEvents/A`,
        SportCode: "F",
        AntepostSportCode: "A",
        Image: "purepng.png",
        PathName: "/sports/nfl/?sportcode=F",
        AntepostPathName: "/antepost?sportcode=A",
    },
    {
        Name: "Baseball",
        URL: base_url + "GetEventsDetail/e?fullcard=1",
        AntePostURL:`${base_url}GetAntepostEvents/n`,
        SportCode: "e",
        AntepostSportCode: "n",
        Image: "baseball.png",
        PathName: "/sports/baseball?sportcode=e",
        AntepostPathName: "/antepost?sportcode=n",
    },
    {
        Name: "Basketball",
        URL: base_url + "GetEventsDetail/E?fullcard=1",
        AntePostURL:`${base_url}GetAntepostEvents/k`,
        SportCode: "E",
        AntepostSportCode: "k",
        Image: "basketball.png",
        PathName: "/sports/basketball?sportcode=E",
        AntepostPathName: "/antepost?sportcode=k",
        FullCardOptions: [
            "Race to 20 Points",
            "1st Half Point Spread",
            "1st Half Money Line",
            "1st Half Totals",
            "Team with High Score Qtr",
        ],
    },
    {
        Name: "Boxing",
        URL: base_url + "GetEventsDetail/b?fullcard=1",
        SportCode: "box",
        Image: "gvb.png",
        PathName: "/sports/boxing?sportcode=box",
        FullCardOptions: ["Total Rounds", "Fight Result"],
    },
    {
        Name: "UFC/MMA",
        URL: base_url + "GetEventsDetail/b?fullcard=1",
        SportCode: "b",
        Image: "gvb.png",
        PathName: "/sports/ufcmma?sportcode=b",
    },
    {
        Name: "Cricket",
        URL: base_url + "GetEventsDetail/C?fullcard=1",
        AntePostURL:`${base_url}GetAntepostEvents/K`,
        SportCode: "C",
        AntepostSportCode: "K",
        Image: "fvg.png",
        PathName: "/sports/cricket?sportcode=C",
        AntepostPathName: "/antepost?sportcode=K",
        FullCardOptions: [
            "To Win the Toss",
            "1st Over Total Runs",
            "Total Match Sixes",
            "Fall of 1st Wicket",
            "A Fifty to be Scored",
            "A Century to be Scored",
            "Highest Individual Score",
        ],
    },
    {
        Name: "Cycling",
        AntePostURL:`${base_url}GetAntepostEvents/c`,
        AntepostSportCode: "c",
        Image: "cycle-race.png",
        PathName: "/antepost?sportcode=c",
        AntepostPathName: "/antepost?sportcode=c",
    },
    {
        Name: "Darts",
        URL: base_url + "GetEventsDetail/I?fullcard=1",
        AntePostURL:`${base_url}GetAntepostEvents/D`,
        SportCode: "I",
        AntepostSportCode: "D",
        Image: "bgf.png",
        PathName: "/sports/darts?sportcode=I",
        AntepostPathName: "/antepost?sportcode=D",
    },
    {
        Name: "GAA",
        URL: base_url + "GetEventsDetail/J?fullcard=1",
        AntePostURL:`${base_url}GetAntepostEvents/d`,
        SportCode: "J",
        AntepostSportCode: "d",
        Image: "xc.png",
        PathName: "/sports/GAA?sportcode=J",
        AntepostPathName: "/antepost?sportcode=d",
    },
    {
        Name: "Ice Hockey",
        URL: base_url + "GetEventsDetail/y?fullcard=1",
        AntePostURL:`${base_url}GetAntepostEvents/Y`,
        AntepostSportCode: "Y",
        Image: "hockey.png",
        PathName: "/sports/icehockey?sportcode=y",
        AntepostPathName: "/antepost?sportcode=Y",
        SportCode: "y",
    },
    {
        Name: "Motor Racing",
        AntePostURL:`${base_url}GetAntepostEvents/M`,
        SportCode: "M",
        AntepostSportCode: "M",
        Image: "motorcycle.png",
        PathName: "/antepost?sportcode=M",
        AntepostPathName: "/antepost?sportcode=M",
    },
    {
        Name: "Rugby League",
        URL: base_url + "GetEventsDetail/l?fullcard=1",
        AntePostURL:`${base_url}GetAntepostEvents/r`,
        SportCode: "l",
        AntepostSportCode: "r",
        Image: "szcd.png",
        PathName: "/sports/rugby?sportcode=l",
        AntepostPathName: "/antepost?sportcode=r",
    },
    {
        Name: "Rugby Union",
        URL: base_url + "GetEventsDetail/v?fullcard=1",
        SportCode: "v",
        Image: "sd.png",
        PathName: "/sports/rugby?sportcode=v",
        AntePostURL:`${base_url}GetAntepostEvents/R`,
        AntepostSportCode: "R",
        AntepostPathName: "/antepost?sportcode=R",
    },
    {
        Name: "Snooker",
        URL: base_url + "GetEventsDetail/j?fullcard=1",
        AntePostURL:`${base_url}GetAntepostEvents/S`,
        SportCode: "j",
        AntepostSportCode: "S",
        Image: "snooker.png",
        PathName: "/sports/snooker?sportcode=j",
        AntepostPathName: "/antepost?sportcode=S",
    },
    {
        Name: "Special/Politics",
        AntePostURL:`${base_url}GetAntepostEvents/z`,
        AntepostSportCode: "z",
        Image: "special.png",
        PathName: "/antepost?sportcode=z",
        AntepostPathName: "/antepost?sportcode=z",
    },
]

function getSportId(sportcode) {
  if (
    sportcode == "H,h" ||
    sportcode == "H" ||
    sportcode == "o" ||
    sportcode == "vh"
  ) {
    return "HR";
  } else if (sportcode == "g,q" || sportcode == "$") {
    return "DG";
  } else {
    return "";
  }
}

export function getLocationData(){
    return new Promise((resolve, reject)=>{
        axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=d3bd1d54fe874ce2907520ca38b8523a')
            .then(res=>resolve(res.data))
            .catch(error=>reject(error))
        })
}
export function geocoding(lat, lng){
    return new Promise((resolve, reject)=>{
        axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lng}&language=en&key=75d0d25c29844e93bb8be95f008c8e5c`)
            .then(res=>resolve(res.data))
            .catch(error=>reject(error))
        })
}

export function GetSportInfo(sportCode) {
    let sportDetail = [];
    _.find(Sports, function (sport) {
        if (sport.SportCode == sportCode) {
            sportDetail = sport;
        } else {
            if (
                typeof sport != "undefined" &&
                typeof sport.SubItems != "undefined" &&
                sport.SubItems.length > 0
            ) {
                _.find(sport.SubItems, function (subItem) {
                    // subItem.SportCode == sportCode;
                    if (subItem.SportCode == sportCode) {
                        sportDetail = subItem;
                    }
                });
            }
        }
    });
    return sportDetail;
}

export function ParseEventData(data) {
    if (data.Races) {
        //console.log("Races");
        data.Races.forEach((race) => {
            let pInfo = race.Price1;
            if (typeof pInfo == "undefined" || pInfo.trim().length <= 0)
                pInfo = race.Price2;
            var p1Sets = pInfo.split("&");
            p1Sets.forEach((element) => {
                var keyVals = element.split("=");
                race[keyVals[0]] = keyVals[1];
            });
        });
        //console.log(data, "parserdata");
    } else if (data.Events) {
        //console.log("Events");
        data.Events.forEach((race) => {
            if (!(typeof race.PriceLink1 == "undefined")) {
                var pInfo = race.PriceLink1;
                if (
                    pInfo.trim().length <= 0 &&
                    !(typeof race.PriceLink1 == "undefined") &&
                    race.PriceLink2.length > 0
                )
                    pInfo = race.PriceLink2;
                var p1Sets = pInfo.split("&");
                p1Sets.forEach((element) => {
                    var keyVals = element.split("=");
                    race[keyVals[0]] = keyVals[1];
                });
            }
        });
        data.Races = data.Events
        //console.log(data, "parserdata");
    } else if (data.Meetings) {
        //console.log("MeetingLink");
        data.Meetings.forEach((race) => {
            if (!(typeof race.MeetingLink == "undefined")) {
                var pInfo = race.MeetingLink;
                if (
                    pInfo.trim().length <= 0 &&
                    !(typeof race.MeetingLink == "undefined") &&
                    race.MeetingLink.length > 0
                )
                    pInfo = race.MeetingLink;
                var p1Sets = pInfo.split("&");
                p1Sets.forEach((element) => {
                    var keyVals = element.split("=");
                    race[keyVals[0]] = keyVals[1];
                });
            }
        });
        data.Races = data.Meetings
    }
    return data;
}

export function ParseEventArrayData(data) {
    data.forEach((event) => {
        if (event.Meetings) {
            event.Meetings.forEach((race) => {
                if (!(typeof race.MeetingLink == "undefined")) {
                    var pInfo = race.MeetingLink;
                    if (
                        pInfo.trim().length <= 0 &&
                        !(typeof race.MeetingLink == "undefined") &&
                        race.MeetingLink.length > 0
                    )
                        pInfo = race.MeetingLink;
                    var p1Sets = pInfo.split("&");
                    p1Sets.forEach((element) => {
                        var keyVals = element.split("=");
                        race[keyVals[0]] = keyVals[1];
                    });
                }
            });
        } else if (event.Events != null) {
            event.Events.forEach((race) => {
                if (
                    !(typeof race.PriceLink1 == "undefined") &&
                    race.PriceLink1 != null
                ) {
                    var pInfo = race.PriceLink1;
                    if (
                        pInfo.trim().length <= 0 &&
                        !(typeof race.PriceLink1 == "undefined") &&
                        race.PriceLink2.length > 0
                    )
                        pInfo = race.PriceLink2;
                    var p1Sets = pInfo.split("&");
                    p1Sets.forEach((element) => {
                        var keyVals = element.split("=");
                        race[keyVals[0]] = keyVals[1];
                    });
                }
            });
        }
    });
    return data;
}

export function AllowedCountries(platform){
    const URL = `https://api.geoff-banks.com/Geoff/GetCountries/${platform==='ios'?'iphone':'android'}?c=${Math.random()}`
    return new Promise((resolve, reject) => {
        axios.get(URL)
            .then(res=>resolve(res.data))
            .catch(error=>reject(error))
    })
}

// Account & settings requests

export function getPromotions(){
    return new Promise((resolve, reject)=>{
        axios.get(`${items_url}promotions?access_token=${access_token}&fields=*.*`)
            .then(res=>resolve(res.data))
            .catch(error=>reject(error))
    })
}

export function getPromotionPage(pageName){
    return new Promise((resolve, reject)=>{
        axios.get(
      `${items_url}pages?access_token=${access_token}&fields=*.*&filter[url][eq]=${pageName}`)
            .then(res=>resolve(res.data))
            .catch(error=>reject(error))
    })
}

export function getSettings() {
    return new Promise((resolve, reject)=>{
        axios.get(`${items_url}settings?access_token=${access_token}`)
            .then(res => resolve(res.data))
            .catch(error=>reject(error))
    })
}

export function login(username, password, callback, errorCallback) {
    axios.post(
        `${base_url}Login`,
        `username=${username}&password=${password}`,
        {headers: { Accept: "application/json", "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}}
    )
        .then((response)=>callback(response.data))
        .catch((error)=>errorCallback(error))
}

export function changePassword(username, oldPassword, newPassword, confirmPassword) {
  return new Promise((resolve, reject)=>{
      axios.post(
      `${base_url}UpdatePassword?username=${username}&oldpassword=${oldPassword}`+
          `&newpassword=${newPassword}&confirmnewpassword=${confirmPassword}`,
      {},
      {
          headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
          }
      }
      )
          .then(res=>resolve(res.data))
          .catch(error=>reject(error))
  })
}

export function getUserInfo(userCreds){
    return new Promise((resolve, reject)=>{
        axios.get(`${base_url}GetUserInfo/${userCreds.username}/${userCreds.password}`)
            .then(res=>resolve(res.data))
            .catch(error=>reject(error))
    })
}

export function getUserPreferences(userName) {
  return new Promise((resolve, reject)=>{
    axios.get(`${items_url}user_preferences?access_token=${access_token}&fields=*.*&filter[userid][eq]=${userName}`)
        .then(res=>resolve(res.data))
        .catch(error=>reject(error))
    })

}

export function getUserDetailFromDirectus(username) {
    const url = items_url + "user_detail?access_token=" + access_token + "&filter[username]=" + username
    return new Promise((resolve, reject)=>{
        axios.get(url).then(res=>resolve(res.data)).catch(error=>reject(error))
    })
}

export function updateUserDetails(data) {
  let url = items_url + "user_detail/" + data.id + "?access_token=" + access_token;
  return new Promise((resolve, reject)=>{
      axios.patch(url,data,{headers: {Accept: "application/json","Content-Type": "application/json",}})
          .then(res=>resolve(res.data))
          .catch(error=>reject(error))
  })
}

export function createUserDetails(data) {
  const URL = items_url + "user_detail?access_token=" + access_token;
  return new Promise((resolve, reject)=>{
      axios.post(URL,data,{headers: {Accept: "application/json","Content-Type": "application/json",}})
          .then(res=>resolve(res.data))
          .catch(error=>reject(error))
  })
}

export function updateUserDetailToNewDB(data) {
  const userCreds = JSON.parse(localStorage.getItem('userCreds'))
    return new Promise((resolve, reject)=>{
        axios.post(
            `${base_url}UpdateUser?userId=${userCreds.username}&password=${userCreds.password}`,
            data
        )
            .then(res=>resolve(res.data))
            .catch(error=>reject(error))
    })
}

export function updateUserProfile(data) {
    return new Promise((resolve, reject)=>{
        axios.post(
            `${base_url_v1}UpdateMyProfile`,
            data,
            {headers:{'content-type':'multipart/form-data', 'accept': '*/*'}}
        )
            .then(res=>resolve(res.data))
            .catch(error=>reject(error))
    })
}

export function getRefund(data) {
    data.mode = process.env.NODE_ENV==='development'? 'dev': 'prod'
    const URL = Reg_API_BASE_URL_V1 + "GetRefund"
    return new Promise((resolve, reject)=>{
        axios.post(URL, data, {headers:{"Content-Type": "application/json; charset=utf-8"}})
            .then(res=>resolve(res.data))
            .catch(error=>reject(error))
    })
}

export function checkDuplicateEmail(email) {
  const username = JSON.parse(localStorage.getItem('userCreds')).username
  const url = `${items_url}user_detail?access_token=${access_token}&filter[emailaddress]=${email}&filter[username][neq]=${username}`;
  return new Promise((resolve, reject)=>{
      axios.get(url)
          .then(res=>{
              console.log(res)
              resolve(res.data.data.length)
          })
          .catch(error=>reject(error))
  })
}

export function getUserDetailsByCustRef(custRef){
    return new Promise((resolve, reject)=>{
        axios.get(`${items_url}user_profiles?access_token=${access_token}&filter[customer_id][eq]=${custRef}`)
            .then(res => resolve(res.data))
            .catch(error=>reject(error))

    })
}

export function getBetSlipHistory(username, start_date, end_date, callback) {
    axios.get(`${items_url}bet_slip_history?access_token=${access_token}&filter[customer_id][eq]=${username}&filter[created_on][between]=${start_date},${end_date}`)
        .then(res => callback(res.data))
}

// Events

export function getNextRaces(sportCode="H,h"){
    return new Promise((resolve, reject)=>{
        axios.get(`${base_url_v1}newLive?sportcode=${sportCode}`,
            {timeout: 15000})
            .then(response=>{
                if (!response.data.data) resolve([])
                const data = JSON.parse(response.data.data)
                if (data.length > 0) {
                    const formattedArray = [];
                    data.forEach((v) => {
                        let res;
                        if (sportCode === "g,q" || sportCode === "$")
                            {
                                res = utility.greyHoundRaceFormat(v, sportCode);
                            }
                        else {
                            res = utility.liveRaceFormat(v, sportCode);
                        }

                        var result = ParseEventData(res);
                        formattedArray.push(result);
                    });
                    resolve(formattedArray);
                    }
            })
            .catch(error=>reject(error))
    })
}

export function getLiveEvents(){
    const URL = `${items_url}in_play_events_local?access_token=${access_token}`
    return new Promise((resolve, reject)=>{
        axios.get(URL)
            .then(response=>{
                return resolve(response.data)
            })
            .catch(error=>reject(error))
    })
}

export function getEvents(sportCode) {
    const sport = GetSportInfo(sportCode);
    let url = sport.URL;
    // if (Cache.Exists(key)) {
    //     callback(JSON.parse(Cache.Get(key)));
    // } else {
    let enableProxy = store.state.settings.enable_cache_apiproxy
    if(enableProxy){
        url = ApiProxy.Get + encodeURI(url);
    }
    return new Promise((resolve, reject)=>{
        axios(url)
            .then(function (response) {
                let res = response.data
                if(enableProxy){
                    res = response.data.Data
                }
                // Cache.Set(key, JSON.stringify(res));
                resolve(res);
            })
            .catch(error=>reject(error))
    })
}

export function getSingleEvent(ef, eg, ev, callback) {
    let url = `${base_url_v0}Ajax/Event/api.asp?ef=${ef}&eg=${eg}&e=${ev}`
    if(store.state.settings.enable_cache_apiproxy){
        url = `${base_url_v1}SingleEvent/Get?ef=${ef}&eg=${eg}&e=${ev}`
    }
    axios.get(url)
        .then(response=>{
            let result = (store.state.settings.enable_cache_apiproxy)? response.data.Data : response.data
            result = ParseEventData(result)
            callback(result)
        })
        .catch(error=>console.log(error))
}

export function getSportEvents(ef, eg) {
    let url = `${base_url}GetEventsFromAPI/${ef}/${eg}`;
    if(store.state.settings.enable_cache_apiproxy){
        url = ApiProxy.Get + encodeURI(url);
    }
    return new Promise((resolve, reject)=>{
      axios(url)
        .then(function (response) {
            let res = (store.state.settings.enable_cache_apiproxy)? response.data.Data : response.data
            console.log()
            const result = ParseEventArrayData(res);
            resolve(result);
        })
        .catch((error)=>reject(error))
    })
}

export function getEventDetails(sportCode, callback){
    axios.get(`${base_url}GetEventsDetail/${sportCode}?fullcard=1`)
        .then(response=>callback(response.data))
        .catch(error=>console.log(error))
}

export function getAntepostMeetingDetail(ef, eg, e, callback) {

    //Need to use GetEventsFromAPI for all antepost (https://nextpage.nifty.pm/l/JxZ8XSkX1dGHJ)
    let url = `${base_url}GetEventsFromAPI/${ef}/${eg}`;
    if(store.state.settings.enable_cache_apiproxy){
        url = ApiProxy.Get + encodeURI(url);
    }
    axios.get(url)
        .then(function (response) {

            let res = (store.state.settings.enable_cache_apiproxy)? response.data.Data : response.data
            const result = ParseEventArrayData(res);
            callback(result);
        })
        .catch(error=>console.log(error))
}

export function getStatements(userName, filterType, filterRange, callback) {
  axios.get(
      `${base_url}Getstatement_v2/${userName}/${filterType}/${filterRange}`,
      {headers: {"Content-Type": "application/x-www-form-urlencoded"}}
  ).then(res=>{
      callback(res.data)
  })
}

export function getAddressByZipcode(zipCode) {
  return new Promise((resolve, reject) => {
    axios.get("https://api.getaddress.io/find/" + zipCode + "?api-key=" + Reg_API_KEY)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPageData(pageName) {
    return new Promise((resolve, reject)=>{
    axios.get(`${items_url}pages?access_token=${access_token}&fields=*.*&filter[url][eq]=${pageName}`)
        .then(res=>resolve(res.data))
        .catch(error=>reject(error))
    })
}

export function updateOddsFormatInPreferences(oddsFormat){
    return new Promise((resolve, reject)=>{
        getUserPreferences(store.state.user.customer_id).then(data=>{
            if (data.data.length) { // If preferences are exists, update this
                axios.patch(
                `${items_url}user_preferences/${data.data[0].id}?access_token=${access_token}`,
                {odds_format: oddsFormat},
                {headers: {Accept: "application/json", "Content-Type": "application/json"}}
                )
                .then(res=>resolve(res.data))
                .catch(error=>reject(error))
            } else { // Create new preferences
                axios.post(
                `${items_url}user_preferences?access_token=${access_token}`,
                { userid: store.state.user.customer_id, odds_format: oddsFormat,}
                )
                .then(res=>resolve(res.data))
                .catch(error=>reject(error))
            }
            store.dispatch('SET_USER_DATA', {oddsFormat: oddsFormat})
        })
            .catch(error=>reject(error))
    })
}

// Video Stream

export function getRMGEventList() {
    const URL = Reg_API_BASE_URL_V1 + "GetRMGEventList";
    return new Promise((resolve, reject)=>{
        axios.get(URL)
        .then(response=>resolve(response.data))
        .catch(error=>reject(error));
    })
}

export function getStreamEvents() {
    let random_no = Math.random().toString(36).substring(7);
    const URL = siteRoot + "/atr/events.php?c=" + random_no;
    return new Promise((resolve, reject)=>{
        axios.get(URL)
        .then(response=>resolve(response.data))
        .catch(error=>reject(error));
    })
}

export function getSISEvents(sportcode) {
    let sportId = "";
    if (sportcode != undefined && sportcode != "") {
    sportId = getSportId(sportcode);
    }
    let URL = `${Reg_API_BASE_URL_V1}GetSISEvents`;
    if (sportId != "") {
    URL += `?sportId=${sportId}`;
    }
    return new Promise((resolve, reject)=>{
        axios.get(URL)
            .then(response=>resolve(response.data))
            .catch(error=>reject(error))
    })
}

export function getRMGStream(data) {
    return new Promise((resolve, reject)=>{
        axios.post(`${Reg_API_BASE_URL_V1}GetRMGStream`, data)
            .then(response=>resolve(response.data))
            .catch(error=>reject(error))
    })
}

export function getSISStream(data) {
  return new Promise((resolve, reject)=>{
        axios.post(`${Reg_API_BASE_URL_V1}GetSISStream`, data)
            .then(response=>resolve(response.data))
            .catch(error=>reject(error))
    })
}

// BetSlip

export function getWagerInfo(officeNo, referenceNo) {
    const URL = `${base_url_v0}cashier/wagerinfo-api.asp?officeNum=${officeNo}&type=credit&locale=&wagerNo=${referenceNo}&events=c:*EVENTS*&audit=`;
    return new Promise((resolve, reject)=>{
      axios.get(URL)
        .then(response=>resolve(response.data))
        .catch(error=>reject(error));
    })
}

// Paydoo

export function getCheckoutIdForPaydoo(data) {
  const URL = `${paydoo_base_url}${process.env.NODE_ENV === 'production' ?'':'test/'}create_payment`
  return new Promise((resolve, reject)=>{
    axios.post(
        URL,
        data,
        {headers: {"Content-Type": "application/json; charset=utf-8", Authorization: `Bearer ${paydoo_token}`}}
    )
        .then(res=>resolve(res.data))
        .catch(error=>reject(error))
    })
}

export function getPaydooPaymentStatus(checkoutId, userName) {
  const URL = `${paydoo_base_url}${process.env.NODE_ENV === 'production' ?'':'test/'}checkout_status/${checkoutId}?user=${userName}`
  return new Promise((resolve, reject)=>{
    axios.get(
        URL,
        {headers: {"Content-Type": "application/json; charset=utf-8", Authorization: `Bearer ${paydoo_token}`}}
    )
        .then(res=>resolve(res.data))
        .catch(error=>reject(error))
    })
}

// PayPal

export function savePayPalDeposit(data){
    const URL = Reg_API_BASE_URL_V1 + "paypal_deposits"
    return new Promise((resolve, reject)=>{
        axios.post(URL, data,
            {headers: {"Content-Type": "application/json; charset=utf-8"}}
        )
            .then(res=>resolve(res.data))
            .catch(error=>reject(error))
        })
}


// Back Claim
export function checkClaim(username){
    const URL = `${items_url}20backclaim?access_token=${access_token}&filter[username]=${username}`
    return new Promise((resolve, reject)=>{
        axios.get(URL,{headers: {"Content-Type": "application/json; charset=utf-8"}}
        )
            .then(res=>resolve(res.data))
            .catch(error=>reject(error))
        })
}

export function postClaim(data){
    const URL = `${items_url}20backclaim?access_token=${access_token}`
    return new Promise((resolve, reject)=>{
        axios.post(URL, data,
            {headers: {"Content-Type": "application/json; charset=utf-8"}}
        )
            .then(res=>resolve(res.data))
            .catch(error=>reject(error))
        })
}

export function sendEmailByClaim(data){
    const URL = `${siteRoot}email/geoff.php?c=242`
    return new Promise((resolve, reject)=>{
        axios.post(URL, data,
            {headers: {"Content-Type": "multipart/form-data; charset=utf-8"}}
        )
            .then(res=>resolve(res))
            .catch(error=>reject(error))
        })
}