<template>
<div
  class="container-fluid desktop-container"
  :class="['sport', 'raceEvent', 'matchEvent'].includes($route.name)?'desktop-container-with-sportsbar':''"
>
  <div class="row bg-light py-3">
    <div class="col-2 px-2">
      <div class="rounded-3 bg-white position-relative">
        <side-bar/>
      </div>
    </div>
    <div class="col-7 px-0">
      <div class="router-container">
        <router-view v-slot="{Component}">
          <transition name="fade">
            <component :is="Component"/>
          </transition>
        </router-view>
      </div>
    </div>
    <div class="col-3 position-relative">
      <div class="betslip-container">
        <div class="rounded-3 px-2 pb-2">
          <bet-slip-main/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import BetSlipMain from "@/components/BetSlip/BetSlipMain";
import SideBar from "@/components/Desktop/SideBar";
export default {
  name: "BaseDescktopView",
  components: {SideBar, BetSlipMain}
}
</script>

<style scoped>
.desktop-container{
  margin-top: 5rem;
  background-color: #FFFFFF;
}
.router-container{
  position: relative;
  width: 100%;
  min-height: 80vh;
}
.betslip-container{
  right: 0;
  position: fixed;
  width: inherit;
  z-index: 100;
}
</style>