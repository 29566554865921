<template>
  <div class="bet-list-container position-relative" :class="{'bet-list-container-desktop': !$store.state.isMobile}">
  <div class="p-3 bg-light">
    <bet-list-item
      v-for="(bet, index) in bets"
      :key="index"
      :bet="bet"
    />
   </div>
  </div>
</template>

<script>
import BetListItem from "@/components/BetSlip/BetListItem";

export default {
  name: "BetsList",
  components: {BetListItem},
  props: ['bets'],
}
</script>

<style scoped>
.bet-list-container{
  overflow: scroll;
  max-height: calc(100vh - 30.5rem);
  min-height: 5rem;
}
.bet-list-container-desktop{
  max-height: calc(100vh - 33.5rem);
}
</style>