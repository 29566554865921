<template>
  <div
    class="countdown"
    :class="{'bg-secondary': !active && ((hours === 0 && minutes >= 10) || hours>0)}"
    v-if="countdown"
  >
    <i class="bi bi-clock"></i>
    <span class="ms-1">{{ countdown }}</span>
  </div>
</template>

<script>
export default {
  name: "RaceCountdown",
  props: ['time', 'active'],
  data:()=>{
    return{
      countdownInterval: null,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },
  watch:{
    time(){
      this.setCountdown()
    }
  },
  computed:{
    countdown(){
      let m, s
      m = this.minutes<10 ? '0'+this.minutes : this.minutes
      s = this.seconds<10 ? '0'+this.seconds : this.seconds
      if (this.hours) {
        return `${this.hours}:${m}:${s}`
      } else {
        return `${m}:${s}`
      }
    }
  },
  mounted() {
    this.setCountdown()
  },
  beforeUnmount() {
    clearInterval(this.countdownInterval)
  },
  methods:{
    setCountdown() {
      const vm = this
      if (vm.countdownInterval){
        clearInterval(vm.countdownInterval)
        vm.countdownInterval = null
      }
      let time = vm.time
      if (typeof time != "undefined" && time.indexOf(":") > 0) {
          var dd = time.split(":");
          if (dd.length === 3) {
            vm.hours = parseInt(dd[0])
            vm.minutes = parseInt(dd[1])
            vm.seconds = parseInt(dd[2])
            } else {
              vm.minutes = parseInt(dd[0])
              vm.seconds = parseInt(dd[1])
            }
          vm.countdownInterval = setInterval(()=>{
            if (vm.seconds>0) {
              vm.seconds-=1
            } else {
              vm.seconds = 59
              if (vm.minutes>0){
                vm.minutes-=1
              } else {
                vm.minutes = 59
                if (vm.hours>0) vm.hours-=1
              }
            }
          }, 1000)
        }
    }
  }
}
</script>

<style scoped>
.countdown{
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FD7760;
  color: #FFFFFF;
  border-radius: 3px;
  padding: 1px 3px;
  width: max-content;
  height: max-content;
  font-size: 12px;
  line-height: 16px;
}
</style>