<template>
  <div v-if="userDataLoading" class="bg-dark position-absolute" style="width: 100vw; height: 100vh">
    <pre-loader />
  </div>
  <div v-if="!userDataLoading" class="app-container" id="scroll-content" :class="{'overflow-scroll': !$store.state.isMobile}">
  <main-header v-if="!$store.state.isMobile"/>
<!--  <router-view/>-->
    <router-view v-slot="{Component}">
      <transition name="fade">
        <component :is="Component"/>
      </transition>
    </router-view>
  <main-footer v-if="!$store.state.isMobile"/>
  <reality-check v-if="$store.state.authToken"></reality-check>
  <geo-access v-if="$store.state.settings.enable_location_access_check && !countryChecked && $store.state.platform!=='web'" @checked="countryChecked=true"/>
  <mobile-app-offer v-if="$store.state.platform==='web'"/>
  </div>
</template>

<style>

</style>
<script>
import {mapActions} from "vuex"
import {isTokenExpired} from "@/services/betslip/_helpers";
import {localStorageService} from "@/services/betslip/_services";
import {AUTH_TOKEN_EXPIRED_AT} from "@/services/betslip/_constants";
import RealityCheck from "@/components/Sevice/RealityCheck";
import {NativeBiometric} from "capacitor-native-biometric";
import {useToast} from "vue-toastification";
import {App} from "@capacitor/app"
import PreLoader from "@/components/UI/PreLoader";
import GeoAccess from "@/components/Sevice/GeoAccess";
import MainFooter from "@/components/MainFooter";
import MainHeader from "@/components/MainHeader";
import MobileAppOffer from "@/components/Sevice/MobileAppOffer";
export default {
  components: {MobileAppOffer, MainHeader, MainFooter, GeoAccess, PreLoader, RealityCheck},
  data:()=>{
    return{
      userDataLoading: true,
      tiresAuth: 3,
      countryChecked: false,
    }
  },
  computed:{
    currentSportTitle(){
      if (["sport", "raceEvent", "matchEvent", "antepostEvent"].includes(this.$route.name)){
        return this.$route.params.sportName
      }
      return ''
    },
    showSportsBar(){
      return ["home", "sport", "raceEvent", "matchEvent", "antepostEvent"].includes(this.$route.name)
    }
  },
  setup(){
    const toast = useToast()
    return { toast }
  },
  mounted(){

    this.getSettings()
    .then(()=>{
      this.autoLogin()
    })
    this.setAppUrlOpenHandler()
  },
  methods:{
    ...mapActions(['GET_SETTINGS','SET_USER_DATA']),
    setAppUrlOpenHandler(){
      App.addListener('appUrlOpen', data => {
        const slug = data.url.split('#').pop()
        console.debug('open path', slug)
        this.$router.push(slug)
      })
    },
    getSettings(){
      const vm = this
      return new Promise(resolve => {
        vm.$store.dispatch('GET_SETTINGS')
        .then((res)=>resolve(res))
      })
    },
    autoLogin(){
      const vm = this
      const userCreds = JSON.parse(localStorage.getItem('userCreds'))
      const useBiometricLogin = JSON.parse(localStorage.getItem('useBiometricLogin'))
      let authTokenExpiredAt = localStorageService.getItem(AUTH_TOKEN_EXPIRED_AT)
      if(authTokenExpiredAt && isTokenExpired(authTokenExpiredAt)){
        this.$store.dispatch('LOGOUT')
        vm.userDataLoading=false
        vm.toast.warning('Your account token has expired! \nLog In again.')
      } else if (this.$store.state.platform !== "web" && useBiometricLogin) {
          console.log('>>> native login')
          this.nativeAutoLogin()
      } else if (userCreds){
          console.log('>>> web login')
          this.login()
      } else {
        vm.userDataLoading = false
      }
    },
    nativeAutoLogin(){
      const vm = this
      NativeBiometric.isAvailable()
        .then((result) => {
          const isAvailable = result.isAvailable;
          console.log("biometric is available RESULT >>", JSON.stringify(result))
          if (isAvailable) {
            vm.nativeAuth()
              .then((credentials)=> {
                console.log(credentials)
              })
              .catch((error)=>{
                console.error('nativeAuth Error', JSON.stringify(error))
              })
              .finally(()=>vm.userDataLoading = false)
          }
      })
      .catch((error)=>{
        console.error('NativeAutoLogin Error', JSON.stringify(error))
        vm.userDataLoading = false
      })
    },
    nativeAuth(){
      const vm = this
      return new Promise((resolve, reject)=> {
        NativeBiometric.getCredentials({server: "m1.geoffbanks.bet"})
            .then((credentials) => {
              console.log('>>>> got credentials >> ', credentials)
              // Authenticate using biometrics before logging the user in
              NativeBiometric.verifyIdentity({
                reason: "Login to Geoff Banks Online",
                title: "Login to Geoff Banks Online",
                subtitle: `${vm.tiresAuth} tries left.`
              })
              .then(
                  () => {
                    // Authentication successful
                    vm.login(credentials)
                      .catch(() => {
                        vm.$store.dispatch('LOGOUT')
                      })
                      .finally(() => {
                        resolve(credentials)
                      })
                  },
                  (error) => {
                    // Authentication failed
                    if (error.code === "10" && vm.tiresAuth > 0) {
                      vm.tiresAuth -= 1
                      resolve(vm.nativeAuth())
                    } else reject(error)
                  }
              )
            })
            .catch((err) => reject(err))
      })
    },
    login(userCreds=null){
      if (!userCreds) userCreds = JSON.parse(localStorage.getItem('userCreds'))
      const vm = this
      const userDataJSON = localStorage.getItem('user')
      if (userDataJSON) vm.SET_USER_DATA(JSON.parse(userDataJSON)).then(()=>vm.userDataLoading = false)
      return new Promise((resolve, reject)=>{
        vm.$store.dispatch('LOGIN',userCreds)
          .then(()=>resolve())
          .catch(err=>{
            console.error(`!!!!!!!!! LOGIN ERROR ${err}`)
            vm.$store.dispatch('LOGOUT')
            reject(err)
          })
      })
    }
  }
}
</script>
<style scoped>
.app-container{
  height: 100%;
  width: 100%;
  position: relative;
}

</style>