import * as axios from 'axios';
import { AUTH_TOKEN_EXPIRED_AT, AUTH_TOKEN_KEY } from '../_constants/localStorageKeys';
import { localStorageService } from './localStorageService';
import {BETSLIP_BASE_URL} from "@/services/betslip/_constants";
import store from "@/store";

export const authApiService = (function(){
    function BetslipApiSingleton() {        
        let client = null;


        const init = () => {
            const headers = {
                'Content-Type': 'application/json',
                Accept: '*/*',
            };

            client = axios.create({
                baseURL: BETSLIP_BASE_URL + '/api',
                timeout: 230000,
                headers
            });
    
            return client;
        }
    
        const login = (credsPayload) => {
            return new Promise((resolve, reject) => {
                init()
                    .post('/auth/token', credsPayload)
                    .then(resp => resp.data)
                    .then(resp => {
                        if (resp) {
                            localStorageService.setItem(AUTH_TOKEN_KEY, resp.access_token || '');
                            localStorageService.setItem(AUTH_TOKEN_EXPIRED_AT, resp.expires_at || '');
                            resolve(resp)
                        }
                    })
                    .catch(error=>reject(error))
            })
        }

        const logout = () => {
            store.dispatch('LOGOUT').then()
        };
    
        return {
            login,
            logout
        };
    }

    let instance = null;

    if (instance == null) {
        instance = new BetslipApiSingleton();

        // Hide the constructor so the returned object can't be new'd...
        instance.constructor = null;
    }
    return instance;
})();