<template>
<races-list-menu
    v-if="meeting.Events"
    :races="meeting.Events"
    :current-race="selectedEvent"
    @selectrace="selectRace"
/>
  <pre-loader v-if="loading"/>
<div v-else >
  <video-stream
      v-if="currentRace.MeetingName && ['h,H', 'H,h', 'g,q', 'vh', '$'].includes(sport.code)"
      :sport-code="sport.code"
      :location-name="currentRace.MeetingName.split(' ')[0]"
      :event-time="currentRace.MeetingName.split(' ')[1]"
    />
    <div class="d-flex flex-column h-100">
      <div class="col bg-light" >
          <race-event
              v-if="currentRace.Races && currentRace.Status!=='Result'"
              :race="currentRace"
              :sport="sport"
          />
          <race-event-results
            v-if="currentRace.Status==='Result'"
            :race="currentRace"
            :sport="sport"
          />
      </div>
    </div>
  </div>
</template>

<script>
import PreLoader from "@/components/UI/PreLoader";
import RacesListMenu from "@/components/Event/RacesListMenu";
import RaceEvent from "@/components/Event/RaceEvent";
import RaceEventResults from "@/components/Event/RaceEventResults";
import VideoStream from "@/components/VideoStream";
export default {
name: "RaceEventView",
  components: {VideoStream, RaceEventResults, RaceEvent, RacesListMenu, PreLoader},
data:()=>{
  return{
    meeting: {},
    selectedEvent:{},
    currentRace: {},
    loading: false,
    sport: {},
    eventFile: '',
    eventGroup: '',
    intervalLoad: null
  }
},
  beforeMount() {
    this.sport = this.API.SPORTS.filter(sport => sport.title.toLowerCase() === this.$route.params.sportName.toLowerCase())[0]
    this.eventFile = this.$route.params.ef
    this.eventGroup = this.$route.params.eg
  },
  beforeUnmount() {
    if (this.intervalLoad) clearInterval(this.intervalLoad)
  },
  mounted() {
    const vm = this
    vm.loadData()
    vm.intervalLoad = setInterval(()=>vm.loadData(), 5000)
  },
  methods:{
  loadData(){
    let vm = this
    if (!vm.meeting.MeetinName) vm.loading = true
    let sportCode = this.$route.query.sportCode? this.$route.query.sportCode: this.sport.code
    this.API.getEvents(sportCode)
    .then((data)=>{
      for (let event of data){
        for (let meeting of event.Meetings){
          if (meeting.EventFile === this.eventFile && meeting.EventGroup === this.eventGroup){
            vm.meeting = meeting
            break
          }
        }
        if (vm.meeting.Events) break
      }
      vm.loading = false
      let eventIdx = this.$route.query.ev?this.$route.query.ev:0
      vm.selectedEvent = vm.meeting.Events[eventIdx]
      vm.getRaceDetails(vm.meeting.Events[eventIdx])
    })
  },
  getRaceDetails(event){
    let vm = this
    if (!vm.meeting.MeetinName) vm.loading = true
    this.API.getSingleEvent(event.EventFile, event.EventGroup, event.EventIdx, (data)=>{
      if (['g,q', '$'].includes(vm.sport.code) && data.Status!=='Result'){
        let sortedRace = {...data}
        sortedRace.Races = data.Races.sort((a,b)=>{
          if (parseInt(a.selection) < parseInt(b.selection)) return -1
          return 1
        })
        vm.currentRace = sortedRace
      } else {
        vm.currentRace = data
      }
      vm.loading = false
    })
  },
  selectRace(race){
    this.selectedEvent = race
    this.getRaceDetails(race)
    this.$router.push(
        {
          name: 'raceEvent',
          params: {
            sportName: this.sport.title,
            ef: this.eventFile,
            eg: this.eventGroup
          },
          query:
            {
              ev: this.selectedEvent.EventIdx,
              sportCode: this.sport.code
            }
        })
  }
}
}
</script>

<style scoped>

</style>