<template>
<div><scroll-tabs-bar
    v-if="categories.length && $store.state.isMobile"
    :items="categories"
    :selected-item="currentCategory"
    @selectitem="selectCategory"
  ></scroll-tabs-bar>
<pre-loader v-if="loading"/>
<no-events v-else-if="eventCategories.length===0 && antepostEvents.length===0" :title="sport.title+ ' matches'"/>
<div v-else>
  <div v-if="currentCategory==='All'">
    <transition-group name="flip-list" tag="div" class="position-relative">
    <div
      v-for="category in sortedEventCategories"
      :key="category.Id"
      class="py-1 px-2"
      :class="$store.state.isMobile?'bg-light':'rounded-3 bg-white mb-2'"
    >
      <div
        class="py-2 ps-2 fw-600"
        :class="$store.state.isMobile?'':'border-bottom'"
      >{{category.EventName}}</div>
      <CompetitionsListItem
          v-for="(event, index) in category.events"
          :key="index"
          :event="event"
          :sport="sport"
          :class="$store.state.isMobile?'':'border-bottom'"
          class="my-1"
      />
    </div>
    </transition-group>
  </div>
  <div v-else>
    <antepost-events-list
      :events="antepostEvents"
      :sport="sport"
      :sport-code="sport.code"
    />
  </div>

</div>
</div>
</template>

<script>
import axios from "axios";
import ScrollTabsBar from "@/components/UI/ScrollTabsBar";
import PreLoader from "@/components/UI/PreLoader";
import CompetitionsListItem from "@/components/Sport/Competitions/CompetitionsListItem";
import NoEvents from "@/components/Sport/NoEvents";
import AntepostEventsList from "@/components/Sport/AntepostEventsList";
export default {
name: "SportCompetitions",
  components: { AntepostEventsList, NoEvents, CompetitionsListItem, PreLoader, ScrollTabsBar},
  props: ['sport'],
  data: ()=>{
    return{
      categories: [],
      loading: false,
      eventCategories: [],
      antepostEvents: [],
      currentCategory: null,
      currentEventCategory: null,
      currentSportCode: '',
      intervalLoad: null,
    }
  },
  beforeUnmount() {
    if (this.intervalLoad) clearInterval(this.intervalLoad)
  },
  mounted() {
    this.emitter.on('select-event-category', this.selectEventCategory)
    const vm = this
    if (this.sport.url) this.categories.push('All')
    this.currentSportCode = this.sport.code
    if (this.sport.antePostUrl) this.categories.push('Futures')
    this.currentCategory = this.$route.query.category || this.categories[0]
    vm.loadEvents()
    vm.intervalLoad = setInterval(()=>vm.loadEvents(), 5000)
  },
  computed:{
    sortedEventCategories(){
      if (this.currentEventCategory){
        let sortedEventCategories = this.eventCategories
            .filter(c=>c.EventName!==this.currentEventCategory.EventName)
        sortedEventCategories.unshift(this.currentEventCategory)
        return sortedEventCategories
      } else {
        return this.eventCategories
      }
    }
  },
  watch:{
    currentCategory(category){
      if (category === 'Futures'){
        this.loadEvents(this.sport.antePostUrl)
      } else {
        this.loadEvents(this.sport.url)
      }
    },
    '$route.query.selectedCategoryEventName'(value){
      if (value) this.selectEventCategory(value)
    }
  },
  methods:{
    loadEvents(url=null){
      let vm = this
      if (!vm.eventCategories.length && !vm.antepostEvents.length) vm.loading = true
      if (!url) url = this.sport.url ? this.sport.url: this.sport.antePostUrl
      axios.get(url)
      .then(response=>{
        if (response.data[0].Meetings){
          vm.antepostEvents = response.data
        } else {
          vm.eventCategories = response.data.map(cat => {
            let category = cat
            category.events = []
            cat.Competitions.map(comp => {
              comp.details.map(ev => {
                let event = ev
                event.date = comp.date
                event.Code = cat.Code
                event.EventName = comp.EventName
                category.events.push(event)
                // allEvents.push(event)
                return event
              })
            })
            return category
          })
        }
        vm.loading=false
        if (vm.$route.query.selectedCategoryEventName) vm.selectEventCategory(vm.$route.query.selectedCategoryEventName)
      })
      .catch(error=>{console.log(error), vm.loading=false})
    },
    selectCategory(categoryName){
      if (this.$store.state.isMobile){
        this.currentCategory = categoryName
        this.antepostEvents = []
        this.eventCategories = []
        this.$router.push({name: 'sport', params: {sportName: this.sport.title }, query:{category:categoryName}})
      }
    },
    selectEventCategory(selectedEventCategoryName){
      this.currentEventCategory = this.eventCategories.filter(c=>c.EventName===selectedEventCategoryName)[0]
    }
  }

}
</script>

<style scoped>
.live-match-item{
  width: auto;
  background: #FFFFFF;
  border-radius: 0.25rem;
}
.flip-list-move{
  transition: transform 0.3s;
}
</style>