<template>
<pre-loader v-if="loading"></pre-loader>
<iframe
  v-show="!loading"
  ref="wufoo"
  allowtransparency="true"
  frameBorder="0"
  class="wufoo-form-container"
  src="https://geoffbanks.wufoo.com/forms/r6vu9ru1jw3aij" >
  <a href="https://geoffbanks.wufoo.com/forms/r6vu9ru1jw3aij">
  </a>
</iframe >
</template>

<script>
import PreLoader from "@/components/UI/PreLoader";
export default {
  name: "ResposibleGamblingView",
  components: {PreLoader},
  data:()=>{
    return{
      loading:true
    }
  },
  mounted() {
    this.$refs.wufoo.onload = ()=>{
      this.loading=false
    }
  }
}
</script>

<style scoped>

</style>