import {createRouter, createWebHashHistory} from 'vue-router'
import store from "../store/index";
import HomeView from '../views/HomeView.vue'
import SportsListView from "@/views/SportsListView";
import TransactionsView from "@/views/TransactionsView";
import AccountView from "@/views/AccountView";
import SportView from "@/views/SportView";
import RaceEventView from "@/views/RaceEventView";
import MatchEventView from "@/views/MatchEventView";
import AntepostEvent from "@/components/Sport/Races/AntepostEvent";
import LoginView from "@/views/auth/LoginView";
import RegistrationView from "@/views/auth/RegistrationView";
import ResetPasswordView from "@/views/auth/ResetPasswordView";
import AccountProfileView from "@/views/account/AccountProfileView";
import ChangePasswordView from "@/views/account/ChangePasswordView";
import DeactivateAccountView from "@/views/account/DeactivateAccountView";
import UpdateAccountView from "@/views/account/UpdateAccountView";
import AccountBaseView from "@/views/account/AccountBaseView";
import AccountVerificationView from "@/views/account/AccountVerificationView";
import ReactivateAccountView from "@/views/account/ReactivateAccountView";
import InfoPageView from "@/views/InfoPageView";
import resposibleGamblingView from "@/views/account/ResposibleGamblingView";
import CardDepositView from "@/views/payment/CardDepositView";
import UpdateDepositLimitVIew from "@/views/payment/UpdateDepositLimitVIew";
import WithdrawalView from "@/views/payment/WithdrawalView";
import SignUpFormStackView from "@/views/auth/SignUpFormStackView";
import PromosView from "@/views/PromosView";
import PromoDetail from "@/views/PromoDetailView";
import BaseMenuView from "@/views/BaseMenuView";
import BaseDescktopView from "@/views/BaseDescktopView";
import BaseAccountDesktopView from "@/views/BaseAccountDesktopView";
import SportBaseView from "@/views/BaseSportView";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 1024

let routes = []

if (isMobile){
    routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView
  },
  {
    path: '/sign-up',
    name: 'signup',
    component: SignUpFormStackView
  },
  {
    path: '/',
    name: 'base',
    component: isMobile?BaseMenuView:BaseDescktopView,
    children: [
     {
        path: '/',
        name: 'home',
        component: HomeView
      },
      {
        path: '/promos',
        name: 'promos',
        component: PromosView
      },
      {
        path: '/promotions/:page',
        name: 'promo',
        component: PromoDetail,
      },
      {
        path: '/sports',
        name: 'sports',
        component: SportsListView
      },
      {
        path: '/sport/:sportName',
        name: 'sport',
        component: SportView,
      },
      {
        path: '/info/:infoType',
        name: 'info',
        component: InfoPageView,
      },
      {
        path: '/sport/:sportName/race/:ef/:eg',
        name: 'raceEvent',
        component: RaceEventView
      },
      {
        path: '/sport/:sportName/match/:ef/:eg',
        name: 'matchEvent',
        component: MatchEventView
      },
      {
        path: '/antepost/:sportName/:ef/:eg',
        name: 'antepostEvent',
        component: AntepostEvent
      },
      {
        path: '/reset_password',
        name: 'resetPassword',
        component: ResetPasswordView
      },
      {
        path: '/transactions',
        name: 'transactions',
        meta: {requiresAuth:true},
        component: TransactionsView
      },
      {
        path: '/payment/deposit',
        name: 'deposit',
        meta: {requiresAuth:true},
        component: CardDepositView,
      },
      {
        path: '/payment/deposit',
        name: 'updateDepositLimit',
        meta: {requiresAuth:true},
        component: UpdateDepositLimitVIew,
      },
      {
        path: '/payment/withdrawal',
        name: 'withdrawal',
        meta: {requiresAuth:true},
        component: WithdrawalView,
      },
      {
        path: '/account',
        component: isMobile?AccountBaseView:BaseAccountDesktopView,
        children: [
          {path: '', name: 'account', component: AccountView},
          {path: 'profile', name: 'profile', component: AccountProfileView, meta: {requiresAuth:true}},
          {path: 'update', name: 'updateProfile', component: UpdateAccountView, meta: {requiresAuth:true}},
          {path: 'change_password', name: 'changePassword', component: ChangePasswordView, meta: {requiresAuth:true}},
          {path: 'deactivate', name: 'deactivateProfile', component: DeactivateAccountView},
          {path: 'reactivate', name: 'reactivateProfile', component: ReactivateAccountView},
          {path: 'verification', name: 'accountVerification', component: AccountVerificationView},
          {path: 'responsible-gambling', name: 'responsibleGambling', component: resposibleGamblingView},
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: isMobile?BaseMenuView:BaseDescktopView
  }
]
} else {
    routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView
  },
  {
    path: '/sign-up',
    name: 'signup',
    component: SignUpFormStackView
  },
  {
    path: '/',
    name: 'base',
    component: isMobile?BaseMenuView:BaseDescktopView,
    children: [
     {
        path: '/',
        name: 'home',
        component: HomeView
      },
      {
        path: '/promos',
        name: 'promos',
        component: PromosView
      },
      {
        path: '/promotions/:page',
        name: 'promo',
        component: PromoDetail,
      },
      {
        path: '/sports',
        name: 'sports',
        component: SportsListView
      },
      {
        path: '/info/:infoType',
        name: 'info',
        component: InfoPageView,
      },
    ]
  },
  {
    path: '/sport/:sportName',
    component: SportBaseView,
    children:[
      {
        path: '',
        name: 'sport',
        component: SportView,
      },
      {
        path: 'race/:ef/:eg',
        name: 'raceEvent',
        component: RaceEventView
      },
      {
        path: 'match/:ef/:eg',
        name: 'matchEvent',
        component: MatchEventView
      },
      {
        path: 'antepost/:ef/:eg',
        name: 'antepostEvent',
        component: AntepostEvent
      },
    ]
  },
  {
    path: '/account',
    component: BaseAccountDesktopView,
    redirect: {name: 'transactions'},
    children: [
      {
        path: '/transactions',
        name: 'transactions',
        meta: {requiresAuth:true},
        component: TransactionsView
      },
      // {path: '', name: 'account', component: AccoutOverviewView},
      {path: 'profile', name: 'profile', component: AccountProfileView, meta: {requiresAuth:true}},
      {path: 'update', name: 'updateProfile', component: UpdateAccountView, meta: {requiresAuth:true}},
      {path: 'change_password', name: 'changePassword', component: ChangePasswordView, meta: {requiresAuth:true}},
      {path: 'deactivate', name: 'deactivateProfile', component: DeactivateAccountView},
      {path: 'reactivate', name: 'reactivateProfile', component: ReactivateAccountView},
      {path: 'verification', name: 'accountVerification', component: AccountVerificationView},
      {path: 'responsible-gambling', name: 'responsibleGambling', component: resposibleGamblingView},
      {
        path: '/reset_password',
        name: 'resetPassword',
        component: ResetPasswordView
      },
      {
        path: '/payment/deposit',
        name: 'deposit',
        meta: {requiresAuth:true},
        component: CardDepositView,
      },
      {
        path: '/payment/deposit',
        name: 'updateDepositLimit',
        meta: {requiresAuth:true},
        component: UpdateDepositLimitVIew,
      },
      {
        path: '/payment/withdrawal',
        name: 'withdrawal',
        meta: {requiresAuth:true},
        component: WithdrawalView,
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: isMobile?BaseMenuView:BaseDescktopView
  }
]
}

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = "Geoff Banks Online"
  let content = document.getElementById('scroll-content')
  if (content) content.scrollTo(0,0)
  if (to.name === 'not-found') next({name: 'home'})
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next ({name: 'login', query: {from: to.path}})
  } else {
    // if (from.path === '/account' && !isMobile) next('/profile')
    next()
  }
})


export default router
