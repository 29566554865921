export const isTokenExpired = (tokenExpirationDate) => {
    if (!tokenExpirationDate) {
        return true;
    }

    const dateNow = new Date();
    const dateNowInUTCMs = Date.UTC(
        dateNow.getUTCFullYear(),
        dateNow.getUTCMonth(),
        dateNow.getUTCDate(),
        dateNow.getUTCHours(),
        dateNow.getUTCMinutes(),
        dateNow.getUTCSeconds()
    );
    const tokenExpirationDateMs = new Date(tokenExpirationDate).getTime();
    return tokenExpirationDateMs <= dateNowInUTCMs;

};