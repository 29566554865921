<template>
<pre-loader v-if="loading"></pre-loader>
<iframe
    v-show="!loading"
    ref="wufoo"
    title="Embedded Wufoo Form"
    id="wufooFormw7fzzwg1mfw6t6"
    class="wufoo-form-container"
    allowtransparency="true"
    frameborder="0"
    style="min-height: 900px"
    src="https://geoffbanks.wufoo.com/embed/w7fzzwg1mfw6t6/def/embedKey=w7fzzwg1mfw6t6487630&amp;entsource=&amp;referrer=">
  <a href="https://geoffbanks.wufoo.com/forms/w7fzzwg1mfw6t6/" title="html form">Fill out my Wufoo form!</a>
</iframe>
</template>

<script>
import PreLoader from "@/components/UI/PreLoader";
export default {
  name: "AccountVerificationView",
  components: {PreLoader},

  data:()=>{
    return{
      loading: true,
      height: '500px',
    }
  },
  mounted() {
    this.$refs.wufoo.onload = ()=>{
      this.loading=false
      this.$refs.wufoo.style.height = this.$refs.wufoo.contentWindow.document.body.scrollHeight + 'px'
    }
  },
}
</script>

<style scoped>

</style>