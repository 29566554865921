<template>
<div class="bet-item border-bottom d-flex justify-content-between py-2">
  <div class="d-flex justify-content-start align-items-center flex-fill pe-3">
    <div>
      <div class="fs-14 fw-600"> {{ bet.detail }} </div>
      <div class="fs-12"> {{ bet.name }} @ {{ bet.newPC }}</div>
    </div>
    <info-icon v-if="bet.isBog" class="ms-3 fs-14">BOG</info-icon>
  </div>
  <div class="d-flex">
    <div class="odd fs-17 fw-600 me-2"><span>{{ glb.formatPrice(bet.priceCode==='S'?bet.newPC:bet.price) }}</span></div>
    <div @click="removeBet(bet)" class="remove-bet">
      <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <img v-else alt="icon" :src="require('@/assets/close-circle.svg')"/>
    </div>
  </div>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {authApiService, betslipApi} from "@/services/betslip/_services";
import InfoIcon from "@/components/UI/InfoIcon";

export default {
  name: "BetListItem",
  components: {InfoIcon},
  props: ['bet'],
  data:()=>{
    return{
      loading: false,
    }
  },
  computed:{
    ...mapGetters(['BETSLIP'])
  },
  methods:{
    ...mapActions(['SET_BETSLIP']),
    removeBet(bet){
      const vm = this
      if (vm.loading) return
      vm.loading = true
      betslipApi(authApiService.logout).deleteBetFromBetslip(this.BETSLIP.id, bet.id, true)
        .then(data=>vm.SET_BETSLIP(data))
        .catch(err=>console.error(err))
        .finally(()=>vm.loading = false)
    }
  }
}
</script>

<style scoped>
.odd{
  height: 2.625rem;
  width: 3.75rem;
  background-color: #23232F;
  color: #FFFFFF;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.odd:active{
  background-color: #414152;
  transition: .1s;
}
.remove-bet{
   height: 2.625rem;
  width: 3.75rem;
  background-color: #FD7760;
  color: #FFFFFF;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.remove-bet:active{
  background-color: #ff9381;
  transition: 0.1s;
}
.border-bottom {
    border-bottom: 1px solid #cccccc !important;
}
</style>