<template>
<div class="mb-2">
  <label v-if="label && type!=='file'" class="my-2 fs-14" :for="name">{{ label }}</label>
  <div class="position-relative mb-2">
    <input
      :id="name"
      :name="name"
      :type="type"
      :required="required"
      :placeholder="placeholder"
      :class="{'d-none':type==='file', 'error': error}"
      :value="modelValue"
      :autocomplete="autocomplete"
      :step="step"
      :pattern="pattern"
      @change="$emit('update:modelValue', $event.target.value)"
      ref="input"
    >
    <div v-if="type==='file'" class="position-relative">
      <div v-if="label" class="my-2">{{label}}</div>
      <div class="position-relative">
        <label class="file-label cursor-pointer text-secondary d-flex align-items-center ps-4" :for="name">{{ modelValue? modelValue.split('\\').slice(modelValue.split('\\').length-1)[0]: 'No file choosen'}}</label>
        <div class="file-input-icon-wrap cursor-pointer" @click="$refs.input.click()">
          <div class="file-input-icon" :style="{backgroundImage: 'url('+require('../../assets/input-edit.svg')+')'}"></div>
        </div>
      </div>
    </div>

  </div>
  <div v-if="description || error" class="text-secondary fs-14 text-end" :class="{'text-danger': error}">{{error?error:description}}</div>
</div>
</template>

<script>
export default {
  name: "VueInput",
  props:{
    modelValue: {
      required: true
    },
    label: {
        type:String,
        default: ''
      },
    required: {
        type:Boolean,
        default: undefined
      },
    name: {
        type:String,
        default: 'input'
      },
    placeholder: {
        type:String,
        default: undefined
      },
    type: {
        type:String,
        default: 'text'
      },
    description: {
        type:String,
        default: undefined
      },
    error: {
        type: String,
        default: undefined
    },
    autocomplete: {
      default: undefined
    },
    step: {
      type: String,
      default: undefined
    },
    pattern: {
      type: String,
      default: undefined
    }
  }


}
</script>

<style scoped>
input{
  width: 100%;
  height: 3.125rem;
  background: #F0F1F5;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  border: none;
  overflow: hidden;
}
.error{
  border: 2px solid rgba(var(--bs-danger-rgb),1);
}
.file-label{
  width: 100%;
  height: 3.125rem;
  background: #F0F1F5;
  border-radius: 4px;
  padding: 0.5rem;
  border: none;
}
input:active{
  border: none;
}
input:focus{
  border: none;
}
.file-input-icon-wrap{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 0 4px 4px 0;
  background-color: #FFD60A;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-input-icon{
  height: 2rem;
  width: 2rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>