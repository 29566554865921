export const localStorageService = (function() {
    function LocalStorageService() {
        const getItem = (key) => {
            const value = localStorage.getItem(key);
            if (!value || !value.trim() || value === 'undefined') {
                return null;
            }
            return value;
        };
    
        const setItem = (key, value) => {
            localStorage.setItem(key, value);
        }
    
        const removeItem = (key) => {
            const value = localStorage.getItem(key);
            if (!value || !value.trim() || value === 'undefined') {
                return;
            }
            localStorage.removeItem(key);
        };

        return {
            getItem,
            setItem,
            removeItem,
        };
    }

    let instance = null;

    if (instance == null) {
        instance = new LocalStorageService();

        // Hide the constructor so the returned object can't be new'd...
        instance.constructor = null;
    }
    return instance;
})();