<template>
<div v-if="errors" class="p-3">
  <div class="my-3 fs-18 fw-600 text-center">Bet Slip is not submitted!</div>
  <ul v-if="errorMessages.length" class="my-3 fs-14 text-center mb-5">
    <li v-for="(error, index) in errorMessages" :key="index" class="mb-2">{{error}}</li>
  </ul>
  <vue-button @click="closeSuccessResponse" class="fw-600" cls="main">OK</vue-button>
</div>
<div v-else class="p-3">
    <p class="fs-18 fw-600 my-4">You have successfully placed {{ betslipResponse.bets.length }} bet(s):</p>
    <ol v-if="betslipResponse.bets.length" class="fs-14">
      <li v-for="(bet, index) in betslipResponse.bets" :key="index" class="d-flex justify-content-between mb-2">
        <div><span class="me-2">{{index+1}}</span>{{bet.detail +' @ ' + bet.name}}</div>
        <div>{{ glb.formatPrice(bet.price) +' '+ bet.newPC }}</div>
      </li>
    </ol>
    <div v-if="betslipResponse.wagersSubmitResult.length" class="border-top border-bottom py-3 fs-14">
      <ul class="placed-receipt-list">
        <li v-for="(wager, index) in betslipResponse.wagersSubmitResult" :key="index" class="">
          <div class="d-flex mb-2 justify-content-between">
            <div class="d-flex"><span class="me-2">{{wager.name}}</span> <info-icon>{{ wager.isEachWay ? 'Each Way' : 'Win only' }}</info-icon></div>
            <div>£{{ wager.stakePlaced  }}</div>
          </div>
          <div class="fs-14 mb-2 d-flex justify-content-between">
            <div>Ticket Number</div>
            <div> {{ wager.ticketNumber }}</div>
          </div>
          <div class="fs-14 mb-2 d-flex justify-content-between">
            <div>Potential Return</div>
            <div> £{{ wager.potentialReturn }}</div>
          </div>
        </li>
      </ul>
    </div>
  <div class="fs-14 border-top py-3 mb-4">
    <div class="d-flex mb-2 justify-content-between fw-600">
        <div>Total Payment</div>
        <div class="ms-2">£{{ successTotalPayment }}</div>
    </div>
    <div class="d-flex mb-2 justify-content-between fw-600">
        <div>Current Balance</div>
        <div class="ms-2">£{{ betslipResponse.customerBalance || 0 }}</div>
    </div>
  </div>
    <vue-button @click="closeSuccessResponse" class="fw-600" cls="main">OK</vue-button>
  </div>
</template>

<script>
import VueButton from "@/components/UI/VueButton";
import {detectBetslipErrors} from "@/services/betslip/_helpers";
import InfoIcon from "@/components/UI/InfoIcon";

export default {
  name: "BetSlipSubmitResponse",
  emits: ['updatestatus'],
  components: {InfoIcon, VueButton},
  props: ['betslipResponse'],
  data:()=>{
    return{
      errors: null,
    }
  },
  mounted() {
    let errors = detectBetslipErrors(this.betslipResponse, true)
    console.log(errors)
    if (errors.isAnyError) {
      this.errors = errors
      this.$emit('updatestatus', 'failure')
    } else {
      this.$emit('updatestatus', 'success')
    }
  },
  computed:{
    successTotalPayment(){
      return (this.betslipResponse.wagersSubmitResult || [])
        .reduce((sum, next) => (sum + next.stakePlaced), 0)
    },
    errorMessages() {
      if (!this.errors) return []
      let messages = []
      if (this.errors.isEventEnded)                 messages.push('Event has ended. The selection is no longer available.')
      if (this.errors.isPriceChanged)               messages.push(`Price has changed! Old price ${this.glb.formatPrice(this.betslipResponse.oldPrice)}. New price${this.glb.formatPrice(this.betslipResponse.price)}`)
      if (this.errors.isLiabilityExceeded)          messages.push('Sorry, the maximum online liability has been exceeded. Should you wish to place more than the permitted stake offered. Please call the office on 01344 873334. Thank you.')
      if (this.errors.isInsufficientFunds)          messages.push('You have insufficient Funds! Top up the your balance!')
      if (this.errors.isSelectionNoLongerAvailable) messages.push('Selection is no longer available.')
      // if (this.errors.isBetslipFailedInGeneral)     messages.push('')
      // if (this.errors.isNothingToSubmit)            messages.push('')
      if (!messages.length)                         messages.push(this.betslipResponse.failureReason || this.betslipResponse.message || '-')
      return messages
    }
  },
  methods:{
    closeSuccessResponse(){
      this.$store.dispatch('CLEAR_BETSLIP')
      this.$emit('close')
    },
    logout(){
      this.$store.dispatch('LOGOUT')
    },
  }
}
</script>

<style scoped>

</style>