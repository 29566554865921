<template>
<sport-header :title="data?data.title:''"/>
<div
  :class="$store.state.isMobile?'content bg-light':'content-desktop bg-white rounded-3'"
>
  <pre-loader v-if="loading"/>
  <div v-else-if="data">
    <img v-if="data.image" :src="data.image.data.full_url" class="w-100">
    <div class="p-3" v-html="data.content"></div>
  </div>
</div>
</template>

<script>
import SportHeader from "@/components/Sport/SportHeader";
import PreLoader from "@/components/UI/PreLoader";
export default {
  name: "PromoDetail",
  components: {PreLoader, SportHeader},
  data:()=>{
    return{
      loading: true,
      data: null
    }
  },
  mounted(){
    this.load()
  },
  methods:{
    load(){
      const vm = this
      vm.loading = true
      vm.API.getPromotionPage(vm.$route.params.page)
      .then(data=>{
        vm.data = data.data[0]
      })
      .finally(()=>vm.loading=false)
    }
  }
}
</script>

<style scoped>
.content{
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.content-desktop{
  width: 100%;
  overflow: hidden;
}
</style>
<style>
li{
 margin-bottom: 1rem;
}
</style>