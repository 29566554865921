<template>
  <div class="bg-white">
    <pre-loader v-if="loading"></pre-loader>
    <iframe
      v-show="!loading"
      ref="wufoo"
      allowtransparency="true"
      frameBorder="0"
      scrolling="no"
      style="min-height: 1700px"
      class="wufoo-form-container"
      src="https://geoffbanks.wufoo.com/forms/?formname=wya0sk71htewmh&embed=1&embedKey=wya0sk71htewmh403530&entsource=&referrer=http:wuslashwuslashv0.geoff-banks.comwuslashdepositlimit.asp#public" >
      <a href="https://geoffbanks.wufoo.com/forms/?formname=wya0sk71htewmh&embed=1&embedKey=wya0sk71htewmh403530&entsource=&referrer=http:wuslashwuslashv0.geoff-banks.comwuslashdepositlimit.asp#public">
      </a>
    </iframe >
  </div>
</template>

<script>
import PreLoader from "@/components/UI/PreLoader";
export default {
  name: "ResposibleGamblingView",
  components: {PreLoader},
  data:()=>{
    return{
      loading:true
    }
  },
  mounted() {
    this.$refs.wufoo.onload = ()=>{
      this.loading=false
    }
  }
}
</script>

<style scoped>
.content{
  position: fixed;
  top: 3.5rem;
  bottom: 3.5rem;
  left: 0;
  right: 0;
  overflow-y: scroll;
}
</style>