<template>
<div class="meeting">
  <div class="border-bottom">
    <div class="d-flex w-100 justify-content-between align-items-center p-2">
      <div class="fs-13 fw-600">{{meeting.MeetinName}}</div>
      <div class="fs-11 fw-500">{{meeting.Events[0].Date.split(' ')[0]}}</div>
    </div>
  </div>
  <div class="container-fluid pb-2">
    <div class="row row-cols-4">
      <div
          class="col fw-500 text-center p-2 cursor-pointer"
          v-for="event in meeting.Events"
          :key="event.EventIdx"
          @click="$router.push({name: 'raceEvent', params:{sportName: sport.title ,ef: event.EventFile, eg: event.EventGroup}, query:{ev:event.EventIdx, sportCode:sportCode}})"
      >
        <img :src="event.Status === 'Result'? require('../../../assets/pr.svg'): require('../../../assets/p.svg')">
        <span class="ms-2">{{event.Time}}</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
name: "SportRacesEventMeeting",
props: ['meeting', 'sport', 'sportCode']
}
</script>

<style scoped>
.meeting {
  margin-right: 0.5rem;
  width: 100%;
    /*min-width: 20rem;*/
    /*height: 12rem;*/
  background-color: #FFFFFF;
  border-radius: 0.25rem;
}
</style>