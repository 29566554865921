<template>
<no-events v-if="!event.Meetings" :title="sport.title" />
<div v-else class="p-2 bg-light" :class="$store.state.isMobile?'':'px-0'">
  <div class="px-2">{{event.EventName}}</div>
  <sport-races-event-meeting
      v-for="(meeting, index) in event.Meetings"
      :key="index"
      :meeting="meeting"
      :sport="sport"
      :sport-code="sportCode"
      class="my-2"
  />
</div>
</template>

<script>
import SportRacesEventMeeting from "@/components/Sport/Races/RacesEventMeeting";
import NoEvents from "@/components/Sport/NoEvents";
export default {
name: "SportRacesEvent",
  components: {NoEvents, SportRacesEventMeeting},
  props: ['event', 'sport', 'sportCode']
}
</script>

<style scoped>
</style>