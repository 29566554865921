<template>
<div>
<sports-bar/>
<div class="container-fluid desktop-container">
  <div class="row bg-light py-3">
    <div class="col-2 position-relative">
      <side-sport-bar :sport="sport"/>
    </div>
    <div class="col-7 px-0">
      <div class="router-container">
        <router-view/>
      </div>
    </div>
    <div class="col-3 position-relative">
      <div class="betslip-container">
        <div class="rounded-3 px-2 pb-2">
          <bet-slip-main/>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import BetSlipMain from "@/components/BetSlip/BetSlipMain";
import SportsBar from "@/components/SportsBar";
import SideSportBar from "@/components/SideSportBar";
export default {
  name: "BaseSportView",
  components: {SideSportBar, SportsBar, BetSlipMain},
  data:()=>{
    return{
      sport: null
    }
  },
  watch:{
    '$route.params.sportName'(){
      this.initSport()
    }
  },
  beforeMount() {
    this.initSport()
  },
  methods:{
    initSport(){
      if(this.$route.params.sportName){
        this.sport = this.API.SPORTS.filter(sport=>sport.title.toLowerCase() === this.$route.params.sportName.toLowerCase())[0]
      }
    }
  }
}
</script>

<style scoped>
.desktop-container{
  margin-top: 8.5rem;
  background-color: #FFFFFF;
}
.router-container{
  position: relative;
  width: 100%;
  min-height: 80vh;
}
.betslip-container{
  right: 0;
  position: fixed;
  width: inherit;
  z-index: 100;
}
</style>