<template>
<pre-loader v-if="loading"/>
<div v-show="!loading" class="p-3">
  <form @submit.prevent="submit">
  <div v-if="$store.state.isMobile">

  <div class="fw-600 my-4 fs-18 text-center">My profile</div>
    <div class="d-flex justify-content-center mb-3">
      <label class="avatar position-relative cursor-pointer" :style="{backgroundImage: 'url('+avatarUrl+')'}" for="avatar">
        <img alt="avatar" class="position-absolute bottom-0 end-0" :src="require('@/assets/input-edit.svg')"/>
      </label>
      <input type="file" id="avatar" class="d-none" @change="loadAvatar">
    </div>
  <vue-input
    label="First Name"
    name="first-name"
    type="text"
    v-model="firstName"
  />
  <vue-input
    label="Last Name"
    name="last-name"
    type="text"
    v-model="lastName"
  />
  <vue-input
    label="Email"
    name="email"
    type="email"
    v-model="email"
  />
  <vue-input
    label="Deposit Limit"
    name="limit"
    type="text"
    v-model="limit"
  />
  <vue-input
    label="Work Phone"
    name="workPhone"
    type="phone"
    v-model="workPhone"
  />
  <vue-input
    label="Mobile Phone"
    name="phone"
    type="phone"
    v-model="phone"
  />
  <div class="mb-2">
    <label class="fs-14 my-2">Date of Birthday</label>
    <Datepicker
       auto-position
       auto-apply
       :enable-time-picker="false"
       v-model="birthDateRow"
       class="mb-2"
    />
  </div>
  <div class="mb-2">
    <label class="my-2 fs-14" for="zip">Postal Code</label>
    <div  class="position-relative my-2">
      <input
          id="zip"
          class="zip-input"
          type="text"
          v-model="zip"
      />
      <div class="zip-input-icon-wrap cursor-pointer" @click="findAddress()">
          <div v-if="!loadingAddresses" class="zip-input-icon" :style="{backgroundImage: 'url('+require('../../assets/header/search.svg')+')'}"></div>
          <span v-else class="spinner-border" role="status"></span>
      </div>
    </div>
  </div>
  <vue-select
    v-if="foundAddresses.length>0"
    label="Select Address"
    :options="foundAddresses"
    v-model="selectedAddress"
  />
  <vue-button @click="showAddressForm=!showAddressForm" type="button" class="my-4">
    <span v-if="!showAddressForm">Enter Address Manually</span>
    <span v-else>Hide Address Form</span>
  </vue-button>
  <div v-if="showAddressForm" class="">
    <vue-input
      label="Address 1"
      name="address1"
      type="text"
      v-model="address1"
    />
    <vue-input
      label="Address 2"
      name="address2"
      type="text"
      v-model="address2"
    />
    <vue-input
      label="Address 3"
      name="address3"
      type="text"
      v-model="address3"
    />
    <vue-input
      label="City/Town"
      name="city"
      type="text"
      v-model="city"
    />
    <vue-input
      label="Country"
      name="country"
      type="text"
      v-model="country"
    />
  </div>
    <VueFileInput
      class="mt-4"
      type="file"
      label="Doc ID"
      name="photoId"
      :value="docId"
      @change="loadFile($event, 'docId','docIdFile')"
      ref="photoId"
    />
    <VueFileInput
      class="mt-4"
      type="file"
      label="Utility Bill"
      name="utilityfile"
      @change="loadFile($event, 'utility', 'utilityFile')"
      :value="utility"
    />
  <vue-button cls="main" class="my-5">Submit</vue-button>
  </div>
  <div v-else class="container-fluid px-0">
    <div class="row">
      <div class="col-6">
        <div class="fw-600 my-4 fs-18 text-center">My profile</div>
        <div class="d-flex justify-content-center mb-3">
      <label class="avatar position-relative cursor-pointer" :style="{backgroundImage: 'url('+avatarUrl+')'}" for="avatar">
        <img alt="avatar" class="position-absolute bottom-0 end-0" :src="require('@/assets/input-edit.svg')"/>
      </label>
      <input type="file" id="avatar" class="d-none" @change="loadAvatar">
    </div>
        <vue-input
          label="First Name"
          name="first-name"
          type="text"
          v-model="firstName"
        />
        <vue-input
          label="Last Name"
          name="last-name"
          type="text"
          v-model="lastName"
        />
        <vue-input
          label="Email"
          name="email"
          type="email"
          v-model="email"
        />
        <vue-input
    label="Deposit Limit"
    name="limit"
    type="text"
    v-model="limit"
  />
      </div>
      <div class="col-6">
        <vue-input
    label="Work Phone"
    name="workPhone"
    type="phone"
    v-model="workPhone"
        />
        <vue-input
          label="Mobile Phone"
          name="phone"
          type="phone"
          v-model="phone"
        />
        <div class="mb-2">
    <label class="fs-14 my-2">Date of Birthday</label>
    <Datepicker
       auto-position
       auto-apply
       :enable-time-picker="false"
       v-model="birthDateRow"
       class="mb-2"
    />
  </div>
        <div class="mb-2">
          <label class="my-2 fs-14" for="zip">Postal Code</label>
          <div  class="position-relative my-2">
            <input
                id="zip"
                class="zip-input"
                type="text"
                v-model="zip"
            />
            <div class="zip-input-icon-wrap cursor-pointer" @click="findAddress()">
                <div v-if="!loadingAddresses" class="zip-input-icon" :style="{backgroundImage: 'url('+require('../../assets/header/search.svg')+')'}"></div>
                <span v-else class="spinner-border" role="status"></span>
            </div>
          </div>
        </div>
        <vue-select
          v-if="foundAddresses.length>0"
          label="Select Address"
          :options="foundAddresses"
          v-model="selectedAddress"
        />
        <vue-button @click="showAddressForm=!showAddressForm" type="button" class="my-4">
          <span v-if="!showAddressForm">Enter Address Manually</span>
          <span v-else>Hide Address Form</span>
        </vue-button>
        <div v-if="showAddressForm" class="">
          <vue-input
            label="Address 1"
            name="address1"
            type="text"
            v-model="address1"
          />
          <vue-input
            label="Address 2"
            name="address2"
            type="text"
            v-model="address2"
          />
          <vue-input
            label="Address 3"
            name="address3"
            type="text"
            v-model="address3"
          />
          <vue-input
            label="City/Town"
            name="city"
            type="text"
            v-model="city"
          />
          <vue-input
            label="Country"
            name="country"
            type="text"
            v-model="country"
          />
        </div>
          <VueFileInput
            class="mt-4"
            type="file"
            label="Doc ID"
            name="photoId"
            :value="docId"
            @change="loadFile($event, 'docId','docIdFile')"
            ref="photoId"
          />
          <VueFileInput
            class="mt-4"
            type="file"
            label="Utility Bill"
            name="utilityfile"
            @change="loadFile($event, 'utility', 'utilityFile')"
            :value="utility"
          />
        <vue-button cls="main" class="my-5">Submit</vue-button>
      </div>
    </div>
  </div>
  </form>
</div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import VueInput from "@/components/UI/VueInput";
import VueButton from "@/components/UI/VueButton";
import VueSelect from "@/components/UI/VueSelect";
import PreLoader from "@/components/UI/PreLoader";
import VueFileInput from "@/components/UI/VueFileInput";
export default {
  name: "UpdateAccountView",
  components: {VueFileInput, PreLoader, VueSelect, VueButton, VueInput, Datepicker},
  data:()=>{
    return{
      loading: false,

      emailWasChanged: false,

      avatar: null,
      avatarUrl: null,
      avatarFile: '',
      firstName: '',
      lastName: '',
      docId: null,
      docIdFile: null,
      utility: null,
      utilityFile: null,

      email: '',
      limit: '',
      workPhone: '',
      phone: '',
      birthDateRow: null,
      zip: '',

      showAddressForm: false,

      address1: '',
      address2: '',
      address3: '',
      city: '',
      country: '',

      selectedAddress: '',
      foundAddresses: [],
      loadingAddresses: false,
    }
  },
  mounted() {
    const vm = this
    vm.loading = true
    vm.avatarUrl = this.API.assets_base_url + this.$store.state.user.profile_pic
    vm.firstName = this.$store.state.user.first_name
    vm.lastName = this.$store.state.user.last_name
    vm.docId = this.$store.state.user.doc_id
    vm.utility = this.$store.state.user.doc_utility_bill
    vm.birthDateRow = new Date(this.$store.state.user.dob)
    vm.email = vm.$store.state.user.email
    vm.limit = vm.$store.state.user.limit
    vm.workPhone = vm.$store.state.user.workPhone
    vm.phone = vm.$store.state.user.phone
    vm.address1 = vm.$store.state.user.address1
    vm.address2 = vm.$store.state.user.address2
    vm.address3 = vm.$store.state.user.address3
    vm.city = vm.$store.state.user.city
    vm.country = vm.$store.state.user.country
    vm.zip = vm.$store.state.user.zip
    vm.loading = false
  },
  watch:{
    email(value, lastValue){
      if (value !== lastValue && lastValue) {
        console.log('cur - ', value, 'last - ', lastValue)
        this.emailWasChanged = true
      }
    },
    selectedAddress(){
      this.address1 = this.selectedAddress.split(',')[0]
      this.address2 = this.selectedAddress.split(',')[1]
      let restData = []
      if (this.selectedAddress.split(',')[2].length>1) restData.push(this.selectedAddress.split(',')[2])
      if (this.selectedAddress.split(',')[3].length>1) restData.push(this.selectedAddress.split(',')[3])
      if (this.selectedAddress.split(',')[4].length>1) restData.push(this.selectedAddress.split(',')[4])
      this.address3 = restData.join(', ')
      this.city = this.selectedAddress.split(',')[5]
      this.country = this.selectedAddress.split(',')[6]
    }
  },
  methods:{
    findAddress(){
      let vm = this
      if(this.zip.length>4){
        vm.loadingAddresses = true
        this.API.getAddressByZipcode(this.zip)
        .then(res=>{
          if (res.data.addresses){
            vm.foundAddresses = res.data.addresses
            vm.selectedAddress = res.data.addresses[0]
            vm.showAddressForm = true
          }
        })
        .catch(error=>{
          this.$toast.error(error.response.data.Message)
        })
        .finally(()=>vm.loadingAddresses=false)
      } else {
        vm.$toast.warning("ZIP code is too short!")
      }
    },
    submit(){
      const vm = this
      vm.loading = true
      this.API.checkDuplicateEmail(vm.email)
          .then(res=>{
            if (res && vm.emailWasChanged) return

            let p1 = vm.API.getUserDetailFromDirectus()
            .then(res=>{
              const data = {
                id: res.data[0].id,
                status: "published",
                emailaddress: vm.email,
                dob: vm.birthDateRow.toLocaleDateString().split('/').join('-')
              }
              vm.API.updateUserDetails(data)
            })


            let userCreds = JSON.parse(localStorage.getItem('userCreds'))
            let userDetails = {
              username: userCreds.username,
              EmailAddress: vm.email,
              TelephoneNumber: vm.limit,
              dob: vm.birthDateRow.toLocaleDateString().split('/').join('-'),
              work_tel: vm.workPhone,
              address1: vm.address1,
              address2: vm.address2,
              address3: vm.address3,
              address4: vm.city,
              country: vm.country,
              mobile: vm.phone,
              postCode: vm.zip,
            }
            let p2 = vm.API.updateUserDetailToNewDB(userDetails)
            .then(res=> {
              console.log('updateUserDetailToNewDB', res)
            })
            .catch(error=>vm.$toast.error(error))


            let formData = new FormData()
            formData.append("customerid", vm.$store.state.user.Cust_Ref)
            formData.append('docid', vm.docIdFile?vm.docIdFile:vm.docId)
            if (vm.avatarFile) formData.append('docutilitybill', vm.utilityFile)
            if (vm.avatarFile) formData.append('profilepic', vm.avatarFile)
            formData.append('firstname', vm.firstName)
            formData.append('lastname', vm.lastName)
            formData.append('dob', `${vm.birthDateRow.getFullYear()}-${vm.birthDateRow.getMonth()}-${vm.birthDateRow.getDate()}`)
            formData.append('userid', vm.$store.state.user.id)
            let p3 = vm.API.updateUserProfile(formData)
            .then(res=>console.log(res))
            .catch(error=>vm.$toast.error(error))

            Promise.all([p1, p2, p3]).then(()=>{
              vm.$store.dispatch('LOGIN', JSON.parse(localStorage.getItem('userCreds')))
              .then(()=>vm.loading = false)
            })
          })
      .finally(()=>vm.loading=false)
    },
    loadAvatar(e){
      const vm = this
      let files = e.target.files
      if (!files.length) return
      vm.avatar = files[0].name
      vm.avatarFile = files[0]
      vm.glb.convertInputFileToBase64(files[0])
      .then(res=>vm.avatarUrl=res)
    },
    loadFile(e, fileName, file){
      const vm = this
      let files = e.target.files
      if (!files.length) return
      vm[fileName] = files[0].name
      vm[file] = files[0]
    },
  }
}
</script>

<style scoped>
.zip-input{
  width: 100%;
  height: 3.125rem;
  background: #F0F1F5;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  border: none;
  overflow: hidden;
}
.zip-input-icon-wrap{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 0 4px 4px 0;
  background-color: #FFD60A;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zip-input-icon{
  height: 1.5rem;
  width: 1.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  filter: brightness(0) drop-shadow(0px 0px 0px black);
}
.avatar{
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>