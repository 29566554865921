<template>
<div class="h-100 py-1">
  <div class="text-center m-2 fs-14 fst-italic">{{race.PlText}}</div>
  <race-item
    v-for="rc in race.Races"
    :key="rc.Idx"
    :sport="sport"
    :race="rc"
  />
</div>

</template>

<script>
import RaceItem from "@/components/Event/RaceItem";
export default {
name: "RaceEvent",
  components: {RaceItem},
  props:['race', 'sport'],
  methods:{
    getSelName(selName){
      if (selName.includes('.')){
        return selName.split('.')[1].trim()
      } else {
        return selName
      }
    }
  }
}
</script>

<style scoped>

</style>