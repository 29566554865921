<template>
  <div v-if="!$store.state.authToken" class="bg-white p-3 h-full-view">
    <div class="mb-1 fw-600 fs-18" :class="$store.state.isMobile?'mt-4':''">My Account</div>
      <div @click="$router.push({name: 'accountVerification'})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/security-user.svg')+')'}"></div>
          <div class="fs-14"> Verify Account </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div @click="$router.push({name: 'reactivateProfile'})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/arrow-counterclockwise.svg')+')'}"></div>
          <div class="fs-14"> Reactivate Account </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div @click="$router.push({name: 'responsibleGambling'})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/dice-5.svg')+')'}"></div>
          <div class="fs-14"> Responsible Gambling </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
</div>
<div v-else :class="isMobile?'bg-white p-3':''">
  <div :class="isMobile?'':'account-menu'">
    <div class="mb-1 fw-600 fs-18" :class="$store.state.isMobile?'mt-4':''">My Account</div>
      <div
        @click="$router.push({name: 'transactions'})"
        class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
        :class="{'active': $route.name === 'transactions'}"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/document-text.svg')+')'}"></div>
          <div class="fs-14"> View Statements </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div
        @click="$router.push({name: 'changePassword'})"
        class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
        :class="{'active': $route.name === 'changePassword'}"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/password-check.svg')+')'}"></div>
          <div class="fs-14"> Change Password </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div
        @click="$router.push({name: 'updateProfile'})"
        class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
        :class="{'active': $route.name === 'updateProfile'}"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/user-edit.svg')+')'}"></div>
          <div class="fs-14"> Update My Details </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div
        @click="$router.push({name: 'accountVerification'})"
        class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
        :class="{'active': $route.name === 'accountVerification'}"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/security-user.svg')+')'}"></div>
          <div class="fs-14"> Verify Account </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
<!--      <div @click="$router.push({name: 'reactivateProfile'})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">-->
<!--        <div class="d-flex align-items-center justify-content-center">-->
<!--          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/arrow-counterclockwise.svg')+')'}"></div>-->
<!--          <div class="fs-14"> Reactivate Account </div>-->
<!--        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">-->
<!--      </div>-->
<!--      <div @click="$router.push({name: 'deactivateProfile'})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">-->
<!--        <div class="d-flex align-items-center justify-content-center">-->
<!--          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/clipboard-close.svg')+')'}"></div>-->
<!--          <div class="fs-14"> Deactivate Account </div>-->
<!--        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">-->
<!--      </div>-->
      <div
        @click="$router.push({name: 'responsibleGambling'})"
        class="border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
        :class="{'active': $route.name === 'responsibleGambling', 'border-bottom': isMobile}"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/dice-5.svg')+')'}"></div>
          <div class="fs-14"> Responsible Gambling </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>

  </div>
  <div :class="isMobile?'':'account-menu'">
    <div class="mb-1 fw-600 fs-18" :class="isMobile?'mt-4':''">Payments</div>

      <div
        @click="$router.push({name: 'deposit'})"
        class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
        :class="{'active': $route.name === 'deposit'}"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/card-tick.svg')+')'}"></div>
          <div class="fs-14"> Deposit </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
<!--      <div @click="$router.push({name: 'account'})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">-->
<!--        <div class="d-flex align-items-center justify-content-center">-->
<!--          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/wallet-3.svg')+')'}"></div>-->
<!--          <div class="fs-14"> Online Deposit </div>-->
<!--        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">-->
<!--      </div>-->
      <div
        @click="$router.push({name: 'withdrawal'})"
        class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
        :class="{'active': $route.name === 'withdrawal'}"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/convert-card.svg')+')'}"></div>
          <div class="fs-14"> Request Withdrawal </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div
        @click="$router.push({name: 'updateDepositLimit'})"
        class="border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
        :class="{'active': $route.name === 'updateDepositLimit', isMobile: 'border-bottom'}"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/trend-up.svg')+')'}"></div>
          <div class="fs-14"> Update Deposit Limits </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>

    </div>
  <div :class="isMobile?'':'account-menu'">

    <div class="mb-1 fw-600 fs-18" :class="isMobile?'mt-4':''">Options</div>
      <div v-if="['ios', 'android'].includes($store.state.platform)" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="fs-14"> Use Biometric Authentication </div>
        </div>
        <vue-switch v-model="useBiometricAuth" />
      </div>
      <div class="border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer" :class="isMobile?'border-bottom':''">
        <div class="d-flex align-items-center justify-content-center">
          <div class="fs-14"> Decimal Odds Format </div>
        </div>
        <vue-switch @click="updateOddsFormat" v-model="decimalFormat" />
      </div>
  </div>
  <div :class="isMobile?'':'account-menu'">
    <div class="mt-4 mb-1 fw-600 fs-18">Other Information</div>

      <div
        @click="$router.push({name: 'info', params: {infoType: 'Terms And Conditions'}})"
        class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div class="fs-14"> Terms And Conditions </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div
        @click="$router.push({name: 'info', params: {infoType: 'Privacy Policy'}})"
        class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"

      >
        <div class="d-flex align-items-center justify-content-center">
          <div class="fs-14"> Privacy Policy </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div @click="$router.push({name: 'info', params: {infoType: 'Cookie Policy'}})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="fs-14"> Cookie Policy </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div @click="$router.push({name: 'info', params: {infoType: 'Affiliates'}})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="fs-14"> Affiliates </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div @click="$router.push({name: 'info', params: {infoType: 'About Us'}})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="fs-14"> About Us </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div @click="$router.push({name: 'info', params: {infoType: 'Contact Details'}})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="fs-14"> Contact Details </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div @click="$router.push({name: 'info', params: {infoType: 'Help'}})" class="border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer" :class="isMobile?'border-bottom':''">
        <div class="d-flex align-items-center justify-content-center">
          <div class="fs-14"> Help </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
    </div>


  <VueButton  class="my-4 mt-5" cls="main" @click="logout">Log Out</VueButton>
</div>
</template>

<script>
import VueButton from "@/components/UI/VueButton";
import {mapActions, mapGetters} from "vuex";
import VueSwitch from "@/components/UI/VueSwitch";
import {NativeBiometric} from "capacitor-native-biometric";
export default {
name: "AccountView",
  components: {VueSwitch, VueButton},
  data: ()=>{
    return{
      decimalFormat: false,
      useBiometricAuth: false,
    }
  },
  computed:{
    ...mapGetters(['isMobile'])
  },
  beforeMount(){
    this.useBiometricAuth = JSON.parse(localStorage.getItem('useBiometricLogin'))
    let timeout = 0
    if (this.$store.state.user.oddsFormat === undefined) timeout = 1500
    setTimeout(()=>{
      if (this.$store.state.user.oddsFormat==='decimal') this.decimalFormat = true
    }, timeout)
  },
  watch:{
    useBiometricAuth(value){
      if (value) {
        localStorage.setItem('useBiometricLogin', 'true')
      } else {
        localStorage.setItem('useBiometricLogin', 'false')
      }
    }
  },
  methods: {
    ...mapActions(['LOGOUT']),
    logout(){
      this.LOGOUT().then(()=>{
        if(this.$store.state.platform !== "web") NativeBiometric.deleteCredentials('m1.geoffbanks.bet')
      })
      this.$router.push({name: 'login'})
    },
    updateOddsFormat(){
      const vm = this
      const oddsFormat = this.decimalFormat?'decimal':'fractional'
      this.API.updateOddsFormatInPreferences(oddsFormat)
      .then(res=>{
        console.log('ODDS FORMAT UPDATED', res)
        vm.$store.dispatch('SET_USER_DATA', {oddsFormat: oddsFormat})
      })
      .catch(error=>{
        vm.$toast.error(error)
        vm.decimalFormat = !vm.decimalFormat
      })
    }
  }

}
</script>

<style scoped>
.account-menu{
  background: #FFFFFF;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.3rem;
}
.account-menu > div {
  margin: 0 -1rem;
  padding: 0 1rem;
}
.account-menu > .active {
  background: #F8F8FA;
}
.menu-item-icon{
  height: 1.75rem;
  width: 1.75rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(0) drop-shadow(0px 0px 0px black);
}
</style>