// import {useToast} from "vue-toastification"
// import router from '../../router'

export default function OneSignalInit() {
    // Uncomment to set OneSignal device logging to VERBOSE
    // window.plugins.OneSignal.setLogLevel(6, 0);
    // const toast = useToast()
    // toast('ONESIGNAL INIT')
    // production AppId
    window["plugins"].OneSignal.setAppId("52a42fc7-bc92-4848-9b1f-b458076b6938");
    // window["plugins"].OneSignal.setAppId("ebf77158-db8c-439d-baa2-c6f31ff2583e");
    window["plugins"].OneSignal.setNotificationOpenedHandler(function(jsonData){
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData))
        let goTo = jsonData.notification.launchURL.split('#').pop()
        // router.push(goTo)
        console.log('GO TO', goTo)
    });

    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        console.log("User accepted notifications: " + accepted);
    });
}