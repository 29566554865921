<template>
  <button
      @mouseenter="btnHover=true"
      @mouseleave="btnHover=false"
      @click="isActive=!isActive"
      :class="['button', btnClass]"
  >
    <span v-if="toBack" class="arrowDiv" :class="['arrowDiv'+cls]"></span>
    <slot></slot>
    <span v-if="toNext" class="arrowDiv arrowNext" :class="['arrowDiv'+cls]"></span>
  </button>
</template>

<script>

export default {
  name: "VueButton",
  props: {
    cls: {
      type: String,
      default: 'base',
      validator: value => {
        return [
          'base',
          'main',
          'secondary',
          'success',
          'disable',
          'dark',
        ].includes(value)
      }
    },
    toNext: {
      type: Boolean,
      default: false
    },
    toBack: {
      type: Boolean,
      default: false
    },
    sm: {
      type: Boolean,
      default: false
    }
  },
  data: function (){
    return {
      btnHover: false,
    }
  },
  computed: {
    btnClass(){
      let classes = this.cls
      if (this.sm){
        classes += ' sm'
      }
      return classes
    },
  }
}
</script>

<style scoped>
.button {
  line-height: 1.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  width: 100%;
  white-space: nowrap;
}
.main {
  background: #FFD60A;
  border: none;
  color: black;
}
.base {
  padding: 0.625rem 1.375rem;
  background: none;
  border: 0.125rem solid #FFD60A;
}
.secondary {
  background: #23232FCC;
  border: none;
  color: #FFFFFF;
}
.dark {
  background: #23232F;
  border: none;
  color: #FFFFFF;
}
.success {
  background: #00984A;
  border: none;
  color: #FFFFFF;
}
.btnDisable {
  background: #F0F3F8;
  border: none;
  color: #A1A2A2;
}
.main:hover{
  background: #debd16;
  color: #1E2022;
  transition: 0.3s;
}
.baseSm:hover, .base:hover, .dark:hover {
  background: #debd16;
  color: #1E2022;
}
.main:active{
  background: rgba(247, 249, 252, 0.38);
  /*background: #debd16;*/
  color: #1E2022;
  transition: 0.1s;
  border: none;
}
.base:active {
  background: rgba(247, 249, 252, 0.38);
  /*color: rgba(9, 8, 12, 0.5) !important;*/
  /*padding: 0.75rem 1.5rem;*/
  /*border: none;*/
  transition: 0.1s;
}
.secondary:active {
  background: #328FE4;
  color: #FFFFFF;
  transition: 0.3s;
}
.btnDisable:hover {
  cursor: default;
}
.arrowDiv{
  margin-right: 0.625rem;
  height: 1rem;
  width: 1rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.arrowNext{
  transform: rotate(180deg);
  margin-left: 0.625rem;
  margin-right: 0;
}
.sm {
  padding: 0.35rem 1.15rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
}
.baseSm {
  padding: 0.375rem 1.225rem;
  background: transparent;
  border: 0.125rem solid #FFC549;
}
.baseSm:active .arrow, .main:active .arrow, .base:active .arrow{
  color: #6D6D6D;
}

.baseSm:active{
  /*background: #F7F9FC;*/
  color: #6D6D6D;
  padding: 0.5rem 1.35rem;
  border: none;
  /*transition: 0.1s;*/
}
</style>
