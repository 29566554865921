<template>
<div>
<sport-header v-if="$store.state.isMobile" to-home="true"/>
<pre-loader v-if="loading"></pre-loader>
<div ref="signup" :class="$store.state.isMobile?'signup-container':'signup-container-desktop'"></div>
</div>
</template>

<script>
import PreLoader from "@/components/UI/PreLoader";
import postscribe from 'postscribe';
import SportHeader from "@/components/Sport/SportHeader";
export default {
  name: "SignUpFormStack",
  components: {SportHeader, PreLoader},
  data:()=>{
    return{
      loading:false
    }
  },
  mounted() {
    postscribe(this.$refs.signup,
    '<'+'script type="text/javascript"src="https://geoffbanks.formstack.com/forms/js.php/gbreg"></'+'script><noscript><a href="https://geoffbanks.formstack.com/forms/gbreg" title="Online Form">Online Form - GBReg</a></noscript>'
    )
  },
}
</script>

<style scoped>
.signup-container{
  position: fixed;
  top: 3.5rem;
  bottom: 3.5rem;
  left: 0;
  right: 0;
  overflow-y: scroll;
  background-color: rgba(30,30,30, 1);
}
.signup-container-desktop{
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  overflow-y: scroll;
  background-color: rgba(30,30,30, 1);
}
</style>