<template>
  <div class="d-flex justify-content-between align-items-end p-3">
    <div class="fw-600 fs-18 text-center mt-3">{{ $route.query.antepostTitle }}</div>
  </div>
  <pre-loader v-if="loading"/>
  <div v-else class="py-2" v-for="(event, index) in events" :key="index">
    <div
      class="d-flex justify-content-between align-items-center p-2 my-2 "
      :class="$store.state.isMobile?'':'bg-white rounded-3'"
    >
      <div class="fw-600">{{ event.ElRespose }}</div>
      <div class="fs-14">{{ event.Pl }}</div>
      <div>
        <div v-if="!event.TimeText.includes(':')" class="time-text fs-12"> {{event.TimeText}}</div>
        <race-countdown active="true" v-else :time="event.TimeText"/>
      </div>
    </div>
    <div class="container-fluid px-0">
      <div class="row">
        <div
          v-for="ev in event.Events.filter(e=>e.SelName && e.price)"
          :key="ev.EventId"
          :class="$store.state.isMobile?'col-12':'col-4'"
        >
          <div class="card-box my-1 p-1 d-flex align-items-center justify-content-between">
            <div class="ms-1">{{ev.SelName}}</div>
            <odd-btn :race="ev" :selection="parseInt(ev.selection)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OddBtn from "@/components/UI/OddBtn";
import PreLoader from "@/components/UI/PreLoader";
import RaceCountdown from "@/components/UI/RaceCountdown";
export default {
name: "AntepostEvent",
  components: {RaceCountdown, PreLoader, OddBtn},
  data: ()=>{
  return{
    events: [],
    sport: {},
    title: '',
    loading: false
  }
},
beforeMount() {
  this.sport = this.API.SPORTS.filter(sport=>sport.title.toLowerCase() === this.$route.params.sportName.toLowerCase())[0]
  this.title = this.$route.query.antepostTitle
},
mounted() {
  this.loadData()
},
watch:{
  '$route.params'(){
    this.loadData()
  }
},
methods:{
  loadData(){
    const vm = this
    vm.loading = true
    vm.API.getSportEvents(vm.$route.params.ef, vm.$route.params.eg)
    .then((data)=>{
      console.log(data)
      vm.events = data
    })
    .finally(()=>vm.loading = false)
  }
}
}
</script>

<style scoped>
.card-box{
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 0.25rem;
}
.time-text{
  padding: 0.25rem 0.5rem;
  color: #FFFFFF;
  background-color: #FD7760;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  text-align: center;
}
</style>