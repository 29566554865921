<template>
<no-events v-if="!events.length" :title="sport.title"/>
<div v-else class="p-2">

  <div v-for="(event, index) in events" :key="index" class="">

    <div class="fw-500 text-center p-2"> {{event.EventName}}</div>
  <div class="content-box my-1">
    <div
        v-for="(meeting, index) in event.Meetings"
        :key="index"
        class="px-2 cursor-pointer"
        @click="$router.push({name: 'antepostEvent', params:{sportName:sport.title, ef: meeting.EventCode?meeting.EventCode:meeting.EventFile, eg: meeting.EventGroup}, query:{antepostTitle:meeting.EventName?meeting.EventName:meeting.MeetinName}})"
    >
      <div class="d-flex justify-content-between align-items-center py-2"
           :class="{'border-bottom': index<event.Meetings.length-1}"
      >
        <div>{{meeting.EventName?meeting.EventName:meeting.MeetinName}}</div>
        <div><img class="opacity-50" src="../../assets/to-right-arrow.svg"></div>
      </div>
    </div>
  </div>
</div>

</div>
</template>

<script>
import NoEvents from "@/components/Sport/NoEvents";
export default {
name: "AntepostEventsList",
  components: { NoEvents},
  props:['events', 'sport', 'sportCode']
}
</script>

<style scoped>
.content-box{
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 0.25rem;
}
</style>