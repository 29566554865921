<template>
<div class="position-absolute top-50 start-50 translate-middle text-center">
  <div v-if="title" class="text-capitalize fw-500 mb-4">{{title}}</div>
  <div class="d-flex justify-content-center">
    <div class="no-events-picture" :style="{backgroundImage: 'url('+require('../../assets/no-events.svg')+')'}"></div>
  </div>
  <div class="text-secondary fs-14 mt-4" style="width: 200px">There are Currently no active events. Please check back later.</div>
</div>
</template>

<script>
export default {
name: "NoEvents",
props: ['title']
}
</script>

<style scoped>
.no-events-picture{
  height: 6rem;
  width: 6rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1001;
}
</style>