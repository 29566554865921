<template>
<div :class="{'d-none': noEvents && !races.length}">
<div v-if="!column" class="fw-600 fs-6 p-2 ps-3 bg-white" :class="{'rounded-3': !$store.state.isMobile}">Next {{sport.title}}</div>
<div class="next-events-wrap position-relative" :class="{'next-events-wrap-show-full': showFull, 'next-events-wrap-desktop':!$store.state.isMobile}">
  <pre-loader v-if="loading"/>
  <div
    v-else-if="races.length" 
    class="next-events-container"
    :class="{'px-2': $store.state.isMobile, 'd-flex': !column, 'next-events-container-desktop': !$store.state.isMobile}">
    <div
      v-for="(eventRace, index) in races"
      :key="index" class="my-2"
      :class="{'next-event-desktop': !$store.state.isMobile}"
    >
      <div class="next-event" :class="{'me-0': column, 'next-event-desktop': !$store.state.isMobile}">
      <div
         class="next-event__header d-flex justify-content-between align-items-center p-2 cursor-pointer"
         @click="goToFullRacePage(eventRace)"
      >
        <div class="next-event__name fs-13 fw-600">{{eventRace.Name}} {{eventRace.Time}}</div>
        <div v-if="$store.state.isMobile" class="fst-italic fs-8 mx-2 text-truncate" style="max-width: 55%">{{ eventRace.PlText }}</div>
        <RaceCountdown :time="eventRace.TimeLeft"/>
      </div>
      <div class="next-event-races" v-if="eventRace.Races" :class="{'next-event-races-show-full': showFull}">
        <div v-for="race in eventRace.Races.filter(r=>r.price!=undefined)" :key="race.Idx" class="d-flex justify-content-between align-items-center py-1">
          <div class="fs-14 ms-2 d-flex align-items-center next-race-item-title-wrap">
            <div class="d-flex align-items-center w-100">
              <race-number class="me-2" :race-number="race.Image?parseInt(race.Image.split('.')[0]):parseInt(race.selection)+1" :sport="sport"/>
              <div class="next-race-item-title text-truncate">
                <div class="text-truncate" data-bs-toggle="tooltip" data-bs-placement="top" :title="getSelName(race.SelName)">{{getSelName(race.SelName)}}</div>
                <div v-if="race.Jockey" class="text-secondary fs-11 text-start mt-1">{{race.Jockey}}</div>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <odd-btn
              class="me-1 odd-btn odd-btn-desktop"
              v-if="race.price && race.gprice && race.PriceLink1 !== 'NR'"
              :race="race"
              :selection="parseInt(race.selection)"
            />
            <odd-btn
              class="me-1 odd-btn odd-btn-desktop"
              :race="race"
              :selection="parseInt(race.selection)"
              sp
            />
          </div>
        </div>
      </div>
      <div v-if="$store.state.isMobile" @click="goToFullRacePage(eventRace)" class="next-event__footer cursor-pointer fs-14 p-2 text-secondary"><span>View Full Race</span></div>
      <div v-else class="next-event__footer next-event__footer-desktop d-flex justify-content-between px-2 py-1">
        <div class="fst-italic fs-10 me-2 text-truncate">{{ eventRace.PlText }}</div>
        <vue-button @click="goToFullRacePage(eventRace)" cls="base" class="p-1 w-auto" sm>View Full Race</vue-button>
      </div>
      </div>
    </div>
  </div>
    <no-events v-else-if="!column" :title="sport.title"/>
  </div>
</div>
</template>

<script>

import {getNextRaces} from "@/http/api";
import RaceNumber from "@/components/UI/RaceNumber";
import OddBtn from "@/components/UI/OddBtn";
import PreLoader from "@/components/UI/PreLoader";
import NoEvents from "@/components/Sport/NoEvents";
import RaceCountdown from "@/components/UI/RaceCountdown";
import VueButton from "@/components/UI/VueButton";

export default {
name: "NextEvents",
  components: {VueButton, RaceCountdown, NoEvents, PreLoader, OddBtn, RaceNumber},
  props:{
    sport: {
      type: Object,
      required: true
    },
    sportCode: {
      type: String,
    },
    column:{
      type: Boolean,
      default: false
    },
    noEvents:{
      type: Boolean,
      default: false
    }
  },
  data: ()=>{
  return {
    showFull: false,
    loading: true,
    mounted: true,
    races: [],
    intervalLoad: null,
  }
},
watch:{
  sportCode(){
    this.loadData()
  }
},
mounted() {
  const vm = this
  vm.loadData()
},
beforeUnmount(){
  this.mounted = false
  if (this.intervalLoad) clearInterval(this.intervalLoad)
},
methods:{
  loadData:function(){
    const vm = this
    let timeout = 5000
    getNextRaces(this.sportCode? this.sportCode: this.sport.code)
      .then((liveraces)=>{
        if (['g,q', '$'].includes(vm.sport.code)){
          vm.races = []
          liveraces.forEach(race=>{
            let sortedRace = {...race}
            sortedRace.Races = race.Races.sort((a,b)=>{
              if (a.number < b.number) return -1
              else return 1
            })
            vm.races.push(sortedRace)
          })
        } else {
          vm.races = liveraces
        }
        vm.loading = false
        timeout = 5000
      })
      .catch(error=> {
        console.log(error)
        timeout = 0
      })
      .finally(()=>{
        vm.$emit('dataloaded')
        if (!vm.mounted) return
        // recursion load data
        setTimeout(()=>{
          vm.loadData()
        }, timeout)
      })
  },
  getSelName(selName){
    if (selName.includes('.')){
      return selName.split('.')[1]
    } else {
      return selName
    }
  },
  goToFullRacePage(eventRace){
    if (!this.sportCode) {
      this.showFull=!this.showFull
      return
    }
    this.$router.push(
       {
          name:'raceEvent',
          params:
             {
               sportName:this.sport.title,
               ef:eventRace.Races[0].eventfile,
               eg:eventRace.Races[0].EventGroup
             },
          query:
            {
              ev:eventRace.Races[0].Events,
              sportCode: this.sportCode? this.sportCode: this.sport.code
            }
       })
  }
},
}
</script>

<style scoped>
.next-events-wrap{
  background-color: #F0F1F5;
  overflow-x: scroll;
  /*min-height: 9.625rem;*/
  transition: height 0.2s ease;
}
.next-events-wrap-desktop{
  margin-right: -0.5rem;
}
.next-events-container{
  min-width: max-content;
}
.next-events-container-desktop{
  min-width: auto;
}
.next-event{
  margin-right: 0.5rem;
  min-width: 20rem;
  /*height: 14rem;*/
  background-color: #FFFFFF;
  border-radius: 0.25rem;
  transition: height 2s ease;
  overflow: hidden;
}
.next-event-desktop{
  min-width: 33.3333%;
  height: auto;
}
.next-event__header{
  height: 2.375rem;
  border-bottom: 1px solid #F0F1F5;
}
.next-event__footer{
  border-top: 1px solid #F0F1F5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.next-event__start_at{
  color: #FFFFFF;
  background-color: #FD7760;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  text-align: center;
}
.next-event-races{
  overflow-y: scroll;
  max-height: 9.625rem;
  transition: max-height 0.2s ease;
}
.next-event-races-show-full{
  /*overflow: hidden;*/
  max-height: 100vh;
}
.odd-btn{
  width: 3.75rem;
  height: 2.7rem;
}
.next-race-item-title-wrap{
  width: calc(100% - 9rem)
}
.next-race-item-title{
  width: calc(100% - 1rem);
}
@media only screen and (max-width: 1368px){
  .odd-btn-desktop{
    width: 3rem;
  }
  .next-race-item-title-wrap{
    width: calc(100% - 7rem)
  }
}
</style>