<template>
<pre-loader v-if="loading"/>
<div v-else>
  <div
    class="match-header text-white text-center d-flex flex-column position-relative"
    :class="$store.state.isMobile?'':'rounded-3'"
    :style="{backgroundImage: 'url('+sport.bg+')'}"
  >
    <div class="start-0 end-0 top-0 my-2 fs-14 col-auto position-absolute text-center">{{title}}</div>
    <div class="d-flex justify-content-center align-items-center col p-2">
      <div class="fs-3 w-50">{{homeParticipant}}</div>
      <div class="m-2 match-date fs-12 fw-500 ">{{date}}</div>
      <div class="fs-3 w-50">{{awayParticipant}}</div>
    </div>
  </div>
</div>
<div v-if="$store.state.isMobile" class="p-2">
  <div v-for="(type, index) in match" :key="index">
    <div class="event-type ms-2 fw-600 mt-2 p-1">{{type.ElRespose}}</div>
    <div class="event-type-results p-1 m-1" v-for="(res, index) in type.Events.filter(ev=>ev.price)" :key="index">
      <div class="d-flex justify-content-between align-items-center">
        <div class="p-1">{{ res.SelName }}</div>
        <odd-btn :race="res" :selection="parseInt(res.selection)"/>
      </div>
    </div>
  </div>
</div>
<div v-else class="px-0 py-2">
  <div v-for="(type, index) in match" :key="index">
  <div class="event-type ms-2 fw-600 mt-2 p-1">{{type.ElRespose}}</div>
  <div class="container-fluid px-0">
    <div class="row">
    <div class="col-6" v-for="(res, index) in type.Events.filter(ev=>ev.price)" :key="index">
      <div class="event-type-results p-1 m-1">
        <div class="d-flex justify-content-between align-items-center">
          <div class="p-1">{{ res.SelName }}</div>
          <odd-btn :race="res" :selection="parseInt(res.selection)"/>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
</div>
</template>

<script>
import PreLoader from "@/components/UI/PreLoader";
import OddBtn from "@/components/UI/OddBtn";

export default {
name: "MatchEventView",
components: {OddBtn, PreLoader},
data:()=>{
   return{
     loading: false,
     match: {},
     sport: {},
     title: '',
     homeParticipant: '',
     awayParticipant: '',
     date: '',
     ef: '',
     eg: '',

   }
},
beforeMount() {
  this.sport = this.API.SPORTS.filter(sport=>sport.title.toLowerCase() === this.$route.params.sportName.toLowerCase())[0]
  this.ef = this.$route.params.ef
  this.eg = this.$route.params.eg
  this.homeParticipant = this.$route.query.homeParticipant
  this.awayParticipant = this.$route.query.awayParticipant
  this.title = this.$route.query.title
  this.date = this.$route.query.date.split(' ').join('\n')
},
  mounted() {
    this.loadData()
  },
  methods:{
    loadData(){
      let vm = this
      vm.loading = true
      console.log('>>>>>> SETTINGS', this.$store.state.settings)
      this.API.getSportEvents(this.ef, this.eg)
      .then((data)=>{
        vm.match = data
      })
      .finally(()=>vm.loading = false)
    }
  }

}
</script>

<style scoped>
.match-header{
  padding: 1rem;
  height: 9rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.event-type-results{
  width: auto;
  background: #FFFFFF;
  border-radius: 0.25rem;
}
.match-date{
  width: min-content;
  text-align: center;
  white-space: pre;
  text-shadow: 1px 1px 3px black;
  line-height: 1.25rem;
}
</style>