<template>
<div class="p-2" :class="$store.state.isMobile?'':'px-0'">
  <div class="res-card">
    <div class="res-card-header border-bottom p-2 fs-15 fw-600">Race Results</div>
    <div class="res-card-list px-3">
      <div
          v-for="(res, index) in race.Results"
          :key="index"
          class="d-flex justify-content-between align-items-center py-2"
          :class="{'border-bottom': index<race.Results.length-1}"
      >
        <div class="d-flex align-items-center justify-content-start">
          <div class="d-flex align-items-center justify-content-start" v-if="!['g,q', '$'].includes(sport.code)">
            <race-number color="#000000" :race-number="parseInt(res.ResultPlace[0])" :sport="sport" result/>
            <div class="ms-2">{{ res.ResultText.split('.').slice(1).join('.') }}</div>
          </div>
          <div class="d-flex align-items-center justify-content-center" v-else>
            <race-number color="#000000" :race-number="parseInt(res.ResultPlace[0])" :sport="sport" result/>
            <div class="ms-2">{{ res.ResultText.split('.').slice(1).join('.') }}</div>
            <race-number class="ms-2" :race-number="parseInt(res.ResultText.split('.')[0])" :sport="sport"/>
          </div>
        </div>
        <div class="fw-500 fs-5">{{ res.Odds }}</div>
      </div>
    </div>
  </div>
  <div class="res-card mt-2">
    <div class="res-card-header border-bottom p-2 fs-15 fw-600">Dividends</div>
    <div class="px-3">
      <div
        v-for="(res, index) in race.Results1"
        :key="index"
        class="d-flex justify-content-between align-items-center py-2"
        :class="{'border-bottom': index<race.Results1.length-1}"
      >
        <div class="">{{ res.Name }}</div>
        <div class="fw-500">{{ res.Odds }}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import RaceNumber from "@/components/UI/RaceNumber";
export default {
  name: "RaceEventResults",
  components: {RaceNumber},
  props: ['race', 'sport']
}
</script>

<style scoped>
.res-card{
  width: auto;
  background: #FFFFFF;
  border-radius: 0.25rem;
}
</style>