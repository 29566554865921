<template>
<collapse-transition>
  <div v-if="show" class="pb-3">
  <div class="container-fluid px-2">
    <div class="row">
      <div @click="$emit('enter', 1)" class="col"><div class="btn">1</div></div>
      <div @click="$emit('enter', 2)" class="col"><div class="btn">2</div></div>
      <div @click="$emit('enter', 3)" class="col"><div class="btn">3</div></div>
      <div @click="$emit('enter', 4)" class="col"><div class="btn">4</div></div>
      <div @click="$emit('enter', 5)" class="col"><div class="btn">5</div></div>
      <div @click="$emit('enter', '.')" class="col"><div class="btn">.</div></div>
      <div @click="$emit('backspace')" class="col"><div class="btn px-2"><img :src="require('@/assets/img/backspace.svg')"/></div> </div>
    </div>
    <div class="row">
      <div @click="$emit('enter', 6)" class="col"><div class="btn"> 6</div></div>
      <div @click="$emit('enter', 7)" class="col"><div class="btn"> 7</div></div>
      <div @click="$emit('enter', 8)" class="col"><div class="btn"> 8</div></div>
      <div @click="$emit('enter', 9)" class="col"><div class="btn"> 9</div></div>
      <div @click="$emit('enter', 0)" class="col"><div class="btn">0</div></div>
      <div @click="$emit('confirm')" class="col united"><div class="btn"><img :src="require('@/assets/img/confirm.svg')"/> </div></div>
    </div>
  </div>
</div>
</collapse-transition>
</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'

export default {
  name: "NumericKeyboard",
  components: {CollapseTransition},
  emits: ['enter', 'backspace', 'confirm'],
  props: ['show'],
}
</script>

<style scoped>
.col{
  min-width: 14.28%;
  max-width: 14.29%;
  padding: 0.2rem;
}
.btn{
  background-color: #F0F1F5;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.3rem;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
}
.btn:active, .btn:hover{
  background-color: #d3d3d3;
}
.united{
  min-width: 25.57%;
  max-width: 28.572%;
}
.slide-enter-from,
.slide-leave-to {
  max-height: 0;
}
.slide-enter-active,
.slide-leave-active {
  transition: height 0.2s;
}
</style>