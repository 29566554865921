<template>
<sport-header :title="$route.params.infoType"/>
<pre-loader v-if="loading" />
<div v-if="$store.state.isMobile" class="content bg-light" v-html="htmlContent"></div>
<div v-else class="content-desktop pt-4">
  <div class="fs-24 text-center fw-600">{{ $route.params.infoType}}</div>
  <div class="p-3" :class="$store.state.isMobile?'content bg-light':'content-desktop'" v-html="htmlContent">
</div>
</div>
</template>

<script>
import SportHeader from "@/components/Sport/SportHeader";
import PreLoader from "@/components/UI/PreLoader";
export default {
  name: "InfoPageView",
  components: {PreLoader, SportHeader},
  data:()=>{
    return{
      loading: false,
      htmlContent: ''
    }
  },
  watch:{
    '$route.params.infoType'(){
      if (this.$route.name === 'info'){
        this.htmlContent = ''
        this.init()
        document.getElementById('scroll-content').scrollTo(0,0)
      }
    }
  },
  mounted(){
    this.init()
  },
  methods:{
    init(){
      let pageName = ''
      switch (this.$route.params.infoType){
        case 'Terms And Conditions':
          pageName = 'termsandconditions'
          break
        case 'Privacy Policy':
          pageName = 'privacy'
          break
        case 'Cookie Policy':
          pageName = 'cookies'
          break
        case 'Affiliates':
          pageName = 'affiliates'
          break
        case 'Betting Rules':
          pageName = 'bettingrule'
          break
        case 'About Us':
          pageName = 'aboutus'
          break
        case 'Contact Details':
          pageName = 'contact-detail'
          break
        case 'Best Odds Guaranteed':
          pageName = 'BOG'
          break
        case 'Help':
          pageName = 'help'
          break
      }
      if (!pageName) pageName = this.$route.params.infoType
      this.loadContent(pageName)
    },
    loadContent(pageName){
      const vm = this
      vm.loading = true
      vm.API.getPageData(pageName).then((res)=>{
        if (res.data.length){
          vm.htmlContent = res.data[0].content.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&')
        } else {
          vm.$toast.error('Page not found!')
        }
      })
      .catch(error=>vm.$toast.error(error))
      .finally(()=>vm.loading = false)
    }
  }
}
</script>

<style scoped>
.content{
  position: fixed;
  top: 3.5rem;
  right: 0;
  left: 0;
  bottom: 3.5rem;
  overflow-y: scroll;
}
.content-desktop{
  background: #FFFFFF;
  border-radius: 0.3rem;
}
</style>