<template>
<pre-loader v-if="loading"></pre-loader>
<iframe
  v-show="!loading"
  ref="wufoo"
  allowtransparency="true"
  frameBorder="0"
  class="wufoo-form-container"
  scrolling="no"
  style="min-height: 900px"
  src="https://geoffbanks.wufoo.com/embed/sods3770ek3re8/def/embedKey=sods3770ek3re8140137&amp;entsource=&amp;referrer=https%3Awuslashwuslashgeoff-banks.comwuslashcashierwuslash" >
  <a href="https://geoffbanks.wufoo.com/embed/sods3770ek3re8/def/embedKey=sods3770ek3re8140137&amp;entsource=&amp;referrer=https%3Awuslashwuslashgeoff-banks.comwuslashcashierwuslash">
  </a>
</iframe >
</template>

<script>
import PreLoader from "@/components/UI/PreLoader";
export default {
  name: "WithdrawalView",
  components: {PreLoader},
  data:()=>{
    return{
      loading:true
    }
  },
  mounted() {
    this.$refs.wufoo.onload = ()=>{
      this.loading=false
    }
  }
}
</script>

<style scoped>
.content{
  position: fixed;
  top: 3.5rem;
  bottom: 3.5rem;
  left: 0;
  right: 0;
  overflow-y: scroll;
}
</style>