import * as betcruncher from 'betcruncher';

export const calculatePotentialResult = (betList, multipliedStake, betTypeKey, loopRun, eachWay) => {
  const slip = {
    type: betTypeKey,
    stake: multipliedStake,
    eachWay
  };

  // An array of runner data: [ { odds: "10/1", terms: "1/4", position: 1 } ]
  const runners = betList
    .map((bet, index) => {
      if (index < loopRun) {
        let odds = bet.price.trim().replace('-', '/');
        if (odds === '0/0') {
          odds = '0/1'; // betcruncher cannot avoid deleting by 0
        }
        
        return {
          odds, // 12-2 -> 12/2
          terms: '1/4',
          position: 1
        };
      }
      return null;
    })
    .filter(data => data != null);

  const result = betcruncher(slip, runners);
  return result ? result.returns : null;
}