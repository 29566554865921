<template>
<div v-if="!['g,q', '$'].includes(sport.code) || result" class="race-number fw-600" :style="{backgroundColor: resultColor, color: color}">{{formatNumber}}</div>
<img v-else class="race-number-img" :alt="raceNumber" :src="'https://v0.geoff-banks.com/images/Dogs/'+raceNumber.toString()+'.jpg'">
</template>

<script>
export default {
name: "RaceNumber",
props:{
  raceNumber: {
    type: Number,
    required: true
  },
  bgColor: {
    type: String
  },
  color:{
    type: String
  },
  result: {
    type: Boolean,
    default: false
  },
  sport: {
    type: Object,
  }
},
computed:{
  formatNumber(){
    // return this.raceNumber
    return parseInt(this.raceNumber)<10 && !this.result ? `0${this.raceNumber}`: this.raceNumber
  },
  resultColor(){
    if (!this.result) return undefined
    switch (this.raceNumber){
      case 1: return '#FFC300'
      case 2: return '#d7d7d7'
      case 3: return '#efa131'
    }
    return '#c4c4c4'
  }
}
}
</script>

<style scoped>
.race-number{
  min-height: 1.25rem;
  min-width: 1.25rem;
  background-color: #7F8184;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #FFFFFF;
  font-size: 0.6125rem;
  line-height: 0.6rem;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}
.race-number-img{
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
}
</style>