<template>
<scroll-tabs-bar
  :items="races.map(ev=>ev.Time)"
  :selected-item="currentRace.Time"
  @selectitem="selectEvent"
/>
</template>

<script>
import ScrollTabsBar from "@/components/UI/ScrollTabsBar";
export default {
name: "RacesListMenu",
  components: {ScrollTabsBar},
  props: ['races', 'currentRace'],
  data:()=>{
    return{
      selectedRace: {}
    }
  },
  mounted() {
    this.selectedRace = this.races[0]
  },
  methods:{
    selectEvent(eventTime){
      this.selectedRace = this.races.filter(ev=>ev.Time===eventTime)[0]
      this.$emit('selectrace', this.selectedRace)
    }
  }
}
</script>

<style scoped>

</style>