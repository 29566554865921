import { BETSLIP_STATUSES } from "../_constants";

export const detectBetslipErrors = (betslip, isBetslipSubmitted) => {
    if (!betslip) {
        return null;
    }

    const isEventEnded = (betslip.wagersSubmitResult || [])
        .reduce((sum, next) => (sum || next.isEventEnded), false);

    const isLiabilityExceeded = (betslip.wagersSubmitResult || [])
        .reduce((sum, next) => (sum || next.isLiabilityExceeded), false);

    const isPriceChanged = (betslip.wagersSubmitResult || [])
        .reduce((sum, next) => (sum || next.isPriceChanged), false);

    const isInsufficientFunds = betslip.isInsufficientFunds;

    const isBetslipFailedInGeneral = isBetslipSubmitted && (
        betslip.status === BETSLIP_STATUSES.FAILED
        || betslip.status === BETSLIP_STATUSES.PROCESSING
    );

    const isSelectionNoLongerAvailable = betslip.message != undefined
        && betslip.message.indexOf('Selection is no longer available') !== -1;

    const isNothingToSubmit = betslip.message != undefined
        && betslip.message.indexOf('Selection is no longer available') !== -1;

    const isAnyError = (
        isEventEnded
        || isLiabilityExceeded
        || isPriceChanged
        || isInsufficientFunds
        || isBetslipFailedInGeneral
        || isSelectionNoLongerAvailable
        || isNothingToSubmit
    );

    return {
        isEventEnded,
        isLiabilityExceeded,
        isPriceChanged,
        isInsufficientFunds,
        isBetslipFailedInGeneral,
        isSelectionNoLongerAvailable,
        isAnyError,
        isNothingToSubmit,
    };
}