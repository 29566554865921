<template>
<div>
<main-header id="main"/>
  <sports-bar v-if="showSportsBar" :current-sport-title="currentSportTitle"/>
  <div
    id="content"
    :class="{'content-without-sports-bar': !showSportsBar}"
    class="content bg-light"
  >
    <div class="router-container" :class="{'router-container-without-sports-bar': !showSportsBar}">
      <router-view v-slot="{Component}">
        <transition name="fade">
          <component :is="Component"/>
        </transition>
      </router-view>
    </div>
    <main-footer/>
  </div>
<bottom-menu/>
</div>
</template>

<script>
import MainFooter from "@/components/MainFooter";
import MainHeader from "@/components/MainHeader";
import SportsBar from "@/components/SportsBar";
import BottomMenu from "@/components/BottomMenu";
export default {
  name: "BaseMenuView",
  components: {BottomMenu, SportsBar, MainHeader, MainFooter},
  computed:{
    currentSportTitle(){
      if (["sport", "raceEvent", "matchEvent", "antepostEvent"].includes(this.$route.name)){
        return this.$route.params.sportName
      }
      return ''
    },
    showSportsBar(){
      return ["home", "sport", "raceEvent", "matchEvent", "antepostEvent"].includes(this.$route.name)
    }
  },
}
</script>

<style scoped>
.content{
  position: absolute;
  top: 7.5rem;
  right: 0;
  left: 0;
  bottom: 3.5rem;
  overflow-y: scroll;
}
.content-without-sports-bar{
  top: 3.5rem;
  height: auto;
  /*min-height: 100%;*/
}
.router-container{
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
}
.router-container-without-sports-bar{
  min-height: 100%;
}
</style>