<template>
<div class="tabs-wrap bg-white" :class="$store.state.isMobile?'':'rounded-3 mb-2'" ref="tabs">
    <div class="tabs-container d-flex justify-content-center py-2 px-3">
      <div
          class="tabs__item px-2 mx-1 fs-13"
          v-for="(item, index) in items"
          :key="index" :class="{'active-tab': item===selectedItem}"
          @click="selectItem($event, item)"
      >{{item}}</div>
    </div>
</div>
</template>

<script>
export default {
name: "ScrollTabsBar",
emits: ['selectitem'],
props: ['items', 'selectedItem'],
mounted() {
  let selectedItem = document.getElementsByClassName('active-tab')[0]
  this.$refs.tabs.scrollLeft=selectedItem.offsetLeft - window.visualViewport.width / 2
},
methods:{
  selectItem(event, item){
    this.$refs.tabs.scrollLeft=event.target.offsetLeft - window.visualViewport.width / 2
    this.$emit('selectitem', item)
  }
}
}
</script>

<style scoped>
.tabs-wrap{
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.tabs-container{
  min-width: max-content;
}
.tabs-container .active-tab{
  border-radius: 2rem;
  border: 2px solid #FFC549;
}
.tabs__item{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>