import { createStore } from 'vuex'
import * as API from "@/http/api"
import betslipStore from "@/store/betslipStore";
import {Device} from "@capacitor/device";


export default createStore({
  state: {
    authToken: localStorage.getItem('authToken', null),
    user: {},
    settings: {},
    platform: null,
    isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 1024
  },
  getters: {
    isMobile(state){
      return state.isMobile
    },
    SETTINGS(state){
      return state.settings
    },
    USER(state){
      return state.user
    },
    isAuthenticated(state){
      return state.authToken
    }
  },
  mutations: {
    SET_PLATFORM_ON_STATE(state, value){
      state.platform = value
    },
    SET_SETTINGS(state, data) {
      localStorage.setItem('settings',JSON.stringify(data))
      state.settings = data;
    },
    SET_AUTH_TOKEN_ON_STATE(state, token){
      localStorage.setItem('authToken', token)
      state.authToken = token;
    },
    SET_USER_DATA_ON_STATE(state, data){
      for (let key in data){
        state.user[key] = data[key]
      }
      localStorage.setItem('user',JSON.stringify(state.user))
    },
    LOGOUT_ON_STATE(state){
      state.user = {}
      state.authToken = null
      localStorage.removeItem('user')
      localStorage.removeItem('authToken')
    }

  },
  actions: {
    GET_SETTINGS({commit}){
      return new Promise((resolve)=> {
        const promise1 = Device.getInfo().then(info => {
          commit('SET_PLATFORM_ON_STATE', info.platform)
        })
        const promise2 = new Promise(resolve => {
          API.getSettings()
            .then(data => {
              commit('SET_SETTINGS', data.data[0])
              resolve()
            })
          console.log('GET_SETTINGS...')
        })
        Promise.all([promise1, promise2]).then(() => resolve())
      })
    },
    async UPDATE_USER_DATA({commit, dispatch}){
      return new Promise((resolve, reject)=>{
        let authData = JSON.parse(localStorage.getItem('userCreds'))
        API.login(authData.username, authData.password, (data)=>{
          if (data.Status === '0') {
            dispatch('LOGOUT')
            reject(data.Message)
          }
          commit('SET_USER_DATA_ON_STATE', data)
          resolve()
        })
      })
    },
    async LOGIN({commit, dispatch}, authData){
      return new Promise((resolve, reject)=>{
        let username = null
        API.login(authData.username, authData.password, (data)=>{
          if (data.Status === '0') {
            dispatch('LOGOUT')
            reject(data.Message)
          }
          username = data.Cust_Ref
          localStorage.removeItem('realityCheckAt')
          commit('SET_AUTH_TOKEN_ON_STATE', data.Token)
          commit('SET_USER_DATA_ON_STATE', data)
          let promise1 = API.getUserDetailsByCustRef(username).then((data)=>{
            console.log('getUserDetailsByCustRef', data)
            commit('SET_USER_DATA_ON_STATE', data.data[0])
          })
          let promise2 = API.getUserPreferences(username).then((data)=>{
            if (data.data.length){
              commit('SET_USER_DATA_ON_STATE', {
                  oddsFormat: data.data[0].odds_format,
                  accountStatus: data.data[0].account_status,
                })
            }
            })
          let promise3 = API.getUserInfo(authData).then(res=>{
            commit('SET_USER_DATA_ON_STATE', {
                email : res.EmailAddress,
                limit : res.TelephoneNumber,
                workPhone : res.Work_Tel,
                phone : res.mobile,
                address1 : res.address1,
                address2 : res.address2,
                address3 : res.address3,
                city : res.address4,
                country : res.country,
                zip : res.postCode,
              })
          })
          let promise4 = API.getUserDetailFromDirectus(username).then(res=>{
            if (res.data.length){
              commit('SET_USER_DATA_ON_STATE', {direct: res.data[0]})
            } else {
              API.createUserDetails({username: username, status: "published"}).then(res=>{
                commit('SET_USER_DATA_ON_STATE', {direct: res.data[0]})
              })
            }
          })
          let promise5 = dispatch('BETSLIP_LOGIN', authData)
          Promise.all([promise1, promise2, promise3, promise4, promise5]).then(()=>{
            localStorage.setItem('userCreds', JSON.stringify({
              username:authData.username,
              password:authData.password
            }))
            resolve()
          })
        },(error)=>{console.error(error); reject(error)})
      })
    },
    SET_AUTH_TOKEN({commit}, token){
      commit('SET_AUTH_TOKEN_ON_STATE', token)
    },
    SET_USER_DATA({commit}, data){
      return new Promise(resolve=>{
        commit('SET_USER_DATA_ON_STATE', data)
        resolve()
      })
    },
    LOGOUT({commit, dispatch}){
      return new Promise(resolve=>{
        localStorage.removeItem('userCreds')
        commit('LOGOUT_ON_STATE')
        dispatch('BETSLIP_LOGOUT')
        resolve()
      })
    }

  },
  modules: {
    betslipStore,
  }
})
