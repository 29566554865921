<template>
  <div class="flex-fill d-flex flex-column">
  <video-stream/>
<!--  <div v-if="!highlightsLoaded && !nextRacesLoaded" class="position-relative flex-fill"><pre-loader/></div>-->
  <div v-show="nextRacesLoaded || highlightsLoaded" class="d-flex flex-column flex-fill">
    <next-events
        :sport="API.SPORTS[0]"
        @dataloaded="nextRacesLoaded=true"
        style="flex: 0 1 auto;"
        no-events
    />
    <sport-highlights
        v-show="nextRacesLoaded || highlightsLoaded"
        @dataloaded="highlightsLoaded=true"
        style="flex: 1 1 auto;"
    />
  </div>
</div>
</template>

<script>

import NextEvents from "@/components/Home/NextEvents";
import SportHighlights from "@/components/Home/SportHighlights";
// import PreLoader from "@/components/UI/PreLoader";
import VideoStream from "@/components/VideoStream";
export default {
  name: 'HomeView',
  components: {
    VideoStream,
    SportHighlights,
    NextEvents,
  },
  data: ()=>{
    return{
      nextRacesLoaded: false,
      highlightsLoaded: false
    }
  },
}
</script>
<style scoped>
.home-container{
  margin-top: 7.5rem;
  margin-bottom: 4.5rem;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.home-content{
  min-height: 100%;
  display: flex;
  flex-flow: column;
}

::-webkit-scrollbar {
    display: none;
}
</style>
