<template>
<div class="px-4">
  <div class="steps d-flex justify-content-between mb-3">
    <div class="step-card" @click="step=1" :class="{'active': step===1 }">Step-1</div>
    <div class="step-card" @click="step=2" :class="{'active': step===2 }">Step-2</div>
    <div class="step-card" @click="step=3" :class="{'active': step===3 }">Step-3</div>
    <div class="step-card" @click="step=4" :class="{'active': step===4 }">Step-4</div>
  </div>
  <hr class="">
  <form @submit.prevent="submitForm">
  <transition :name="transitionName">
  <div v-show="step===1" class="position-fixed step">
    <div class="fw-600 fs-4 mt-4 mb-3">Your Details</div>
    <VueInput
      label="First Name"
      name="firstname"
      v-model="firstName"
      :error="firstNameError"
    />
    <VueInput
      label="Last Name"
      name="lastname"
      v-model="lastName"
    />
    <div class="mb-2">
      <label class="fs-14 my-2">Date of Birthday</label>
      <Datepicker
         auto-position
         auto-apply
         :enable-time-picker="false"
         v-model="dateRow"
         class="mb-2"
      />
      <div class="text-secondary text-end">
        You must be over 18 to bet with us/ If we cant verify your age electonically, we will require documets to verify your age
      </div>
    </div>
<!--    <VueInput-->
<!--      label="Date"-->
<!--      name="date"-->
<!--      placeholder="DD"-->
<!--      v-model="date"-->
<!--    />-->
<!--    <VueInput-->
<!--      label="Month"-->
<!--      name="month"-->
<!--      placeholder="MM"-->
<!--      v-model="month"-->
<!--    />-->
<!--    <VueInput-->
<!--      label="Year"-->
<!--      name="year"-->
<!--      placeholder="YYYY"-->
<!--      v-model="year"-->
<!--      description="You must be over 18 to bet with us/ If we cant verify your age electonically, we will require documets to verify your age"-->
<!--    />-->

  </div>
  </transition>

  <transition :name="transitionName">
  <div v-show="step===2" class="position-fixed step">
     <div class="fw-600 fs-4 mt-4 mb-3">Your Details</div>
    <VueInput
      label="Postal Code or Adress"
      name="zip"
      description="Enter adress manually"
      v-model="zip"
    />
    <VueInput
      label="Mobile Number"
      name="phone"
      description="Including country code"
      v-model="phone"
    />
    <VueInput
      label="Daily Deposit Limit"
      name="deposit"
      description="Deposit limits over £2000 daily will need to be accompanied with a recent bank statement"
      v-model="limit"
    />

  </div>
  </transition>
  <transition :name="transitionName">
  <div v-show="step===3" class="position-fixed step">
    <div class="fw-600 fs-4 mt-4 mb-3">Your Account</div>
    <VueInput
      class="mt-4"
      type="file"
      label="Photo ID"
      name="photoId"
      v-model="photoId"
      ref="photoId"
    />
    <VueInput
      class="mt-4"
      type="file"
      label="Adress Verefication (Optional)"
      name="addressfile"
      v-model="addressVerification"
    />
    <VueInput
      class="mt-4"
      name="username"
      label="Preferred User Name"
      description="No special charractes allowed"
      v-model="username"
    />
    <VueInput
      name="email"
      label="Email Address"
      v-model="email"
    />
  </div>
  </transition>
  <transition :name="transitionName">
  <div v-show="step===4" class="position-fixed step">
    <div class="fw-600 fs-4 mt-4 mb-3">Account Security</div>
    <VueInput
      type="password"
      autocomplete
      label="Preferred Password"
      name="password"
      description="Min 12 characters alpha and numeric only"
      v-model="password"
    />
    <VueSelect
      v-model="securityQuestion"
      label="Security Question"
      placeholder="Select security question"
      :options="QuestionList.map(el=>el.label)"
    />
    <VueInput
      label="Security Answer"
      name="securityA"
      v-model="securityAnswer"
    />
    <VueInput
      name="promocode"
      label="Promocode"
      v-model="promocode"
    />
    <VueCheckbox
      class="my-3"
      name="recievemess"
      v-model="recieveMessages"
      description="Recieve target marketing messages "
    />
    <VueCheckbox
      class="my-4"
      name="agree"
      v-model="agree"
      description="I agree to the terms and conditions"
    />
  </div>
  </transition>
   <div class="d-flex justify-content-between position-absolute bottom-0 start-0 end-0 p-4">
    <VueButton type="button" @click="backStep()" class="w-50 me-1" >Back</VueButton>
    <VueButton :type="step===4?'submit':'button'" @click="nextStep()" class="w-50 ms-1" cls="main">{{ step===4?'Done!':'Next' }}</VueButton>
  </div>
  </form>
</div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueInput from "@/components/UI/VueInput";
import VueButton from "@/components/UI/VueButton";
import VueSelect from "@/components/UI/VueSelect";
import VueCheckbox from "@/components/UI/VueCheckbox";
export default {
name: "RegistrationView",
  components: {VueCheckbox, VueSelect, VueButton, VueInput, Datepicker},
  data: ()=>{
    return {
      dateRow: '',
      firstName: '',
      firstNameError: '',
      lastName: '',
      lastNameError: '',
      date: '',
      month: '',
      year: '',
      zip: '',
      phone: '',
      limit: '',
      username: '',
      photoId: '',
      photoIdBase64: '',
      addressVerification: '',
      addressVerificationBase64: '',
      email: '',
      password: '',
      securityQuestion: '',
      securityAnswer: '',
      promocode: '',
      recieveMessages: false,
      agree: false,

      step: 1,
      transitionName: 'slide-next',

      QuestionList: [
        {
          label: "What is your mother's maiden name?",
          value: "What is your mother's maiden name?",
        },
        {
          label: "Which phone number do you remember most from your childhood?",
          value: "Which phone number do you remember most from your childhood?",
        },
        {
          label: "What was your favorite place to visit as a child?",
          value: "What was your favorite place to visit as a child?",
        },
        {
          label: "Who is your favorite actor,musician, or artist?",
          value: "Who is your favorite actor,musician, or artist?",
        },
        {
          label: "What is the name of your best friend from childhood?",
          value: "What is the name of your best friend from childhood?",
        },
      ],
    }
  },
  watch:{
    photoId(){
      let input = document.getElementById('photoId')
      if (input.files.length) {
        this.glb.convertInputFileToBase64(input.files[0])
        .then(res=>this.photoIdBase64=res)
      }
    },
    addressVerification(){
      let input = document.getElementById('addressfile')
      if (input.files.length) {
        this.glb.convertInputFileToBase64(input.files[0])
        .then(res=>this.addressVerificationBase64=res)
      }
    }
  },
  methods:{

    nextStep(){
      this.transitionName='slide-next'
      if (this.step<4) this.step += 1
    },
    backStep(){
      if (this.step===1) {
        this.$router.back()
      } else {
        this.transitionName='slide-back'
        this.step -= 1
      }
    },
    submitForm(){
      console.log(this.step)
    }
  }
}
</script>

<style scoped>
hr{
  color: #b7b7b7;
}
.dp__input{
  width: 100%;
  height: 3.125rem;
  background: #F0F1F5;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  border: none;
  overflow: hidden;
}
.step-card{
  display: flex;
  align-items: end;
  height: 3.5rem;
  padding: 1rem 1rem 0.5rem 1rem;
  color: #b7b7b7;
  background-color: #F8F8FA ;
  border-radius: 0 0 4px 4px;
}
.active{
  color: #09080C;
  background-color: #FFD60A;
}

.slide-next-enter-from{
  transform: translateX(100vw);
}
.slide-next-leave-to {
  transform: translateX(-100vw);
}
.slide-back-enter-from{
  transform: translateX(-100vw);
}
.slide-back-leave-to {
  transform: translateX(100vw);
}
.slide-back-enter-active,
.slide-back-leave-active,
.slide-next-enter-active,
.slide-next-leave-active{
  transition: transform 0.3s;
}
.step{
  position: fixed;
  overflow: scroll;
  top: 4.5rem;
  left: 0;
  right: 0;
  bottom: 4.5rem;
  padding: 0 1.5rem 1rem;
}
</style>