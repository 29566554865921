<template>
<div v-if="!$store.state.authToken" class="p-2 text-center fs-12" :class="!$store.state.isMobile?'bg-white rounded-3 mb-2':''">Please login to your account to view live streams of games/races.</div>
<div v-else>
<div ref="streamWrap" id="streamWrap" class="d-flex p-2 bg-white align-items-center" :class="{'justify-content-between': show, 'justify-content-end': !show, 'bg-white rounded-3 mb-2': !$store.state.isMobile}">
    <div v-if="show" class="flex-fill h-100 d-flex justify-content-center align-items-center cursor-pointer pe-2" style="max-width: 25%">
      <select v-model="selectedStream" class="h-100 d-flex fs-12 justify-content-between w-100 border-0 bg-white">
        <option value="TRP" selected>AtTheRaces</option>
        <option value="SD365">Racing UK</option>
        <option value="SIS">SIS Stream</option>
      </select>
    </div>
    <div v-if="streamEvents.length && show" class="flex-fill h-100 d-flex justify-content-center align-items-center" style="max-width: 50%">
      <select v-if="selectedStream==='SD365' && selectedEvent && loaded" @change="selectEvent" class="h-100 d-flex fs-12 justify-content-between w-100 border-0 bg-white">
        <option
            v-for="ev in streamEvents"
            :key="ev.id"
            :value="ev.id"
            :selected="ev.id===selectedEvent.id"
        >{{ ev.description.split(' ').slice(0,2).join(' ') }}</option>
      </select>
      <select v-if="selectedStream==='SIS' && selectedEvent && loaded" @change="selectEvent" class="h-100 d-flex fs-12 justify-content-between w-100 border-0 bg-white">
        <option
            v-for="ev in streamEvents"
            :key="ev.streamId"
            :value="ev.streamId"
            :selected="ev.streamId===selectedEvent.streamId"
        >{{ ['Live Races','Buckingham'].includes(ev.competition)? ev.competition: ev.competition + ' ' + displayEventTime(ev.startDate) }}</option>
      </select>
      <select v-if="selectedStream==='TRP' && selectedEvent && loaded" @change="selectEvent" class="h-100 d-flex fs-12 justify-content-between w-75 border-0 bg-white">
        <option
            v-for="ev in streamEvents"
            :key="ev.ID"
            :value="ev.ID"
            :selected="ev.ID===selectedEvent.ID"
        >{{ ev.Location }}</option>
      </select>
      <div v-if="selectedStream==='TRP'" class="w-25 d-flex justify-content-center align-items-center">
        <info-icon class="fs-12">LIVE</info-icon>
      </div>
    </div>

    <div class="d-flex align-items-center">
      <div class="mx-2 fs-10">Show stream</div>
      <vue-switch v-model="show"/>
    </div>
  </div>

  <div class="stream-container" :style="{maxHeight:calculateMaxHeight+'px'}">

  <div class="bg-dark position-relative" :style="{width: calculatedWidth+'px', height: calculateMaxHeight+'px'}">
    <iframe
      v-if="streamSrc && loaded"
      class=""
      sandbox="allow-scripts allow-same-origin"
      allow="autoplay"
      :key="streamId"
      :src="streamSrc"
      :width="calculatedWidth"
      :height="calculateHeight"
      scrolling="no"
      frameborder="no"
      allowfullscreen
    ></iframe>
    <div v-else  class="p-2 text-center fs-12 d-flex align-items-center justify-content-center text-warning h-100 w-100">
      Live event streaming will show here as soon as available.
    </div>
  </div>
</div>
</div>
</template>

<script>
import _ from "lodash"
import {partnerCode, seedKey} from "@/services/videoStream/constants";
import VueSwitch from "@/components/UI/VueSwitch";
import InfoIcon from "@/components/UI/InfoIcon";
import {useToast} from "vue-toastification";
import {mapGetters} from "vuex";
const CryptoJS = require("crypto-js");

export default {
  name: "VideoStream",
  components: {InfoIcon, VueSwitch},
  props: ['eventName', 'eventFile', 'sportCode', 'eventTime', 'locationName'],
  data:()=>{
    return{
      show: localStorage.getItem('showStream')? JSON.parse(localStorage.getItem('showStream')) : true,
      loaded: true,

      streamSrc: null,
      streamLoc: null,
      streamDesc: null,
      streamVenue: null,
      streamId: undefined,

      streamEvents: [],
      selectedEvent: null,

      RmgAllEvents: [],

      selectedStream: null,
      streamTypes: [
          {id: "TRP", name: "AtTheRaces"},
          {id: "SD365", name: "Racing UK"},
          {id: "SIS", name: "SIS Stream"},
      ],
      HorseRacing: ["1","1001", "1002", "1003", "1009", "1012", "1013", "1014"],
      GreyHoundRacing: ["1202", "1203", "1206", "1207"],
      intervalLoad: null,
      calculatedWidth: 300
    }
  },
  setup(){
    const toast = useToast()
    return { toast }
  },
  computed:{
    ...mapGetters(['isMobile']),
    calculateMaxHeight(){
      const aspectRatio = this.selectedStream === "SD365"? 3/4.7 : 9/16
      return this.show?parseInt(parseInt(this.calculatedWidth)*aspectRatio):0
    },
    calculateHeight(){
      const aspectRatio = this.selectedStream === "SD365"? 3/4.7 : 9/16
      return parseInt(parseInt(this.calculatedWidth)*aspectRatio)
    }
  },
  watch:{
    '$store.state.authToken'(){
      if (this.intervalLoad) clearInterval(this.intervalLoad)
      setTimeout(()=>this.calculateWidth())
    },
    selectedStream(){
      this.streamId = null
      this.selectedEvent = null
      this.init()
    },
    selectedEvent(value, oldValue){
      if (oldValue) this.init()
    },
    show(isShow){
      if (this.intervalLoad) clearInterval(this.intervalLoad)
      this.startStreaming()
      this.calculateWidth()
      localStorage.setItem('showStream', isShow)
    }
  },
  beforeUnmount() {
    if (this.intervalLoad) clearInterval(this.intervalLoad)
    window.removeEventListener('resize', this.calculateWidth)
  },
  mounted() {
    window.addEventListener('resize', this.calculateWidth)
    this.startStreaming()
    this.calculateWidth()
  },
  methods:{
    startStreaming(){
      const vm = this
      if (vm.show && vm.$store.state.authToken){
        console.log('START STREAMING')
        this.selectedStream = this.$store.state.settings.default_stream || 'TRP'
        if (this.eventFile) {
          if (this.eventFile.includes("HGB00")) {
            this.selectedStream = "SD365"
          } else if (this.eventFile !== null && this.eventFile.includes("BFTRP")) {
            this.selectedStream = "TRP"
          } else if (this.eventName !== null && this.eventName.includes("SD365")) {
            this.selectedStream = "SIS"
          }
        }
        vm.intervalLoad = setInterval(()=>vm.init(), 5000)
      } else {
        if (vm.intervalLoad) clearInterval(vm.intervalLoad)
      }
    },
    init() {
      const vm = this
      if (!vm.selectedStream) vm.loaded = false
      vm.streamLoc = null
      vm.streamDesc = null
      vm.streamVenue = null
      return new Promise((resolve)=>{
         if (vm.selectedStream === "SD365") {
            vm.API.getRMGEventList()
              .then((cb) => {
                  console.log(cb, "RMG EVENTS VIDEO")
                  vm.RmgAllEvents = cb.Events || [] //Set empty array if undefined
                  vm.streamEvents = cb.CurrentEvents || []
                  if (vm.locationName && vm.eventTime && !vm.selectedEvent){
                      let singleHorseRace = _.filter(vm.RmgAllEvents, (r) => {
                          const rmgLocationName = r.location;
                          const rmgEventTime = r.description.split(" ")[1]
                          return (
                              vm.locationName.toLowerCase() ===
                              rmgLocationName.toLowerCase() &&
                              vm.eventTime === rmgEventTime &&
                              vm.sportCode === "H,h"
                          )
                      })
                      if (singleHorseRace.length){
                         vm.selectedEvent = singleHorseRace[0]
                      } else {
                         vm.selectedEvent = vm.streamEvents[0]
                      }
                  }
                  if (vm.streamEvents.length <= 0) {
                    resolve(vm.loaded=true)
                  } else {
                    // console.log(evt, "RMG EVENT VIDEO")
                    if (!vm.selectedEvent){
                      vm.selectedEvent = vm.streamEvents[0]
                    }

                    const data = {
                        platform: "Mobile",
                        eventID: vm.selectedEvent.id,
                        userID: vm.$store.state.user.Cust_Ref,
                    }
                    vm.API.getRMGStream(data)
                    .then((stream) => {
                        const video_url = stream;
                        console.log(video_url, "VIDEO URL RMG");
                        vm.streamSrc = video_url + '&width=' + this.calculatedWidth
                    })
                   .finally(()=>resolve(vm.loaded=true))
                  }
              })
        } else if (vm.selectedStream === "TRP") {
            vm.API.getStreamEvents()
              .then((cb) => {
                let data = cb.Events
                console.log(cb, "ATR EVENTS VIDEO")

                // CHECKING HERE FOR PAGE/EVENT BASED ON WHICH WE COMPARE THE CONTENT ID OF HORSE RACING AND GREYHOUND AND SET SPORT EVENTS.
                let sportEvents = []
                if (vm.sportCode == "H,h") {
                    sportEvents = _.filter(data, (e) =>
                        _.some(vm.HorseRacing, (h) => {
                            if (parseInt(e.ContentTypeID) === parseInt(h)) return e
                        })
                    )
                } else if (vm.sportCode == "g,q") {
                    sportEvents = _.filter(data, (event) =>
                        _.some(vm.GreyHoundRacing, (h) => {
                            if (parseInt(event.ContentTypeID) === parseInt(h)) return event
                        })
                    )
                } else sportEvents = data

                if (!sportEvents.length){
                  // vm.toast.warning('No At The Races streaming at the moment.')
                  // vm.sportCode !== undefined && vm.sportCode.includes("g,q") ?
                  //   vm.selectedStream="SIS" :
                  //   vm.selectedStream="SD365"
                  vm.streamSrc = null
                  // vm.selectedStream="SD365"
                  resolve(vm.selectedStream="SD365")
                  // this.init().then(()=>resolve())
                } else {
                  vm.streamEvents = sportEvents
                  if (!vm.selectedEvent) {
                    vm.selectedEvent = vm.streamEvents[0]
                  }

                  let val =`${partnerCode}:${vm.selectedEvent.ID}:L:${vm.$store.state.user.Cust_Ref}:${seedKey}`
                  let valMd5 = CryptoJS.MD5(val).toString()
                  const video_url = 'https://bw197.attheraces.com/ps/player/default.aspx?partnercode=pkgc197&eventid='+vm.selectedEvent.ID+'&eventtype=live&userid='+vm.$store.state.user.Cust_Ref+'&key='+valMd5
                  console.log("TRP STREAM URL", video_url)

                  vm.streamSrc = video_url
                  vm.streamLoc = vm.selectedEvent.Location
                  vm.streamDesc = vm.selectedEvent.Description
                  vm.streamVenue = vm.selectedEvent.VenueCode
                  resolve(vm.loaded=true)
                }
            })
        } else if (vm.selectedStream === "SIS") {
            vm.API.getSISEvents(vm.sportCode)
              .then((cb) => {
                let data = cb.CurrentEvents.length === 0 ? cb.Events : cb.CurrentEvents;
                let sportEvents = []
                console.log(
                    data,
                    vm.sportCode,
                    "SIS EVENTS"
                )
                vm.streamEvents = data
                if (vm.sportCode === "H,h") {
                    sportEvents = _.filter(data, (e) => {
                        if (e.sportName === "Horse Racing" || e.sportName === "Other")
                            return e
                    })
                } else if (vm.sportCode === "g,q") {
                    sportEvents = _.filter(data, (event) => {
                        if (event.sportName === "Greyhounds" || event.sportName === "Other")
                            return event
                    })
                }
                if (vm.sportCode){
                    if (sportEvents.length > 0) vm.streamEvents = sportEvents
                }

                if (!vm.streamEvents.length) {
                    // if(vm.sportCode == "vh" || vm.sportCode =="$") resolve(vm.loaded=true)
                    // vm.toast.warning('No SIS streaming at the moment.')
                    vm.streamSrc = null
                    resolve(vm.selectedStream = "TRP")
                }

                if (!vm.selectedEvent) {
                  vm.selectedEvent = vm.streamEvents[0]
                }
                data = {
                    streamId: vm.selectedEvent.streamId,
                    username: vm.$store.state.user.Cust_Ref,
                }
                const prevVal = vm.streamId

                vm.API.getSISStream(data)
                .then((stream) => {
                    if (stream.error !== undefined && stream.error.length > 0) {
                        // Util.ShowNotification(stream.error);
                      console.error(stream.error)
                    }
                    const video_url = stream.phenixEmbedUrl ? stream.phenixEmbedUrl : ''
                    console.log(video_url, "SIS STREAM URL");
                    if (video_url === "") vm.selectedEvent = null
                    if (prevVal !== stream.streamId) {
                      vm.streamSrc = video_url
                      vm.streamId = stream.streamId
                    }
                })
              .finally(()=>resolve(vm.loaded=true))
            })
        }
      })
    },
    selectStream(name){
      this.selectedStream = this.streamTypes.filter(el=>el.name===name)[0].id
    },
    displayEventTime(startDateTime) {
      let startTime = new Date(startDateTime).toLocaleTimeString();
      var t = startTime.split(":");
      return t[0] + ":" + t[1];
    },
    selectEvent(e){
      if (this.selectedStream === 'SD365'){
        this.selectedEvent = this.streamEvents.filter(el=>parseInt(el.id) === parseInt(e.target.value))[0]
      } else if (this.selectedStream === 'SIS'){
        console.log('SELECT SIS STREAM', e.target.value)
        this.selectedEvent = this.streamEvents.filter(el=>el.streamId==e.target.value)[0]
        console.log('SELECTED SIS STREAM', this.selectedEvent)
      } else if (this.selectedStream === 'TRP'){
        this.selectedEvent = this.streamEvents.filter(el=>el.ID==e.target.value)[0]
      }
    },
    calculateWidth(){
      try {
        if (this.$refs.streamWrap){
          this.calculatedWidth = this.$refs.streamWrap.offsetWidth
          if (!this.calculatedWidth) setTimeout(()=>this.calculateWidth(), 100)
          console.error('calculateWidth >>>>>>', this.$refs.streamWrap)
          console.error('calculateWidth >>>>>>', this.$refs.streamWrap.offsetWidth)
        }
      } catch (e) {
        console.log('calculateWidth e >>>>< M>M', e)
      }
    }
  }
}
</script>

<style scoped>
.spinner-border-sm{
  height: 0.75rem;
  width: 0.75rem;
}
.stream-container{
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.2s ease;
}
iframe{
  border: none;
}
select:focus-visible,
select:focus{
  outline: none;
}
</style>