<template>
<transition name="fade">
<div v-if="loading" class="sidebar_preloader p-2">
  <div class="position-relative rounded-3">
    <pre-loader sm/></div>
  </div>
</transition>
<div v-show="!loading">
      <!--    Sport Options -->
    <div v-if="options.length && sport.type === 'race'" class="bg-white rounded-3 py-2 mb-2">
    <div class="fw-600 p-2 border-bottom">{{sport.title}} Options</div>
    <div
      v-for="(option, index) in options"
      :key="index"
      class="p-2 border-bottom cursor-pointer"
      @click="selectSportOption(option)"
      :class="{'active_category':isActiveOption(option)}"
    >{{option}}</div>
  </div>
      <!-- Main Categories -->
    <div v-if="categories.length" class="bg-white rounded-3 py-2 mb-2">
      <div class="fw-600 p-2 border-bottom">{{sport.title}}</div>
      <div
        v-for="category in categories"
        :key="category.Id"
        class="p-2 border-bottom cursor-pointer"
        @click="selectEventCategory(category)"
        :class="{'active_category':isActiveCategory(category)}"
      >
        <div>{{category.EventName?category.EventName:category}}</div>
      </div>
    </div>

      <!--   AntePost Categories-->
    <div v-if="antepostCategories.length" class="bg-white rounded-3 py-2">
    <div v-for="category in antepostCategories" :key="category.EventName">
     <div class="fw-600 p-2 border-bottom">{{category.EventName}}</div>
      <div
        v-for="ev in category.Meetings"
        :key="ev.EventCode"
        class="p-2 border-bottom cursor-pointer"
        :class="{'active_category':isActiveAntepost(ev)}"
        @click="$router.push({name: 'antepostEvent', params:{sportName:sport.title, ef: ev.EventCode?ev.EventCode:v.EventFile, eg: ev.EventGroup}, query:{antepostTitle:ev.EventName?ev.EventName:ev.MeetinName}})"
      >
      <div>{{ev.EventName}}</div>
      </div>
    </div>
  </div>
  <div v-else>

  </div>
</div>
</template>

<script>
import axios from "axios";
import PreLoader from "@/components/UI/PreLoader";

export default {
  name: "SideSportBar",
  components: {PreLoader},
  emits: ['select-event-category'],
  props: ['sport'],
  data: ()=>{
    return{
      categories: [],
      options: [],
      antepostCategories: [],
      loading: false
    }
  },
  watch:{
    sport(){
      this.loadCategories()
    },
    '$route.query.selectedOption'(val){
      if (val){
        this.categories = []
        this.loadCategories()
      }
    }
  },
  mounted() {
    this.loadCategories()
  },
  methods:{
    loadCategories(){
      const vm = this
      vm.loading = true
      let selectedSport = null
      if (vm.sport.subcategories && vm.$route.query.selectedOption){
        const selectedOptionName = vm.$route.query.selectedOption
        try {
          selectedSport = this.sport.subcategories.filter(subc => subc.title === selectedOptionName)[0]
        } catch (e) {
          console.log('select sport', e)
        }
      }
      const URL = selectedSport? selectedSport.url : vm.sport.url
      if (URL){
        axios.get(URL)
        .then(response=>{
          if (this.sport.type === 'competition'){
            vm.categories = response.data.map(cat => {
              let category = cat
              category.events = []
              cat.Competitions.map(comp => {
                comp.details.map(ev => {
                  let event = ev
                  event.date = comp.date
                  event.Code = cat.Code
                  event.EventName = comp.EventName
                  category.events.push(event)
                  return event
                })
              })
              return category
            })
          } else {
            vm.categories = response.data === '[{]}]' ? [] : response.data
          }
          if (vm.categories.length && vm.$route.name === 'sport' && !vm.$route.query.selectedCategoryEventName) vm.selectEventCategory(vm.categories[0])
          vm.loading=false
        })
        .catch(error=>{console.log(error), vm.loading=false})
      if (vm.sport.antePostUrl) {
        axios.get(vm.sport.antePostUrl)
        .then(response=>{
          vm.antepostCategories = response.data
        })
        .catch(error=>{console.log(error)})
      }
      }
      if (this.sport.subcategories){
          this.options = this.sport.subcategories.map(sc=>sc.title)
          this.currentSportCode = this.sport.subcategories[0].code
      } else if (this.sport.url){
          this.options = ['Races']
      } else {
          this.options = []
      }
      if (this.sport.antePostUrl){
          this.options = this.options.concat(['Futures'])
          this.loading = false
      }
      if (this.sport.type === 'race'){
        if (this.options.length && vm.$route.name === 'sport') this.selectSportOption(this.options[0])
      }
    },
    selectEventCategory(category){
      this.$router.push({name: 'sport', params:{sportName: this.sport.title}, query:{selectedOption: this.$route.query.selectedOption, selectedCategoryEventName: category.EventName}})
    },
    selectSportOption(option){
      this.$router.push({name: 'sport', params:{sportName: this.sport.title}, query:{selectedOption: option}})
    },
    isActiveCategory(category){
      return this.$route.query.selectedCategoryEventName === category.EventName
    },
    isActiveOption(option){
      return this.$route.query.selectedOption === option
    },
    isActiveAntepost(ev){
      return this.$route.query.antepostTitle === ev.EventName
    }
  }
}
</script>

<style scoped>
.sidebar_preloader{
  height: 10rem;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}
.sidebar_preloader > div{
  width: inherit;
  height: inherit;
  background-color: #FFFFFF;
}
.active_category{
  background-color: #f9f9f9;
}
</style>