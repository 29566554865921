<template>
<div class="pre-loader-wrap position-absolute top-50 start-50 translate-middle">
  <div class="pre-loader" :class="{'sm': sm}" :style="{backgroundImage: 'url('+require('../../assets/logo.png')+')'}"></div>
</div>
</template>

<script>
export default {
name: "PreLoader",
props: {
  sm:{
    type: Boolean,
    default: false,
  }
}
}
</script>

<style scoped>
.spinner-grow{
  height: 5rem;
  width: 5rem;
}
.pre-loader-wrap{
  z-index: 1000;
}
.pre-loader{
  position: relative;
  overflow: hidden;
  height: 6rem;
  width: 6rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  animation: scale 2.5s infinite ease-in-out;
}
.sm{
  height: 2rem;
  width: 2rem;
}
@keyframes scale {
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(720deg);
  }
}
.blink::after {
  content: "";
  position: absolute;
  left: 200%;
  top: -50%;
  width: 200%;
  height: 250%;
  background: linear-gradient(180deg,rgba(48,48,48,.2) 0,#303030 20%,#535353 45%,#535353 55%,#303030 80%,rgba(48,48,48,.2) 100%);
  transform: matrix(.47,-.97,.77,.53,0,0);
  animation: blink .5s infinite ease-in-out;
}
@keyframes blink {
  from {
    left: 200%;
  }
  to {
    left: -200%;
  }
}
</style>