<template>
<div>
<scroll-tabs-bar
  v-if="categories.length && $store.state.isMobile"
  :items="categories"
  :selected-item="currentCategory"
  @selectitem="selectCategory"
></scroll-tabs-bar>
<pre-loader v-if="loading" />
<div v-show="!loading">
  <video-stream
  v-if="['h,H', 'H,h', 'g,q', 'vh', '$'].includes(sport.code) && events.length"
  :sport-code="sport.code"
  />
  <next-events
      v-if="currentSportCode && currentCategory!=='Futures'"
      @dataloaded="nextLoaded=true"
      :sport="sport"
      :sportCode="currentSportCode"
      no-events
  />
  <no-events v-if="!events.length" :title="currentCategory"/>
  <div v-else-if="currentCategory!=='Futures'">
    <transition-group name="list" tag="div" class="position-relative">
      <sport-races-event
          v-for="event in sortedEvents"
          :key="event.EventName"
          :event="event"
          :sport="sport"
          :sport-code="currentSportCode"
      ></sport-races-event>
    </transition-group>
  </div>
  <div v-else-if="currentCategory==='Futures'">
      <antepost-events-list
        :events="events"
        :sport="sport"
        :sport-code="currentSportCode"
      />
  </div>
</div>
</div>
</template>

<script>
import SportRacesEvent from "@/components/Sport/Races/RacesEvent";
import ScrollTabsBar from "@/components/UI/ScrollTabsBar";
import axios from "axios";
import NextEvents from "@/components/Home/NextEvents";
import PreLoader from "@/components/UI/PreLoader";
import AntepostEventsList from "@/components/Sport/AntepostEventsList";
import VideoStream from "@/components/VideoStream";
import NoEvents from "@/components/Sport/NoEvents";
export default {
  name: "RacesMain",
  components: {NoEvents, VideoStream, AntepostEventsList, PreLoader, NextEvents, ScrollTabsBar, SportRacesEvent},
  props: ['sport'],
  data:()=>{
    return{
      events: [],
      categories: [],
      currentEventCategory: null,
      currentCategory: 'UK',
      currentSportCode: '',
      loading: false,
      nextLoaded: false
    }
  },
  computed:{
    sortedEvents(){
      if (this.currentEventCategory){
        let sortedEventCategories = this.events
            .filter(c=>c.EventName!==this.currentEventCategory.EventName)
        sortedEventCategories.unshift(this.currentEventCategory)
        return sortedEventCategories
      } else {
        return this.events
      }
    }
  },
  watch:{
    currentCategory(){
      let url
      if (this.currentCategory !== 'Futures') {
        if (this.sport.subcategories) {
          let subCategory = this.sport.subcategories.filter(subc => subc.title === this.currentCategory)[0]
          url = subCategory.url
          this.currentSportCode = subCategory.code
        } else {
          url = this.sport.url
        }
      } else {
        url = this.sport.antePostUrl
      }
      this.events = []
      this.loadEvents(url)
    },
    '$route.query.selectedOption'(val){
      if(this.categories.includes(val)){
        this.currentCategory = val
      }
    },
    '$route.query.selectedCategoryEventName'(value){
      if (value) this.selectEventCategory(value)
    }
  },
  mounted() {
    this.currentSportCode = this.sport.code? this.sport.code : this.sport.antePostCode
    this.loadEvents()
    if (this.sport.subcategories){
      this.categories = this.sport.subcategories.map(sc=>sc.title)
      this.currentSportCode = this.sport.subcategories[0].code
    } else if (this.sport.url){
      this.categories = ['Races']
    } else {
      this.categories = []
    }
    if (this.sport.antePostUrl){
      this.categories = this.categories.concat(['Futures'])
    }
    this.currentCategory = this.$route.query.selectedOption || this.categories[0]
  },
  methods:{
    loadEvents(url=null){
      let vm = this
      vm.loading = true
      if (!url) url = this.sport.url ? this.sport.url: this.sport.antePostUrl
      axios.get(url)
      .then(response=>{
        vm.events = response.data === '[{]}]' ? [] : response.data
        vm.loading = false
      })
      .catch(error=>console.log(error))
    },
    selectCategory(categoryName){
      this.currentCategory = categoryName
    },
    selectEventCategory(selectedEventCategoryName){
      this.currentEventCategory = this.events.filter(ev=>ev.EventName===selectedEventCategoryName)[0]
    }
  }
}
</script>

<style scoped>
.list-move{
  transition: transform 0.3s ease;
}

</style>