<template>
<transition name="fade">
<div v-if="!['sport', 'raceEvent', 'matchEvent'].includes($route.name)">
  <div
    v-for="sport in API.SPORTS"
    :key="sport.title"
    @click="$router.push({name: 'sport', params: {sportName: sport.title}})"
    class="px-3 text-capitalize cursor-pointer"
    :class="{'active_sport': $route.params.sportName === sport.title}"
  >
    <div class="border-bottom border-1 d-flex justify-content-between py-2 px-1 align-items-center">
      <div class="d-flex align-items-center justify-content-start sidebar_item__title_wrap">
        <div class="sport-icon me-2" :style="{backgroundImage: 'url('+sport.img+')'}"></div>
        <div class="sidebar_item__title fs-14 text-truncate">{{sport.title}}</div>
      </div>
      <img :src="require('@/assets/to-right-arrow.svg')">
    </div>
  </div>
</div>
</transition>
</template>

<script>
export default {
  name: "SideBar"
}
</script>

<style scoped>
.sport-icon{
  height: 1.5rem;
  width: 1.75rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(0) drop-shadow(0px 0px 0px black);
}
.active_sport{
  background-color: #f9f9f9;
}
.sidebar_item__title_wrap{
  width: calc(100% - 1rem);
}
.sidebar_item__title{
  width: calc(100% - 1rem);
}
</style>