<template>
<vue-modal ref="modal">
  <template v-slot:header>
    <div class="p-3 bg-dark fw-600 text-white text-center">
      Download Mobile App
    </div>
  </template>
  <template v-slot:default>
    <download-app class="content mt-4"/>
    <vue-button @click="setRead" cls="main">Ok</vue-button>
  </template>
</vue-modal>
</template>

<script>
import VueModal from "@/components/UI/VueModal";
import DownloadApp from "@/components/DownloadApp";
import VueButton from "@/components/UI/VueButton";
export default {
  name: "MobileAppOffer",
  components: {VueButton, DownloadApp, VueModal},
  mounted() {
    if (!localStorage.getItem('appOffer')){
      setTimeout(()=>this.$refs.modal.open(), 15000)
    }
  },
  methods:{
    setRead(){
      localStorage.setItem('appOffer', 'true')
      this.$refs.modal.close()
    }
  }
}
</script>

<style scoped>
.content{
  max-width: 400px;
}
</style>