import * as axios from 'axios';
import {BETSLIP_BASE_URL} from '@/services/betslip/_constants';
import betslipStore from "@/store/betslipStore";

let betslipApiService = null;

export const betslipApi = (logoutIfError) => {
    if (betslipApiService) {
        return betslipApiService;
    }
    
    betslipApiService = (function(logoutIfError) {
        function BetslipApiSingleton() {
            let authToken = null;
            let client = null;

            const init = (isLoggedIn) => {
                let headers = {
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                };
    
                if (isLoggedIn) {
                    authToken = betslipStore.state.authToken
                    if (authToken) {
                        headers.Authorization = `Bearer ${authToken}`
                    }
                }
    
                client = axios.create({
                    baseURL: BETSLIP_BASE_URL + '/api',
                    timeout: 350000,
                    headers
                });
    
                client.interceptors.response.use(
                    response => {
                        return response;
                    },
                    error => {
                        if (error.response.status === 401) {
                            console.error(error);
                            logoutIfError();
                        }
                        return error;
                    }
                );
        
                return client;
            };
        
            const getActiveBetslip = (betslipId, isLoggedIn) => {
                return init(isLoggedIn)
                    .get(`/betslip/${betslipId}`)
                    .then(resp => resp.data);
            };
        
            const createEmptyBetslip = (isLoggedIn) => {
                return init(isLoggedIn)
                    .post('/betslip', null)
                    .then(resp => resp.data);
            };
        
            const addBetToBetslip = (bet, betslipId, isLoggedIn) => {
                return init(isLoggedIn)
                    .post(`/betslip/${betslipId}`, bet)
                    .then(resp => resp.data)
                    .catch(err=>console.error(err))
            };
        
            const deleteBetFromBetslip = (betslipId, betId, isLoggedIn) => {
                return init(isLoggedIn)
                    .delete(`/betslip/${betslipId}/${betId}`)
                    .then(resp => resp.data);
            };
        
            const clearBetslip = (betslipId, isLoggedIn) => {
                return init(isLoggedIn)
                    .delete(`/betslip/${betslipId}`)
                    .then(resp => resp.data);
            };
        
            const submitBetslip = (betslip, isLoggedIn) => {
                return init(isLoggedIn)
                    .post('/betslip/submit', betslip)
                    .then(resp => resp.data);
            };
    
            const submitBetslipAsync = (betslip, isLoggedIn) => {
                return init(isLoggedIn)
                    .post('/betslip/submitasync', betslip)
                    .then(resp => resp.data);
            };
        
            return {
                getActiveBetslip,
                createEmptyBetslip,
                addBetToBetslip,
                deleteBetFromBetslip,
                clearBetslip,
                submitBetslip,
                submitBetslipAsync,
            };
        }
    
        let instance = null;
    
        if (instance == null) {
            instance = new BetslipApiSingleton();
    
            // Hide the constructor so the returned object can't be new'd...
            instance.constructor = null;
        }
        return instance;
    })(logoutIfError);

    return betslipApiService;
}