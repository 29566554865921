<template>
<div class="d-flex my-2 align-self-center">
  <input
    ref="checkbox"
    type="checkbox"
    :checked="modelValue"
    @change="$emit('update:modelValue', $refs.checkbox.checked)"
    :id="name"
  >
  <label v-if="description" :for="name" class="ms-3 fs-14 ">{{description}}</label>
</div>
</template>

<script>
export default {
name: "VueCheckbox",
props: ['modelValue','description', 'name']

}
</script>

<style scoped>
input[type=checkbox]{
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 4px;
  accent-color: #FFD60A;
}
.fs-14{
  line-height: 20px;
}
</style>