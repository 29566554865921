<template>
<vue-modal dark ref="modal" no-close="true">
  <div class="fs-20 fw-60 text-center text-warning mb-3">Reality Check</div>
  <div class="m-3 d-flex justify-content-center text-warning"><i class="bi bi-shield-check" style="font-size: 5rem"></i></div>
  <vue-button @click="remainLoggedIn" cls="main" class="mb-2"> Remain Logged In </vue-button>
  <vue-button @click="logout" cls="secondary"> Log Out <span class="fs-12 ms-2">( {{ countdown }} )</span> </vue-button>
</vue-modal>
</template>

<script>
import VueModal from "@/components/UI/VueModal";
import VueButton from "@/components/UI/VueButton";
const TIMEOUT = 300
const REALITY_CHECK_DELAY_MINUTES = 120
export default {
  name: "RealityCheck",
  components: {VueButton, VueModal},
  data:()=>{
    return{
      intervalCompare: null,
      intervalCountdown: null,
      autoLogoutCountdown: TIMEOUT,
    }
  },
  computed:{
    countdown(){
      if (this.autoLogoutCountdown>60){
        return `${parseInt(this.autoLogoutCountdown/60)}m ${this.autoLogoutCountdown%60}s`
      } else {
        return `${this.autoLogoutCountdown%60}s`
      }
    }
  },
  watch:{
    '$store.state.authToken'(authToken){
      if (authToken) {
        if (!this.intervalCompare) this.startRealityCheck()
      } else {
        if (this.intervalCompare) this.clearIntervalAndSetNull(this.intervalCompare)
      }
    }
  },
  beforeUnmount() {
    if (this.intervalCompare) this.clearIntervalAndSetNull(this.intervalCompare)
    if (this.intervalCountdown) this.clearIntervalAndSetNull(this.intervalCountdown)
  },
  mounted() {
    if (this.$store.state.authToken) this.startRealityCheck()
  },
  methods:{
    startRealityCheck(){
      this.compareExpiredTime()
      this.intervalCompare = setInterval(this.compareExpiredTime, 5000)
    },
    setRealityCheckAt(){
      let now = new Date()
      let realityCheckAt = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          now.getHours(),
          now.getMinutes() + REALITY_CHECK_DELAY_MINUTES,
          now.getSeconds(),
      ).toISOString()
      console.log('REALITY CHECK AT ', realityCheckAt)
      localStorage.setItem('realityCheckAt', realityCheckAt)
      return realityCheckAt
    },
    getRealityCheckAt(){
      let realityCheckAt = localStorage.getItem('realityCheckAt')
      if (!realityCheckAt) {
        realityCheckAt = this.setRealityCheckAt()
      }
      return realityCheckAt
    },
    remainLoggedIn(){
      this.$store.dispatch('BETSLIP_LOGIN', JSON.parse(localStorage.getItem('userCreds')))
      this.setRealityCheckAt()
      this.clearIntervalAndSetNull(this.intervalCountdown)
      this.autoLogoutCountdown = TIMEOUT
      this.$refs.modal.close()
      this.startRealityCheck()
    },
    logout(){
      this.clearIntervalAndSetNull(this.intervalCountdown)
      if (this.intervalCompare) this.clearIntervalAndSetNull(this.intervalCompare)
      this.autoLogoutCountdown = TIMEOUT
      this.$refs.modal.close()
      localStorage.removeItem('realityCheckAt')
      this.$store.dispatch('LOGOUT')
    },
    compareExpiredTime(){
      const vm = this
      let realityCheckAt = vm.getRealityCheckAt()
      // console.log(realityCheckAt, '\n', new Date().toISOString())
      if (new Date(realityCheckAt) < new Date()) {
        this.openRealityCheckModal()
      }
    },
    openRealityCheckModal(){
      this.$refs.modal.open()
      this.clearIntervalAndSetNull(this.intervalCompare)
      this.intervalCountdown = setInterval(()=>{
        if (this.autoLogoutCountdown>0) {
          this.autoLogoutCountdown-=1
        } else {
          this.logout()
        }
      }, 1000)
    },
    clearIntervalAndSetNull(interval){
      clearInterval(interval)
      interval = null
    }
  }
}
</script>

<style scoped>

</style>