<template>
<transition name="fade" appear>
  <div
    class="side_popup__wrapper"
    v-if="showPopup"
    @click.self="close"
  >
</div>
</transition>
<transition name="slide" appear>
  <div v-show="showPopup" class="side_popup">
    <bet-slip-main @close="close()"/>
  </div>
</transition>
</template>

<script>
import BetSlipMain from "@/components/BetSlip/BetSlipMain";
export default {
  name: "BetslipModal",
  components: {BetSlipMain},
  data: function (){
    return {
      showPopup: false,
    }
  },
  computed:{
  },
  methods: {
    open() {
      this.showPopup = true;
    },
    close(){
      this.showPopup = false;
    },
  }
}
</script>

<style scoped>

.side_popup__wrapper{
  position: fixed;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  display: flex;
  justify-content: right;
}
.side_popup{
  position: fixed;
  /*top: 20vh;*/
  min-height: max-content;
  max-height: calc(100vh - 3.5rem);
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(0, 0);
  background: #fff;
  z-index: 1051;
  border-radius: 0.75rem 0.75rem 0px 0px;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateY(100vh);
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s;
}
</style>
