<template>
<div class="d-flex justify-content-between align-items-center race-item p-1 py-2 m-2">
  <div class="fs-14 ms-2 d-flex align-items-center">
    <div class="d-flex align-items-center">
      <race-number  class="me-2" :race-number="raceNumber" :sport="sport"/>
      <div>
        <div>{{getSelName(race.SelName)}}</div>
        <div v-if="race.Jockey" class="text-secondary fs-11 text-start mt-1">{{race.Jockey}}</div>
      </div>
    </div>
  </div>
  <div class="d-flex">
    <odd-btn
      v-if="race.price && race.gprice && race.PriceLink1 !== 'NR'"
      class="me-1 odd-btn"
      :race="race"
      :selection="parseInt(race.selection)"
    />
    <odd-btn
      class="me-1 odd-btn"
      :race="race"
      sp
    />
  </div>
</div>
</template>

<script>
import RaceNumber from "@/components/UI/RaceNumber";
import OddBtn from "@/components/UI/OddBtn";
export default {
name: "RaceItem",
  components: {OddBtn, RaceNumber},
  props:['race', 'sport'],
  computed:{
    raceNumber(){
      if (this.race.Image) return parseInt(this.race.Image.split('.')[0])
      if (this.race.selection) return parseInt(this.race.selection)+1
      return this.race.number
    }
  },
  methods:{
    getSelName(selName){
      if (selName.includes('.')){
        return selName.split('.')[1].trim()
      } else {
        return selName
      }
    }
  }
}
</script>

<style scoped>
.race-item{
  width: auto;
  background: #FFFFFF;
  border-radius: 0.25rem;
}
</style>