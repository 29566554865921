<template>
<div v-if="$store.state.isMobile" class="header py-2">
  <div class="d-flex justify-content-between align-items-center w-100 h-100">
    <div
        v-if="['home', 'sports'].includes($route.name)"
        @click="$router.push({name: 'home'})"
        class="header_logo ms-3 cursor-pointer"
        :style="{backgroundImage: 'url('+require('@/assets/logo.png')+')'}"
    ></div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center p-3 cursor-pointer"
      @click="$router.back()"
    >
      <img class="" :src="require('@/assets/to-left-arrow.svg')">
    </div>
    <div class="header_buttons">
      <div class="d-flex h-100">
        <div v-if="!$store.state.user.Cust_Ref" class="me-3 d-flex cursor-pointer">
        <vue-button sm cls="main" @click="$router.push({name: 'signup'})" class="me-2">Register</vue-button>
        <vue-button sm @click="loginLogut" class="text-white">Login</vue-button>
        ></div>
        <div v-else @click="$router.push({name: 'account'})" class="d-flex align-items-center me-2 cursor-pointer">
          <div class="text-end text-white fs-12 me-2">
            <div class="fs-14">{{ $store.state.user.Cust_Ref }}</div>
            <div class="text-white-50 text-capitalize">{{ $store.state.user.first_name }}</div>
          </div>
          <div class="avatar" :style="{backgroundImage: 'url('+ getAvatarUrl()+')'}"></div>
        </div>
      </div>
    </div>
  </div>
  <div
      @click="$router.push({name:'deposit'})"
      v-if="$store.state.user.funddetail"
      class="position-absolute start-50 top-50 translate-middle text-center cursor-pointer"
  >
      <div class="fs-10 text-secondary text-uppercase">Funding</div>
      <div class="text-white">{{ $store.state.user.funddetail.Balance }}</div>
  </div>
</div>
<div v-else class="header desktop-header py-2">
  <div class="d-flex justify-content-between align-items-center w-100 h-100">
    <div class="d-flex align-items-center text-white">
      <div
          @click="$router.push({name: 'home'})"
          class="header_logo header_logo-desktop cursor-pointer mx-4"
          :style="{backgroundImage: 'url('+require('@/assets/logo.svg')+')'}"
      ></div>
      <a href="#" class="navigate fw-600 mx-4 ms-5">Home</a>
      <a href="/#/info/Affiliates" class="navigate fw-600 mx-4">Affiliates</a>
      <a href="/#/promos" class="navigate fw-600 mx-4">Promotions</a>
    </div>
    <div class="d-flex me-4">
      <div v-if="$store.state.authToken" class="d-flex">
        <vue-button cls="dark" sm class="me-2" @click="$refs.backClaimModal.open()">20Back Claim</vue-button>
        <vue-button cls="dark" @click="$router.push({name: 'deposit'})" sm class="me-2">Online Deposit</vue-button>
      </div>
      <div class="d-flex h-100">
        <div v-if="!$store.state.authToken" class="me-3 d-flex cursor-pointer">
        <vue-button sm cls="main" @click="$router.push({name: 'signup'})" class="me-2">Register</vue-button>
        <vue-button sm @click="$refs.loginModal.open()" class="text-white">Login</vue-button>
        ></div>
        <div v-else @click="$router.push({name: 'transactions'})" class="d-flex align-items-center me-2 cursor-pointer">
          <div class="avatar" :style="{backgroundImage: 'url('+ getAvatarUrl()+')'}"></div>
          <div class="text-end text-white fs-12 ms-3">
            <div class="fs-12">{{ $store.state.user.Cust_Ref }}<span class="ms-3 text-white-50 text-uppercase">{{ $store.state.user.first_name }}</span></div>
            <div v-if="$store.state.user.funddetail" class="fs-18 mt-1 text-warning text-center f-roboto fw-700">{{ $store.state.user.funddetail.Balance }}</div>
          </div>
          <div class="ms-3 p-3 btn-expand-menu"><img style="filter: invert()" :src="require('@/assets/img/arrow-down.svg')"/>
            <div class="bg-dark rounded-3 position-absolute end-0 d-none p-3">
              <vue-button cls="dark" class="mb-2"><router-link class="text-decoration-none" :to="{name:'transactions'}">My Account</router-link></vue-button>
              <vue-button cls="dark" class="" @click="$store.dispatch('LOGOUT')">Log Out</vue-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <teleport to="body">
  <back-claim ref="backClaimModal"/>
  <vue-modal ref="loginModal">
    <template v-slot:header>
      <div class="p-3 bg-dark">
        <div class="auth-logo bg-dark" :style="{backgroundImage: 'url('+require('@/assets/logo.svg')+')'}"></div>
      </div>
    </template>
    <template v-slot:default>
      <log-in
        class="bg-white"
        style="margin-top: 3rem"
        @close-modal="$refs.loginModal.close()"
      ></log-in>
    </template>
  </vue-modal>
  </teleport>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VueButton from "@/components/UI/VueButton";
import VueModal from "@/components/UI/VueModal";
import LogIn from "@/components/Auth/LogIn";
import BackClaim from "@/components/BackClaim";

export default {
  name: "MainHeader",
  components: {BackClaim, LogIn, VueModal, VueButton},
  computed:{
    ...mapGetters(['isAuthenticated'])
  },
  methods:{
    ...mapActions(['LOGOUT']),
    loginLogut(){
      if (this.isAuthenticated) {
        this.LOGOUT()
      } else {
        this.$router.push({name: 'login'})
      }
    },
    getAvatarUrl(){
      return this.$store.state.user.profile_pic ?
          this.API.assets_base_url + this.$store.state.user.profile_pic
          : require('@/assets/img/avatar.png')
    }
  }
}
</script>

<style scoped>
.header{
  position: fixed;
  height: 3.5rem;
  left: 0;
  right: 0;
  top: 0;
  background-color: #09080C;
}
.desktop-header{
  height: 5rem;
  z-index: 1100;
}
.header_logo{
  height: 2rem;
  width: 2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.header_logo-desktop{
  height: 3.125rem;
  width: 7rem;
}
.header_buttons__search{
  height: 1.5rem;
  width: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.avatar{
  border-radius: 50%;
  min-height: 2.5rem;
  min-width: 2.5rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.navigate{
  text-decoration: none;
  color: white;
}
.auth-logo{
  height: 3rem;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.btn-expand-menu{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #23232F;
  border-radius: 0.3rem;
}
.btn-expand-menu > div {
  max-height: 0;
  transition: max-height 0.2s ease-in;
}
.btn-expand-menu:hover > div {
  max-height: 100vh;
  top: 2.5rem;
  display: block!important;
}
a{
  color: inherit;
}

</style>