<template>
<div v-if="$store.state.isMobile" class="footer d-flex align-items-center flex-column text-center fs-12">
  <img class="mb-4" :src="require('@/assets/logo.svg')"/>
  <div class="my-3 mx-4">© Copyright {{ new Date().getFullYear() }}. Geoff Banks. All Right Reserved. This site Licensed and Regulated by the UK Gambling Commission.</div>
  <hr>
  <div class="container">
    <div class="row">
      <div class="col-6 p-3 d-flex justify-content-center align-items-center"><a href="https://www.gamblingcommission.gov.uk/" class="footer_icons" ><img :src="require('@/assets/footer/gambling.png')" alt="gambling"/></a></div>
      <div class="col-6 p-3 d-flex justify-content-center align-items-center"><a href="https://www.gamstop.co.uk/" class="footer_icons" ><img :src="require('@/assets/footer/gamstop.png')" alt="gamstop"/></a></div>
      <div class="col-6 p-3 d-flex justify-content-center align-items-center"><a href="https://www.ibas-uk.com/" class="footer_icons" ><img :src="require('@/assets/footer/ibas.png')" alt="ibas"/></a></div>
      <div @click="$router.push({name: 'info', params: {infoType: 'over18'}})" class="col-6 p-3 d-flex align-items-center justify-content-center">
        <div class="footer_icons icon_bg" :style="{backgroundImage: 'url(' + require('@/assets/footer/18+.png') + ')', minHeight: '2rem'}"></div>
      </div>
    </div>
  </div>
  <hr>
  <div class="mt-3">Licensed by the UK Gambling Commission<br>
  Licensee name – GB Sports Advisors Limited<br>
  Account number – 54095</div>
  <div class="mt-3">Responsible Gambling:<br>
    UK - 0808 8020 133 <a class="text-white opacity-50" href="https://www.begambleware.org" target="_blank">www.begambleware.org</a><br>
    IRL - 089 241 5401 <a class="text-white opacity-50" href="https://www.problemgambling.ie" target="_blank">www.problemgambling.ie</a>
  </div>
  <div class="my-3"><a class="text-white" href="https://www.gamblingcommission.gov.uk/public-register/business/detail/54095" target="_blank">www.gamblingcommission.gov.uk/
public-register/business/detail/54095</a> </div>
</div>
<div v-else>
  <div class="footer container-fluid">
    <div class="row">
      <div class="col-3 d-flex flex-column align-items-center justify-content-center">
        <div class="mb-4 w-75"><img class="w-75" alt="logo" :src="require('@/assets/logo.svg')"/></div>
        <div class="w-75 fs-14">© Copyright {{ new Date().getFullYear() }}, GB Sports Advisors Limited is licensed and regulated in Great Britain by the Gambling Commission under account number 54095</div>
      </div>
      <div class="col-9">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col d-flex flex-column justify-content-around">
              <div><a class="footer-link" href="/#/info/About Us">About Us</a></div>
              <div><a class="footer-link" href="/#/info/Contact Details">Contact Details</a></div>
              <div><a class="footer-link" href="https://www.indeed.co.uk/viewjob?t=trading+assistant&jk=917cbd12c2234b2f&_ga=2.154254520.1857144260.1541691432-1777259780.1541691432" target="_blank">Job at Geoff Banks</a></div>
              <div><a class="footer-link" href="/#/info/Terms And Conditions">Terms & Conditions</a></div>
            </div>
            <div class="col d-flex flex-column justify-content-around">
              <div><a class="footer-link" href="/#/info/Betting Rules">Betting Rules</a></div>
              <div><a class="footer-link" href="/#/info/Best Odds Guaranteed">Best Odds Guaranteed</a></div>
              <div><a class="footer-link" href="/#/info/Privacy Policy">Privacy Policy</a></div>
              <div><a class="footer-link" href="/#/info/Cookie Policy">Cookie Policy</a></div>
            </div>
            <div class="col d-flex flex-column justify-content-around">
              <div><a class="footer-link" href="/#/account/responsible-gambling">Responsible Gambling</a></div>
              <div><a class="footer-link" href="/#/info/Help">Help</a></div>
              <div><a class="footer-link" href="https://geoffbanksracing.com/" target="_blank">Geoff Banks Blog</a></div>
              <div><a class="footer-link" href="/#/info/Affiliates">Affiliates</a></div>
            </div>
            <div class="col d-flex flex-column justify-content-start">
              <div class="my-2"><a href="https://play.google.com/store/apps/details?id=com.geoff_banks.geoffbanks" target="_blank">
                <img src="@/assets/download_play.svg"/></a>
              </div>
              <div class="my-2"><a href="https://apps.apple.com/gb/app/geoff-banks-betting/id881898186" target="_blank">
                <img src="@/assets/download_apple.svg"/></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="container-fluid">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <div class="p-3 d-flex justify-content-center align-items-center"><a href="https://www.gamblingcommission.gov.uk/" class="footer_icons footer_icons-desktop" ><img :src="require('@/assets/footer/gambling.png')" alt="gambling"/></a></div>
          <div class="p-3 d-flex justify-content-center align-items-center"><a href="https://www.gamstop.co.uk/" class="footer_icons footer_icons-desktop" ><img :src="require('@/assets/footer/gamstop.png')" alt="gamstop"/></a></div>
          <div class="p-3 d-flex justify-content-center align-items-center"><a href="https://www.ibas-uk.com/" class="footer_icons footer_icons-desktop" ><img :src="require('@/assets/footer/ibas.png')" alt="ibas"/></a></div>
          <div @click="$router.push({name: 'info', params: {infoType: 'over18'}})" class="p-3 d-flex align-items-center justify-content-center cursor-pointer">
            <div class="footer_icons footer_icons-desktop icon_bg" :style="{backgroundImage: 'url(' + require('@/assets/footer/18+.png') + ')', minHeight: '2rem'}"></div>
          </div>
        </div>
        <div class="fs-12 me-5">
          <div class="d-flex mt-3">
            <div class="me-3">Licensed by the UK Gambling Commission<br>Licensee name – GB Sports Advisors Limited<br>Account number – 54095</div>
            <div>Responsible Gambling:<br>
              UK - 0808 8020 133 <a class="text-white opacity-50" href="https://www.begambleware.org" target="_blank">www.begambleware.org</a><br>
              IRL - 089 241 5401 <a class="text-white opacity-50" href="https://www.problemgambling.ie" target="_blank">www.problemgambling.ie</a>
            </div>
          </div>
          <div class="mt-3"><a class="text-white" href="https://www.gamblingcommission.gov.uk/public-register/business/detail/54095" target="_blank">www.gamblingcommission.gov.uk/public-register/business/detail/54095</a> </div>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: "MainFooter",
  methods:{
    openLink(url){
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>
.footer{
  width: 100%;
  background-color: #09080C;
  color: white;
  padding: 2rem;
}
.footer > img {
  width: 45%;
}
hr{
  width: 100%;
  color: #414160;
}
.footer_icons{
  min-height: 3rem;
  min-width: 5rem;
}
.icon_bg{
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.footer_icons > img {
  width: 100%;
  height: auto;
}
.footer_icons-desktop > img{
  max-height: 3rem;
}
.footer-link{
  color: white;
  text-decoration: none;
}
.footer-link:hover{
  color: #ada48f;
}
</style>