<template>
<pre-loader v-if="loading"></pre-loader>
<iframe
    v-show="!loading"
    ref="wufoo"
    allowtransparency="true"
    :height="height"
    frameBorder="0"
    class="wufoo-form-container"
    src="https://geoffbanks.wufoo.com/forms/r6vu9ru1jw3aij"
    onload="autoHeight(this)"
>
      <a href="https://geoffbanks.wufoo.com/forms/r6vu9ru1jw3aij">
      </a>
  </iframe >
</template>

<script>
import PreLoader from "@/components/UI/PreLoader";
export default {
  name: "DeactivateAccountView",
  components: {PreLoader},
  data:()=>{
    return{
      loading:true,
    }
  },
  mounted() {
    this.$refs.wufoo.onload = ()=>{
      this.loading=false
    }
  },
}
</script>

<style scoped>
html, body, iframe { height: 100%; }
html { overflow: hidden; }
</style>